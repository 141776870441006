/*
 * Copyright 2020-2021 AskChristee, LLC. All rights reserved.
 */

import React, { useContext, useEffect, useRef, useState } from 'react';
import {Accordion, Tab, Tabs} from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { fetchHecm, hREVERSE } from '../../../../api';
import { THecmResponse } from '../../../../types/api';
import {
  PoweredBy,
  tipAge,
  tipClose_date,
  tipCust_name,
  tipEmail,
  tipHecmT_cash,
  tipInput_ltv,
  tipInput_ma,
  tipInput_orgfee,
  tipInput_rpt_age,
  tipProp_t,
  tipSp,
  tipZip,
  UiSettingsProviderContext,
} from '../../../atoms';
import { ChristeeConfigCtx } from '../../../context';
import {
  FormInputChangeRate,
  FormInputDatePicker2,
  FormInputDollar,
  FormInputEmail,
  FormInputNumber,
  FormInputPercent2,
  FormInputRadioButtons,
  FormInputStateAreaDropdowns,
  FormInputStateAreaDropdownsHandle,
  FormInputText,
  FormInputZip, FormLoader, FormWrapper,
  ValidationModal,
} from '../../../molecules';
import { ButtonSendPdf } from '../../../molecules/ButtonSendPdf';
import { Help } from '../../../molecules/QuickStart';
import {HecmReportV2} from '../../Reports';
import ButtonDownloadPdf from "../../../molecules/ButtonDownloadPdf";
import { useRecommendations } from '../../../../hooks/useRecommendations';
import { Recommended } from '../../Recommendations';
import HomeMainImage from "../../../../images/main.png"

const Hecm = () => {
  const defaultValues = {
    account_type: '',
    age: '',
    condo: '',
    cust_email: '',
    cust_name: '',
    cust_phone: '',
    input_dol_cred: '',
    input_ltv: '',
    input_ma: '',
    input_rpt_age: '',
    input_uw_fee: '',
    int_rate2: '',
    int_rate: '',
    lend_cred: '',
    lim_piti: '',
    m_debts: '',
    org_fee: '',
    prop_app: '',
    prop_t: '',
    q_income: '',
    report_years: '',
    s_cred: '',
    sp: '',
    t_cash: '',
  };
  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<any>({ mode: 'onTouched', reValidateMode: 'onBlur', defaultValues });
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { currentSettings } = useContext(UiSettingsProviderContext);
  const { recommendations, requestRecommendations } = useRecommendations();

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  };

  useEffect(() => {
    scrollTop();
  }, []);

  const [dats, setDats] = useState<THecmResponse>();
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);

  const ctx = useContext(ChristeeConfigCtx);

  const stateAreaRef = useRef<FormInputStateAreaDropdownsHandle>(null);
  type TTerm = {
    value: string;
    text: string;
  };
  let termTypeaheadRef: Typeahead<TTerm> | null;
  let loanTypeaheadRef: Typeahead<TTerm> | null;

  const onSubmit = async (data: any) => {
    trigger();
    scrollTop();
    setLoading(true);
    await new Promise((r) => setTimeout(r, 700));

    const doTypeArr = data.doType && data.doType.length > 0 ? data.doType.map((m: any) => m.value) : [];
    const filteredData = {
      ...data,
      state: data.state[0].code,
      county_select: data.county_select[0].code,
      doType: doTypeArr,
      term: Array.isArray(data.term) ? data.term[0].value : data.term ? data.term : '30',
    };

    try {
      let captchaToken;
      if (executeRecaptcha) {
        captchaToken = await executeRecaptcha();
      }
      const modulePromise = fetchHecm(
        {
          ...filteredData,
          company_name: currentSettings.companyId,
          logged_in_email: currentSettings.email,
          logged_in_name: currentSettings.name,
          logged_in_phone: currentSettings.phone,
          account_type: currentSettings.accountType,
        },
        ctx?.stage,
        ctx?.key,
        captchaToken
      );

      const recommendationPromise = requestRecommendations({
        zipCode: data.zip,
        state: data.state?.[0].text
      });

      const [res] = await Promise.all([modulePromise, recommendationPromise]);

      if (res.meta?.displayMessage) toast.info(res.meta.displayMessage);

      setDats(res);
      setLoaded(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error('Something went wrong!');
      }
    }
  };

  const clearForm = () => {
    stateAreaRef.current?.clear();
    loanTypeaheadRef?.clear();
    termTypeaheadRef?.clear();
    reset(defaultValues);
    setDats({} as THecmResponse);
    setLoaded(false);
    setLoading(false);
    scrollTop();
  };

  return (
    <FormWrapper>
      <div className="buyers-choice">
        <div className="container-fluid">
          <div className="row g-2">
            <div className="col-lg-3">
              <h3 className="h2">Reverse Mortgage</h3>
              <form className="christy" autoComplete="off">
                <fieldset>
                  <legend className="sr-only">Required Fields:</legend>
                  <FormInputStateAreaDropdowns setValue={setValue} stateWatch={watch('state')} areaWatch={watch('county_select')} control={control} errors={errors} ref={stateAreaRef} />
                  <FormInputZip label="Zip Code" tip={tipZip} error={errors.zip} name="zip" control={control} required={true} />
                  <FormInputChangeRate watchCalcForm={watch()} resetCalcForm={reset} selectedRates={['hecm']} />
                  <FormInputNumber label="Age" tip={tipAge} error={errors.age} name="age" control={control} required={true} min={40} max={114} />
                </fieldset>
                <Accordion id="optional-inputs">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header data-cy="btn-optional">Optional Inputs</Accordion.Header>
                    <Accordion.Body>
                      <fieldset>
                        <FormInputDollar label="Sales Price" error={errors.sp} name="sp" control={control} tip={tipSp} />
                        <FormInputDollar label="Enter Cash" tip={tipHecmT_cash} error={errors.t_cash} name="t_cash" control={control} />
                        <FormInputDollar label="Annual Property Taxes" tip={tipProp_t} error={errors.prop_t} name="prop_t" control={control} />
                        <FormInputDatePicker2 label="Close Date" tip={tipClose_date} error={errors.close_date} name="close_date" control={control} />
                        <FormInputPercent2 label="Down Payment" tip={tipInput_ltv} error={errors.input_ltv} name="input_ltv" control={control} />
                        <FormInputDollar label="Limit Loan Amt" tip={tipInput_ma} error={errors.input_ma} name="input_ma" control={control} />
                        <FormInputNumber label="Age for HECM Balance" tip={tipInput_rpt_age} error={errors.input_rpt_age} name="input_rpt_age" control={control} min={62} max={114} />
                        <FormInputRadioButtons
                          label="Include Origination Fee"
                          name="input_orgfee"
                          tip={tipInput_orgfee}
                          defaultValue="n"
                          ids={['input_orgfee_yes', 'input_orgfee_no']}
                          control={control}
                          errors={errors}
                        />
                        <FormInputText label="Name" tip={tipCust_name} error={errors.cust_name} name="cust_name" control={control} />
                        <FormInputText label="Phone" tip={tipCust_name} error={errors.cust_phone} name="cust_phone" control={control} />
                        <FormInputEmail accountType={currentSettings.accountType} tip={tipEmail} error={errors.cust_email} control={control} />
                      </fieldset>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

                <p className="mt-3">
                  <ValidationModal label="Submit" errors={errors} onClick={handleSubmit(onSubmit)} />
                  <button type="reset" className="btn btn-outline-secondary me-2" onClick={clearForm}>
                    Clear
                  </button>
                </p>
              </form>
            </div>
            <div className="col-lg-9 position-relative ps-4">
              {!loading && recommendations && <Recommended recommendations={recommendations} />}
              <div className="form-results">
                <div className="w-100 d-flex align-items-center justify-content-end">
                  <Help sFile={hREVERSE} />
                </div>
                {loading ? (
                  <FormLoader />
                ) : (loaded && dats?.hecm) ? (
                  <>
                    <Tabs id="controlled-tab" activeKey='main' className="mb-3">
                      <Tab className="pt-3" eventKey="main" title="Results">
                        <div className="mb-3" style={{ maxWidth: "720px" }}>
                          <HecmReportV2 dats={dats.hecm} />
                          {/* only guests can see this */}

                        </div>
                      </Tab>
                    </Tabs>

                    {dats && <div className="d-flex mt-3">
                      <ButtonDownloadPdf data={dats} fileName="hecm" />
                      {dats?.meta.email && dats?.meta.email !== 'nil' && <ButtonSendPdf dats={dats} />}
                    </div>}
                  </>
                ) : (
                  <>
                    <h4 id="house-title" className="h4">
                      You're seconds away from unlocking your Reverse Mortgage data.
                    </h4>
                    <img id="house-image" src={HomeMainImage} alt="home" />
                  </>
                )}
              </div>
              <div className="w-100 mt-3">
                <PoweredBy />
              </div>
            </div>
          </div>
        </div>
      </div>
    </FormWrapper>
  );
};

export default Hecm;

import React from "react";

import "./styles.css";

type Props = {
  children: React.ReactNode
}

export const ReportSectionMessElement = ({ children }: Props) => (
  <p className="report-values-section__element report-values-section-mess-element">{children}</p>
)

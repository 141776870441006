import React from 'react';
import { useController, ValidationRule } from 'react-hook-form';
import { FloatingLabel, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

type FormInputTextProps = {
  disabled?: boolean;
  label: string;
  tip: string;
  name: string;
  mask?: (_: string) => string,
  error: any;
  control: any;
  required?: boolean;
  pattern?: ValidationRule<RegExp>
};

function FormInputText({ label, tip, error, name, control, disabled = false, required = false, pattern, mask }: FormInputTextProps) {
  const options = { required, pattern };

  const { field } = useController({ name, control, rules: options, defaultValue: '' });

  return (
    <div className="sp-input w-100">
      <FloatingLabel controlId="floatingSelect" label={error ? `${label} Incorrect` : label} className={error ? 'alert-expand' : ''}>
        <input
          disabled={disabled}
          type="text"
          className="form-control"
          id={name}
          data-cy={name}
          data-test={name}
          placeholder=""
          autoComplete="ignore-me"
          aria-label="Text Input"
          {...field}
          value={mask ? mask(field.value) : field.value}
        />
        <OverlayTrigger placement="right" trigger="click" rootClose overlay={<Tooltip>{tip}</Tooltip>}>
          <a className="tooltippanel">
            <FontAwesomeIcon icon={faInfoCircle} />
          </a>
        </OverlayTrigger>
      </FloatingLabel>
    </div>
  );
}

export default FormInputText;

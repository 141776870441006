import React from "react";

import { phoneNumberAutoFormat } from '../../../helpers/phoneNumberAutoFormat';

import DefaultUser from "../../../images/default-user.jpg";
import PhoneIcon from '../../../images/icons/phone-icon.svg';
import ArrowRightIcon from '../../../images/icons/arrrow-right-icon.svg';

import "./styles.css";

const ContactCard = ({
  linkUrl,
  name,
  photo,
  phone,
}: {
  linkUrl: string,
  photo?: string,
  name: string,
  phone: string
}) => {
  const handleCardClick = () => {
    window.open(linkUrl, '_blank');
  }

  return (
    <div className="contact-card" onClick={handleCardClick}>
      <img className="contact-card__photo" src={photo || DefaultUser} alt={name} />
      <div className="contact-card__body">
        <div className="contact-card__info">
          <p className="contact-card__name">{name}</p>
          <p className="contact-card__phone">
            <img width="16" src={PhoneIcon} alt="phone" />
            <span className="ms-1">{phoneNumberAutoFormat(phone)}</span>
          </p>
        </div>
        <div className="contact-card__arrow">
          <img src={ArrowRightIcon} alt="arrow-right" />
        </div>
      </div>
    </div>
  );
}

export default ContactCard;

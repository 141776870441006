import React from 'react';
import { ReportSectionElement, ReportSectionHeader, ReportSectionItem } from '../../../molecules';
import { TBuyOrRentResponseObjectV2 } from '../../../../types/api';

export const ProjectEquity = ({ aCalc }: TBuyOrRentResponseObjectV2) => {
  return (
    <ReportSectionItem>
      <ReportSectionHeader>{aCalc.project$}</ReportSectionHeader>
      <ReportSectionElement title="Future Home Value" value={aCalc.disp_h_value} />
      <ReportSectionElement title="Mortgage Balance" value={aCalc.disp_ma_bal} />
      <ReportSectionElement title="Potential Equity" value={aCalc.disp_net_equity} />
    </ReportSectionItem>
  );
};

import { useState, useCallback } from 'react';

import { toast } from 'react-toastify';
import { Auth } from 'aws-amplify';

type RequestOptions = {
  url: string;
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  body?: BodyInit | null;
  queryParams?: Record<string, string | number | boolean>;
  isAuth?: boolean;
};

type UseRequestResult<T> = {
  data: T | null;
  isLoading: boolean;
  error: string | null;
  requestData: (options: RequestOptions) => Promise<T | void>;
};

export const useRequest = <T>(): UseRequestResult<T> => {
  const [data, setData] = useState<T | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const requestData = useCallback(async (options: RequestOptions): Promise<T | void> => {
    setIsLoading(true);
    setData(null);
    setError(null);

    const {
      isAuth = true,
      body = null,
      method = 'GET',
      queryParams = {},
    } = options;

    const queryString = Object.keys(queryParams)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(queryParams[key]))
      .join('&');

    const urlWithParams = Object.keys(queryParams).length ? `${options.url}?${queryString}` : options.url;

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    try {
      if (isAuth) {
        const session = await Auth.currentSession();
        const accessToken = session.getAccessToken().getJwtToken();

        if (accessToken) {
          headers.append('token', accessToken);
        }
      }

      const response = await fetch(urlWithParams, {
        method,
        headers: headers,
        body: method !== 'GET' && method !== 'DELETE' ? JSON.stringify(body) : null,
      });

      const data: T = await (response.headers.get('Content-Length') ? response.json() : null);

      setData(data);
      return data;
    } catch (error) {
      const typedError = error as any;
      const errorMessage = typedError?.response?.data || typedError.message || "Something went wrong!";

      setError(errorMessage);
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return { data, isLoading, error, requestData };
}

import React from 'react';
import { ReportSectionElement, ReportSectionHeader, ReportSectionItem } from '../../../molecules';
import { TBuyOrRentResponseObjectV2 } from '../../../../types/api';

export const PaymentComparison = ({ aCalc }: TBuyOrRentResponseObjectV2) => {
  return (
    <ReportSectionItem>
      <ReportSectionHeader>{aCalc.detail$}</ReportSectionHeader>
      <ReportSectionElement title="Average Rent" value={aCalc.disp_avg_rent} />
      <ReportSectionElement title="Mortgage Payment" value={aCalc?.disp_t_piti} />
      <ReportSectionElement title={aCalc.pay_dif$} value={aCalc?.disp_pay_dif} />
    </ReportSectionItem>
  );
};

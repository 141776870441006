import React from "react";

import "./styles.css";

type Props = {
  children: React.ReactNode
}

export const ReportSectionValue = ({ children }: Props) => (
  <p className="report-values-section__element-value">{children}</p>
)

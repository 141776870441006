import React from "react";

import {ReportSectionHeader, ReportSectionItem, ReportSectionElement} from "../../../molecules";

import {TVacationResultsV2} from "../../../../types/api";

type Props = {
  dats: TVacationResultsV2,
  calcType: "aCalc1" | "aCalc2" | "aCalc3" | "aCalc4"
}

export const PotentialTaxConsiderations = ({ dats, calcType }: Props) => {
  return (
    <ReportSectionItem>
      <ReportSectionHeader>Potential Tax Considerations</ReportSectionHeader>
      <ReportSectionElement title="Estimated Tax Deduction" value={dats[calcType]?.disp_tot_duct} />
      <ReportSectionElement title="Monthly Tax Savings" value={dats[calcType]?.disp_tax_saving} />
      <ReportSectionElement title="Net Mortgage Payment" value={dats[calcType]?.disp_net_mort_pay} />
    </ReportSectionItem>
  );
}

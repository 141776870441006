export type RateData = {
  [key: string]: number;
};

export type RateField = {
  name: string;
  label: string;
  tip: string;
};

export type RenderRatesFields = {
  "30 Year": RateField[];
  "15 Year": RateField[];
};

const labelMap: { [key: string]: { label: string; tip: string } } = {
  "conv": { label: "Conventional", tip: "Conventional Tip" },
  "fha": { label: "FHA", tip: "FHA Tip" },
  "usda": { label: "USDA", tip: "USDA Tip" },
  "va": { label: "VA", tip: "VA Tip" },
  "second_home_conv": { label: "Second Home Conv", tip: "Second Home Conv Tip" },
  "hecm": { label: "HECM", tip: "HECM Tip" }
};

export const filterRatesValues = (data: RateData, filters: string[]): RateData => {
  const filteredRatesValues: RateData = {};

  for (const key in data) {
    const filterKey = filters.find(filter => key.includes(filter));
    if (filterKey) {
      filteredRatesValues[key] = data[key];
    }
  }

  return filteredRatesValues;
};

export const generateRenderRatesFields = (data: RateData): RenderRatesFields => {
  const renderRatesFields: RenderRatesFields = { "30 Year": [], "15 Year": [] };

  for (const key in data) {
    const filterKey = Object.keys(labelMap).find(filter => key.includes(filter));
    if (filterKey) {
      const rateField: RateField = {
        name: key,
        label: labelMap[filterKey].label,
        tip: labelMap[filterKey].tip
      };

      if (key.endsWith('_30') || key === 'hecm') {
        renderRatesFields["30 Year"].push(rateField);
      } else if (key.endsWith('_15')) {
        renderRatesFields["15 Year"].push(rateField);
      }
    }
  }

  return renderRatesFields;
};

export type TableDataRow = {
  label: string;
  "30 Year": string;
  "15 Year": string;
};

export const generateTableData = (rates: RateData): TableDataRow[] => {
  const tableData: { [label: string]: TableDataRow } = {};

  // Function to extract label key from rate key
  const extractLabelKey = (key: string): string => {
    return key.replace(/_\d+$/, '');
  };

  for (const key in rates) {
    const labelKey = extractLabelKey(key);
    const label = labelMap[labelKey].label;
    const yearType = key.endsWith('_30') || key === 'hecm' ? '30 Year' : '15 Year';

    if (!tableData[label]) {
      tableData[label] = { label: label, "30 Year": "", "15 Year": "" };
    }
    tableData[label][yearType] = key;
  }

  return Object.values(tableData);
}

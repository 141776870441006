import React, { useContext } from 'react';
import { useController } from 'react-hook-form';
import { FloatingLabel, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { UiSettingsProviderContext } from '../../atoms';

type FormInputNumberProps = {
  label: string;
  tip: string;
  name: string;
  error: any;
  control: any;
  required?: boolean;
  min?: number;
  max?: number;

};

const FormInputZip = ({ label, tip, error, name, control, required = false, min = -Number.MAX_SAFE_INTEGER, max = Number.MAX_SAFE_INTEGER }: FormInputNumberProps) => {
  const { currentSettings } = useContext(UiSettingsProviderContext);
  const options = {
    required: required && "Required",
    pattern: {
      value: /^\d{5}$/,
      message: 'Incorrect',
    },
    min: min,
    max: max,
  };

  const { field } = useController({ name, control: control, rules: options, defaultValue: currentSettings.zipCode || '' });

  return (
    <div className="sp-input">
      <FloatingLabel controlId="floatingSelect" label={error ? label + " " + error.message : label} className={error ? 'alert-expand' : ''}>
        <input type="text" min={min} max={max} className="form-control" id={name} data-cy={name} data-test={name} placeholder="" aria-label="Number Input" {...field} />
        <OverlayTrigger placement="right" trigger="click" rootClose overlay={<Tooltip>{tip}</Tooltip>}>
          <a className="tooltippanel">
            <FontAwesomeIcon icon={faInfoCircle} />
          </a>
        </OverlayTrigger>
      </FloatingLabel>
    </div>
  );
};

export default FormInputZip;

import React from "react";

import {ReportSectionElement, ReportSectionHeader, ReportSectionItem} from "../../../molecules";

import {BuyerQualChoiceReport} from "./index";

interface Props extends BuyerQualChoiceReport {
  calcType: 'aCalc' | 'bCalc'
}

export const PotentialTaxConsiderations = ({ dats, loanType, calcType }: Props) => {
  return (
    <ReportSectionItem>
      <ReportSectionHeader>Potential Tax Considerations</ReportSectionHeader>
      <ReportSectionElement title="Estimated Tax Deduction" value={dats[loanType][calcType]?.disp_tot_duct} />
      <ReportSectionElement title="Monthly Tax Savings" value={dats[loanType][calcType]?.disp_tax_saving} />
      <ReportSectionElement title="Net Mortgage Payment" value={dats[loanType][calcType]?.disp_net_mort_pay} />
    </ReportSectionItem>
  );
}

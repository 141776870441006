/**
 * Copyright 2020-2021 AskChristee, LLC. All rights reserved.
 *
 * This will do payment subsidy with greater results detail than Buyer Prequal or Buyer Choice
 *
 * int_rate = 6;          Input interest Rate
 * input_ma = 200000;     For amortization need loan amount
 * term = 30;             Mortgage Term
 */

import React, { useContext, useEffect, useRef, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { fetchPaymentSubsidy, hSUBSIDY } from '../../../../api';
import { checkAccessFullReport } from '../../../../helpers/checkAccessFullReport';
import { TPaymentSubsidyResponse } from '../../../../types/api';
import { PoweredBy, tipCust_name, tipEmail, tipInput_ma, tipInt_rate, tipZip, UiSettingsProviderContext } from '../../../atoms';
import { ChristeeConfigCtx } from '../../../context';
import {
  FormInputDollar,
  FormInputEmail,
  FormInputPercent2,
  FormInputStateAreaDropdownsHandle,
  FormInputTerm,
  FormInputTermHandle,
  FormInputText,
  FormInputZip, FormLoader,
  FormWrapper,
  ValidationModal,
} from '../../../molecules';
import { ButtonSendPdf } from '../../../molecules/ButtonSendPdf';
import { Help } from '../../../molecules/QuickStart';
import { PaymentSubsidyReport } from '../../Reports';
import ButtonDownloadPdf from "../../../molecules/ButtonDownloadPdf";
import { useRecommendations } from '../../../../hooks/useRecommendations';
import { Recommended } from '../../Recommendations';
import HomeMainImage from "../../../../images/main.png"

const PaymentSubsidy = () => {
  const defaultValues = {
    account_type: '',
    input_ma: '',
    int_rate: '',
    term: '',
  };
  const {
    control,
    handleSubmit,
    watch,
    reset,
    getValues,
    trigger,
    formState: { errors, isValid, touchedFields, submitCount },
  } = useForm<any>({ mode: 'onTouched', reValidateMode: 'onBlur', defaultValues });
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { currentSettings } = useContext(UiSettingsProviderContext);
  const isAccessFullReport = checkAccessFullReport(currentSettings);
  const { recommendations, requestRecommendations } = useRecommendations();

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  };

  useEffect(() => {
    console.log('useEffect->scrollTop');
    scrollTop();
  }, []);

  const [dats, setDats] = useState<TPaymentSubsidyResponse>();
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState<string | null>('results');
  const ctx = useContext(ChristeeConfigCtx);
  const termRef = useRef<FormInputTermHandle>(null);
  const stateAreaRef = useRef<FormInputStateAreaDropdownsHandle>(null);
  type TTerm = {
    value: string;
    text: string;
  };
  let termTypeaheadRef: Typeahead<TTerm> | null;
  let loanTypeaheadRef: Typeahead<TTerm> | null;

  const onSubmit = async (data: any) => {
    trigger();
    scrollTop();
    setLoading(true);
    await new Promise((r) => setTimeout(r, 700));
    console.log('data', data);
    console.log('watch', watch);
    console.log('errors', errors);
    //const doTypeArr = data.doType && data.doType.length > 0 ? data.doType.map((m: any) => m.value) : [];
    const filteredData = {
      ...data,
      term: Array.isArray(data.term) ? data.term[0].value : data.term ? data.term : '30',
    };

    try {
      let captchaToken;
      if (executeRecaptcha) {
        captchaToken = await executeRecaptcha();
      }
      const modulePromise = fetchPaymentSubsidy(
        {
          ...filteredData,
          company_name: currentSettings.companyId,
          logged_in_email: currentSettings.email,
          logged_in_name: currentSettings.name,
          logged_in_phone: currentSettings.phone,
          account_type: currentSettings.accountType,
        },
        ctx?.stage,
        ctx?.key,
        captchaToken
      );

      const recommendationPromise = requestRecommendations({
        zipCode: data.zip,
        state: data.state?.[0].text
      });

      const [res] = await Promise.all([modulePromise, recommendationPromise])

      console.log('onSubmit->res', res);
      setDats(res);
      setLoaded(true);
      setLoading(false);
      setKey('results');
      console.log('dats after setDats', dats);
    } catch (error) {
      setLoading(false);
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error('Something went wrong!');
      }
    }
  };

  const clearForm = (data: any) => {
    console.log('clearForm->data', data);
    console.log('clearForm->isValid', isValid);
    console.log('clearForm->errors', errors);
    console.log('clearForm->touchedFields', touchedFields);
    console.log('clearForm->submitCount', submitCount);
    console.log('clearForm->getValues->state', getValues('state'));
    stateAreaRef.current?.clear();
    termRef.current?.clear();
    loanTypeaheadRef?.clear();
    termTypeaheadRef?.clear();
    reset(defaultValues);
    setDats({} as TPaymentSubsidyResponse);
    setLoaded(false);
    setLoading(false);
    setKey('results');
    scrollTop();
  };

  return (
    <FormWrapper>
      <div className="buyers-choice">
        <div className="container-fluid">
          <div className="row g-2">
            <div className="col-lg-3">
              <h3 className="h2">Payment Subsidy</h3>
              <form className="christy" autoComplete="off">
                <fieldset>
                  <legend className="sr-only">Required Fields:</legend>
                  <FormInputPercent2 label="Interest Rate" tip={tipInt_rate} error={errors.int_rate} name="int_rate" control={control} required={true} />
                  <FormInputDollar label="Loan Amount" tip={tipInput_ma} error={errors.input_ma} name="input_ma" control={control} required={true} />
                  <FormInputTerm watch={watch('term')} ref={termRef} control={control} errors={errors} required={true} />
                  <FormInputZip label="Zip Code" tip={tipZip} error={errors.zip} name="zip" control={control} required={true} />
                  <FormInputText label="Name" tip={tipCust_name} error={errors.cust_name} name="cust_name" control={control} />
                  <FormInputText label="Phone" tip={tipCust_name} error={errors.cust_phone} name="cust_phone" control={control} />
                  <FormInputEmail accountType={currentSettings.accountType} tip={tipEmail} error={errors.cust_email} control={control} />
                </fieldset>
                <p className="mt-3">
                  <ValidationModal label="Submit" errors={errors} onClick={handleSubmit(onSubmit)} />
                  <button type="reset" className="btn btn-outline-secondary me-2" onClick={clearForm}>
                    Clear
                  </button>
                </p>
              </form>
            </div>
            <div className="col-lg-9 position-relative ps-4">
              {!loading && recommendations && <Recommended recommendations={recommendations} />}
              <div className="form-results">
                <div className="w-100 d-flex align-items-center justify-content-end">
                  <Help sFile={hSUBSIDY} />
                </div>
                {loading ? (
                  <FormLoader />
                ) : loaded && dats ? (
                  <>
                    <Tabs id="controlled-tab" activeKey={key as string} onSelect={(k) => setKey(k)} className="mb-3">
                      <Tab className="pt-3" eventKey="results" title="Results">
                        {isAccessFullReport ? <PaymentSubsidyReport {...dats!} /> : <PaymentSubsidyReport {...dats!} />}
                      </Tab>
                    </Tabs>

                    <div className="d-flex mt-3">
                      <ButtonDownloadPdf data={dats} fileName="payment-subsidy" />
                      {dats?.meta.email && dats?.meta.email !== 'nil' && <ButtonSendPdf dats={dats} />}
                    </div>
                  </>
                ) : (
                  <>
                    <h4 id="house-title" className="h4">
                      You're seconds away from unlocking Payment Subsidy data.
                    </h4>
                    <img id="house-image" src={HomeMainImage} alt="home" />
                  </>
                )}
              </div>
              {/* only guests can see this */}

              <div className="w-100 mt-3">
                <PoweredBy />
              </div>
            </div>
          </div>
        </div>
      </div>
    </FormWrapper>
  );
};

export default PaymentSubsidy;

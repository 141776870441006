import React from "react";

import "./styles.css";

type Props = {
  fullWidth?: boolean,
  children: React.ReactNode
}

export const ReportSectionItem = ({fullWidth = false, children }: Props) => (
  <p
    className={"report-values-section__item"
    + (fullWidth
      ? " section-item_full-width"
      : "")}>
    {children}
  </p>
)

/*
 * Copyright 2020-2021 AskChristee, LLC. All rights reserved.
 */

import React, { useEffect, useState } from 'react';

import {
  TCalcType,
  ResponseLoanTypes,
  TBuyerQualChoiceResponseObjectV2,
} from '../../../../types/api';
import {
  ReportContainer,
  ReportHeader,
  ReportNotesSection,
  ReportResults,
  ReportSection,
  ReportCashSwitcher
} from '../../../molecules';

import {LoanMess} from "./LoanMess";
import {LoanDetails} from "./LoanDetails";
import {CashRequirements} from "./CashRequirements";
import {ProjectedPaymentDetails} from "./ProjectedPaymentDetails";
import {PotentialTaxConsiderations} from "./PotentialTaxConsiderations";

export type BuyerQualChoiceDats = {
  [key in ResponseLoanTypes]: TBuyerQualChoiceResponseObjectV2;
};

export type BuyerQualChoiceReport = {
  dats: BuyerQualChoiceDats;
  loanType: ResponseLoanTypes;
};

const BuyerQualChoice = ({ dats, loanType }: BuyerQualChoiceReport) => {
  const [calcType, setCalcType] = useState<TCalcType>('aCalc');

  const switchCalcType = () => {
    setCalcType(calcType === 'aCalc' ? 'bCalc' : 'aCalc');
  }

  const isBCalcAvailable = !!dats[loanType]?.bCalc;

  useEffect(() => {
    setCalcType(isBCalcAvailable ? 'bCalc' : 'aCalc');
  }, [isBCalcAvailable]);

  return (
    <>
      <ReportCashSwitcher
        calcType={calcType}
        switchCalcType={switchCalcType}
        isBCalcAvailable={isBCalcAvailable}
      />

      <ReportContainer>
        <ReportHeader>{dats[loanType][calcType]?.brow_head}</ReportHeader>
        <ReportHeader>{dats[loanType][calcType]?.brow_head_2}</ReportHeader>

        <ReportSection>
          <LoanDetails dats={dats} loanType={loanType} calcType={calcType} />
          <CashRequirements dats={dats} loanType={loanType} calcType={calcType} />
          <ProjectedPaymentDetails dats={dats} loanType={loanType} calcType={calcType} />
          <PotentialTaxConsiderations dats={dats} loanType={loanType} calcType={calcType} />
        </ReportSection>

        <ReportResults>{dats[loanType][calcType]?.results$}</ReportResults>

        <ReportNotesSection>
          <LoanMess dats={dats} loanType={loanType} calcType={calcType} />
        </ReportNotesSection>
      </ReportContainer>

    </>
  );
};

export default BuyerQualChoice;

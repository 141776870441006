import React from "react";

import {
  ReportSectionHeader, ReportSectionItemMess,
  ReportSectionMessElement
} from "../../../molecules";

import {TVacationResultsV2} from "../../../../types/api";

type Props = {
  dats: TVacationResultsV2,
  calcType: "aCalc1" | "aCalc2" | "aCalc3" | "aCalc4"
}

export const LoanMess = ({ dats, calcType }: Props) => {
  return (
    <ReportSectionItemMess>
      <ReportSectionHeader disableBottomLine>{dats[calcType]?.loan_mess}</ReportSectionHeader>
      <ReportSectionMessElement>{dats[calcType]?.m_note}</ReportSectionMessElement>
      <ReportSectionMessElement>{dats[calcType]?.m_note1}</ReportSectionMessElement>
      <ReportSectionMessElement>{dats[calcType]?.m_note2}</ReportSectionMessElement>
      <ReportSectionMessElement>{dats[calcType]?.m_note3}</ReportSectionMessElement>
      <ReportSectionMessElement>{dats[calcType]?.m_note4}</ReportSectionMessElement>
      <ReportSectionMessElement>{dats[calcType]?.m_note5}</ReportSectionMessElement>
    </ReportSectionItemMess>
  );
}

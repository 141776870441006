import React from "react";

import { Spinner } from "react-bootstrap";

const CustomLoader = () => {
  return (<Spinner
    style={{ width: "4em", height: "4em" }}
    animation="border"
    variant="primary"
  /> );
};

export default CustomLoader;

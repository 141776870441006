import React, { useState, Fragment } from "react";

import { Button, Modal } from "react-bootstrap";
import { NavigateFunction, } from 'react-router-dom';

import "./styles.css";

type QuickItem = {
  text: string;
  link: string;
  image: string;
}

interface QuickModuleOverlayProps {
  quickItems: QuickItem[];
  navigate: NavigateFunction,
  children: React.ReactElement;
}

const QuickModuleOverlay = ({ quickItems, navigate, children }: QuickModuleOverlayProps) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <Fragment>
        {React.cloneElement(children, {
          onClick: () => setShow(true),
        })}
      </Fragment>
      <div onClick={(e) => e.stopPropagation()}>
        <Modal
          show={show}
          onHide={() => setShow(false)}
          centered
          size="lg"
          aria-labelledby="title"
        >
          <Modal.Header closeButton className="display">
            <h4 className="fw-bold px-4 my-0">Choose type of module</h4>
            <Modal.Title id="title">&nbsp;</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="quick-items">
              {quickItems.map((item) => (
                <div className="quick-item-card" key={item.link}>
                  <img
                    src={item.image}
                    alt={item.text}
                    style={{ display: "block", margin: "auto" }}
                  />
                  <div className="mt-3">{item.text}</div>
                  <Button
                    className="p-2 mt-3"
                    onClick={() => navigate(item.link)}
                  >
                    Choose
                  </Button>
                </div>
              ))}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default QuickModuleOverlay;

import React from 'react';
import { TPaymentSubsidyResponseObject } from '../../../../types/api';
import { ReportContainer, ReportHeader, ReportNotesSection, ReportSection, ReportSectionHeader, ReportSectionItem, ReportSectionItemMess, ReportSectionMessElement } from '../../../molecules';

const PaymentSubsidy = (res: TPaymentSubsidyResponseObject | undefined) => {
  return (
    <ReportContainer>
      <ReportHeader>{res!.browser.brow_head}</ReportHeader>
      <ReportHeader>{res!.browser.brow_head2}</ReportHeader>
      <ReportSection>
        <div>
          <ReportSectionItem>
            <ReportSectionHeader>{res!.browser.header2}</ReportSectionHeader>

            <ReportSectionMessElement>{res!.browser.brwmess8}</ReportSectionMessElement>
            <ReportSectionMessElement>{res!.browser.brwmess9}</ReportSectionMessElement>
            <ReportSectionMessElement>{res!.browser.brwmess10}</ReportSectionMessElement>
            <ReportSectionMessElement>{res!.browser.brwmess10a}</ReportSectionMessElement>
          </ReportSectionItem>
          <ReportSectionItem>
            <ReportSectionHeader>{res!.browser.header1}</ReportSectionHeader>

            <ReportSectionMessElement>{res!.browser.brwmess1}</ReportSectionMessElement>
            <ReportSectionMessElement>{res!.browser.brwmess2}</ReportSectionMessElement>
            <ReportSectionMessElement>{res!.browser.brwmess3}</ReportSectionMessElement>
            <ReportSectionMessElement>{res!.browser.brwmess4}</ReportSectionMessElement>
            <ReportSectionMessElement>{res!.browser.brwmess5}</ReportSectionMessElement>
            <ReportSectionMessElement>{res!.browser.brwmess6}</ReportSectionMessElement>
            <ReportSectionMessElement>{res!.browser.brwmess7}</ReportSectionMessElement>
            <ReportSectionMessElement>{res!.browser.brwmess7a}</ReportSectionMessElement>
          </ReportSectionItem>
        </div>

        <ReportSectionItem>
          <ReportSectionHeader>{res!.browser.header3}</ReportSectionHeader>
          <ReportSectionMessElement>{res!.browser.brwmess11}</ReportSectionMessElement>
          <ReportSectionMessElement>{res!.browser.brwmess12}</ReportSectionMessElement>
          <ReportSectionMessElement>{res!.browser.brwmess13}</ReportSectionMessElement>
          <ReportSectionMessElement>{res!.browser.brwmess14}</ReportSectionMessElement>
          <ReportSectionMessElement>{res!.browser.brwmess15}</ReportSectionMessElement>
          <ReportSectionMessElement>{res!.browser.brwmess16}</ReportSectionMessElement>
          <ReportSectionMessElement>{res!.browser.brwmess17}</ReportSectionMessElement>
          <ReportSectionMessElement>{res!.browser.brwmess18}</ReportSectionMessElement>
          <ReportSectionMessElement>{res!.browser.brwmess19}</ReportSectionMessElement>
          <ReportSectionMessElement>{res!.browser.brwmess20}</ReportSectionMessElement>
          <ReportSectionMessElement>{res!.browser.brwmess21}</ReportSectionMessElement>
        </ReportSectionItem>

        <ReportNotesSection>
          <ReportSectionItemMess>
            <ReportSectionHeader disableBottomLine>{res!.browser.loan_mess}</ReportSectionHeader>

            <ReportSectionMessElement>{res!.browser.m_note}</ReportSectionMessElement>
            <ReportSectionMessElement>{res!.browser.m_note1}</ReportSectionMessElement>
            <ReportSectionMessElement>{res!.browser.m_note2}</ReportSectionMessElement>
            <ReportSectionMessElement>{res!.browser.m_note3}</ReportSectionMessElement>
            <ReportSectionMessElement>{res!.browser.m_note4}</ReportSectionMessElement>
            <ReportSectionMessElement>{res!.browser.m_note5}</ReportSectionMessElement>
            <ReportSectionMessElement>{res!.browser.brwmess22}</ReportSectionMessElement>
          </ReportSectionItemMess>
        </ReportNotesSection>
      </ReportSection>
    </ReportContainer>
  );
};

export default PaymentSubsidy;

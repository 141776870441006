import React from "react";

import "./styles.css";

type Props = {
  children: React.ReactNode
}

export const ReportResults = ({ children }: Props) => (
  <h4 className="report__results">{children}</h4>
)

/*
 * Copyright 2020-2021 AskChristee, LLC. All rights reserved.
 */

export enum ResponseLoanTypes {
  CONV = 'conventional',
  FHA = 'fha',
  VA = 'va',
  USDA = 'usda',
}

export type TCalcType = 'aCalc' | 'bCalc';

export type TResponseMeta = {
  version: string;
  engine: string;
  date: string;
  email: string;
  is_bCalc: boolean
  displayMessage?: string;
};

export type TResponseCoverPage = {
  agent_name: string;
  agent_phone: string;
  doc_id: string;
  email: string;
  loan_officer_name: string;
  loan_officer_phone: string;
  name: string;
  phone: string;
  title: string;
  company: string;
};

export type TResponseCoverPageV2 = {
  title: string;
  doc_id: string;
  agent_name: string;
  agent_phone: string;
  loan_officer_name: string;
  loan_officer_phone: string;
  email: string;
  name: string;
  phone: string;
  taxesPrompt: string;
  insurancePrompt: string;
  purchasePricePrompt: string;
};

export * from './amortization';
export * from './budget';
export * from './buyers_choice';
export * from './buyerqual';
export * from './buyerqualchoice-v2';
export * from './closing-cost';
export * from './buyorrent';
export * from './buyorrent-v2';
export * from './buywait';
export * from './hecm';
export * from './investment';
export * from './vacation';
export * from './vacation-v2';
export * from './seller-net';
export * from './consumerdebt';
export * from './paymentsubsidy';
export * from './equity';
export * from './leadcontact';

import React from "react";

import "./styles.css";

type Props = {
  children: React.ReactNode
}

export const ReportContainer = ({ children }: Props) => (
  <div className="report">
    {children}
  </div>
)

import React, { useState, useRef, useEffect, ReactNode } from 'react';
import { Button, Overlay, Popover } from 'react-bootstrap';
import { InfoCircle } from 'react-bootstrap-icons';

import { Placement } from 'react-bootstrap/types';

import './styes.css';


const IconPopoverOverlay = ({
    title,
    children
  }: {
    title: string;
    children: ReactNode;
}) => {
  const [show, setShow] = useState(false);
  const target = useRef<HTMLButtonElement>(null);
  const [placement, setPlacement] = useState<Placement>('right');

  const updatePlacement = (): void => {
    if (window.innerWidth <= 768) {
      setPlacement('bottom');
    } else {
      let newPlacement: Placement = 'right';

      // Calculate available space
      if (target.current) {
        const rect = target.current.getBoundingClientRect();
        const spaceOnRight = window.innerWidth - rect.right;

        // Check if popover goes out of the right edge
        if (spaceOnRight < 400) {
          newPlacement = 'left';
        }
      }

      setPlacement(newPlacement);
    }
  };

  useEffect(() => {
    updatePlacement();
    window.addEventListener('resize', updatePlacement);
    return () => {
      window.removeEventListener('resize', updatePlacement);
    };
  }, []);

  const handleMouseEnter = (): void => {
    setShow(true);
    updatePlacement();
  };

  const handleMouseLeave = (): void => {
    setShow(false);
  };

  return (
    <div
      className='popover-wrapper'
    >
      <Button
        ref={target}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        variant='link'
      >
        <InfoCircle />
      </Button>

      <Overlay target={target.current} show={show} placement={placement}>
        {(props) => (
          <Popover
            {...props}
            id='icon-popover'
            className='custom-popover'
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Popover.Header className='custom-popover__title' as='h3'>
              {title}
            </Popover.Header>
            <Popover.Body className='custom-popover__body'>
              {children}
            </Popover.Body>
          </Popover>
        )}
      </Overlay>
    </div>
  );
};

export default IconPopoverOverlay;

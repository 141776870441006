import React from 'react';

import MinimumCashSwitch from '../MinimumCashSwitch';
import { TCalcType } from '../../../types/api';

type Props = {
  isBCalcAvailable: boolean,
  switchCalcType: () => void,
  calcType: TCalcType
}

export const ReportCashSwitcher = ({ isBCalcAvailable, switchCalcType, calcType }: Props) => {
  return (
  <div className='w-100 d-flex align-items-center justify-content-end'>
    <div>
      {isBCalcAvailable && <MinimumCashSwitch onChange={switchCalcType} checked={calcType === 'aCalc'} />}
    </div>
  </div>);
}

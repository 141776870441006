/*
 * Copyright 2020-2021 AskChristee, LLC. All rights reserved.
 */

import React, { useContext, useState } from 'react';

import { ButtonWithSpinner, UiSettingsProviderContext } from '../../atoms';
import { toast } from 'react-toastify';
import {
  TAmortizationResponse,
  TBudgetResponse,
  TBuyerQualChoiceResponseV2,
  TBuyerQualResponse,
  TBuyersChoiceResponse,
  TBuyOrRentResponse,
  TBuyOrRentResponseV2,
  TBuyWaitResponse,
  TClosingCostResponse, TConsumerDebtResponse, THecmResponse,
  TInvestmentResponse, TPaymentSubsidyResponse, TSellerNetResponse, TVacationResponse, TVacationResponseV2,
} from '../../../types/api';

type Props = {
  data:
    | TBuyerQualResponse
    | TAmortizationResponse
    | TBudgetResponse
    | TBuyersChoiceResponse
    | TBuyerQualChoiceResponseV2
    | TBuyOrRentResponse
    | TBuyOrRentResponseV2
    | TBuyWaitResponse
    | TClosingCostResponse
    | TInvestmentResponse
    | THecmResponse
    | TSellerNetResponse
    | TVacationResponse
    | TConsumerDebtResponse
    | TPaymentSubsidyResponse
    | TVacationResponseV2
  fileName: string
};

const ButtonDownloadPdf = ({ data, fileName }: Props) => {
  const { currentSettings } = useContext(UiSettingsProviderContext);


  const [isLoading, setIsLoading] = useState(false);

  const handleButtonClick = async () => {
    setIsLoading(true);
    try {
    const response = await fetch(currentSettings.apiUrls.downloadPdf, {
      method: 'POST',
      body: JSON.stringify(data)
    });

    const pdfData = await response.text();

    const linkSource = `data:application/pdf;base64,${pdfData}`;

    let alink = document.createElement('a');
    alink.href = linkSource;
    alink.download = `${fileName}.pdf`;
    alink.click();

    setIsLoading(false);
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error('Something went wrong!');
      }
      setIsLoading(false);
    }
  };

  return (
    <ButtonWithSpinner
        loadingIndicator="Downloading..."
        loading={isLoading}
        onClick={handleButtonClick}
        spinnerVariant="primary"
        variant="outline-primary"
        type="button"
      >
        Download pdf
      </ButtonWithSpinner>
  );
};

export default ButtonDownloadPdf;

import React from 'react';

export interface IVProps {
  v: string | number;
}

export interface IColSpanProps {
  v: number;
}

export interface IVTProps {
  v: string;
  t: string;
}

export interface IABProps {
  a: string | number;
  b: string | number;
}

export interface IABCProps {
  a: string | number;
  b: string | number;
  c: string | number;
}

export interface IABCDProps {
  a: string;
  b: string;
  c: string;
  d: string;
}

export interface IVPromptProps {
  v: number | string;
  prompt: string;
}

export interface IMessageProps {
  v: number;
  prompt: string;
}

export interface IVPromptPProps {
  v: number;
  prompt: string;
  p: string | number;
}

export interface IBlank {}

export interface IPrefixVProps {
  prefix: string;
  v: string;
}

/**
 * Old function, should be deprecated
 * use TdCash instead.
 * @param {*} param0
 */
const DetailCashNaked = ({ v }: IVProps) => {
  return TdCash({ v });
};

/**
 * Use TdPercent
 */
const DetailPercent = ({ v }: IVProps) => {
  return TdPercent({ v });
};

const DetailTextBold = ({ v }: IVProps) => {
  return (
    <td>
      <strong>{v}</strong>
    </td>
  );
};

const TdDownloadPdfColSpan = ({ v }: IColSpanProps) => {
  return (
    <td colSpan={v}>
      <strong>Download PDF File for Complete Details</strong>
    </td>
  );
};

const TdDownloadPdf = () => {
  return (
    <td colSpan={5}>
      <strong>Download PDF File for Complete Details</strong>
    </td>
  );
};

const DetailTextBoldTip = ({ v, t }: IVTProps) => {
  return (
    <td data-tip data-for={t}>
      <strong>{v}</strong>
    </td>
  );
};

/**
 * Place text in <td align="center">
 * This is being deprecated to use the more expressive TdAlignCenter
 * @param {String} v
 */
const DetailTextCenter = ({ v }: IVProps) => {
  return TdCenter({ v });
};

/**
 * Format given text inside a <td>
 * @param {string} String to be displayed placed
 */
const DetailText = ({ v }: IVProps) => {
  return TdText({ v });
};

export interface IDolPerText {
  dollAmt: string;
  percAmt: string;
  txtDisplay: string;
}

const DolPerText = ({ dollAmt, percAmt, txtDisplay }: IDolPerText) => {
  let x = addCommas(dollAmt);
  return (
    <td align="center">
      ${x}&nbsp;{percAmt}%&nbsp;{txtDisplay}
    </td>
  );
};

export interface IDolText {
  dollAmt: string;
  txtDisplay: string;
}

const DolText = ({ dollAmt, txtDisplay }: IDolText) => {
  let x = addCommas(dollAmt);
  return (
    <td align="center">
      ${x}&nbsp;{txtDisplay}
    </td>
  );
};

const HtmlDetailTextTwo = ({ a, b }: IABProps) => {
  let x = addCommas(a as string);
  return (
    <td align="center">
      ${x}&nbsp;({b})%
    </td>
  );
};

// API passes in a $ sign, but not a percent
const HtmlDollarPercent = ({ a, b }: IABProps) => {
  let x = addCommas(a as string);
  return (
    <td align="center">
      {x}&nbsp;({b})%
    </td>
  );
};

const HtmlInterestRate = ({ a, b }: IABProps) => {
  let x = addCommas(a as string);
  return (
    <td align="center">
      {x}%&nbsp;/{b}&nbsp;yrs
    </td>
  );
};

const HtmlInterestRateRefactor = ({ v }: IVProps) => {
  return <td align="center">{v}%</td>;
};

const HtmlDetailTwoPercents = ({ a, b, c }: IABCProps) => {
  return (
    <td align="center">
      ({a}&nbsp;%&nbsp;Rate)&nbsp;${b}&nbsp;({c}&nbsp;%)
    </td>
  );
};

const HtmlDetailTextThree = ({ a, b, c, d }: IABCDProps) => {
  return (
    <td align="center">
      {a}&nbsp;${b}&nbsp;{c}&nbsp;{d}%
    </td>
  );
};

const HtmlDetailTextFour = ({ a, b }: IABProps) => {
  return (
    <td align="center">
      {a}&nbsp;${b}
    </td>
  );
};

const HtmlDetailTextFive = ({ a, b }: IABProps) => {
  return (
    <td align="center">
      ${a}&nbsp; After {b} years
    </td>
  );
};

const HtmlDetailTextSix = ({ a }: { a: string | number }) => {
  return <td align="center">${a}&nbsp; in home ownership</td>;
};

/**
 * https://stackoverflow.com/questions/3753483/javascript-thousand-separator-string-format/19840881
 * @param {*} nStr
 */
const addCommas = (nStr: string) => {
  nStr += '';
  var x = nStr?.split('.');
  var x1 = x[0];
  var x2 = x.length > 1 ? '.' + x[1] : '';
  var rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2');
  }
  return x1 + x2;
};

/**
 * Returns param in td with a % suffix
 * @param {v} param0
 */
const TdPercent = ({ v }: IVProps) => {
  return <td align="center">{v}%</td>;
};

const TdPrefixPrompt = ({ a, b }: IABProps) => {
  return (
    <td align="center">
      {a}&nbsp;{b}
    </td>
  );
};

const TdPrefixPromptNoCenter = ({ a, b }: IABProps) => {
  return (
    <td>
      {a}&nbsp;{b}
    </td>
  );
};

/**
 * Returns param in td with a % suffix
 * @param {v} param0
 */
const TdBlank = () => {
  return <td align="center">&nbsp;</td>;
};

const TdCash = ({ v }: IVProps) => {
  let x = addCommas(v as string);
  return <td align="center">${x}</td>;
};

const TdCashPercent = ({ a, b }: IABProps) => {
  let x = addCommas(a as string);
  return (
    <td align="center">
      ${x}&nbsp;{b}%
    </td>
  );
};

/**
 * Place text in <td align="center">
 * @param {String} v
 */
const TdCenter = ({ v }: IVProps) => {
  return <td align="center">{v}</td>;
};

/**
 * Format given text inside a <td>
 * @param {string} String to be displayed placed
 */
const TdText = ({ v }: IVProps) => {
  return <td>{v}</td>;
};

/**
 * Format given text inside a <td>
 * @param {string} String to be displayed placed
 */
const TdTextPrefix = ({ prefix, v }: IPrefixVProps) => {
  return (
    <td>
      {prefix}&nbsp;{v}
    </td>
  );
};

/**
 * Format given text inside a <td>
 * @param {string} String to be displayed placed
 */
const TdTextTwoElements = ({ a, b }: IABProps) => {
  return (
    <td>
      {a}&nbsp;{b}
    </td>
  );
};

/**
 * Format given text inside a <td>
 * @param {string} String to be displayed placed
 */
const TdTextTwoElementsCenter = ({ a, b }: IABProps) => {
  return (
    <td align="center">
      {a}&nbsp;{b}
    </td>
  );
};

/**
 * In some cases a string is spaned across a table.
 * In some cases this prompt can be used to display an empty row.
 *
 * @param {Number} v
 * @param {String} prompt
 */
const RowMessage = ({ v, prompt }: IMessageProps) => {
  return <td colSpan={v}>{prompt}</td>;
};

/**
 * This is somewhat of a kludge and could be considered for refactor
 * in the future.  I had to add this function, because I was having
 * an issue passing in a single object BrowMess into the
 * Buyer Choice and Buyer Qual HTML Reports.
 *
 */
const BrowMess = ({ a, b, c, d }: IABCDProps) => {
  var message;
  if (a != '') {
    message = a;
  } else if (b != '') {
    message = b;
  } else if (c != '') {
    message = c;
  } else if (d != '') {
    message = d;
  }
  return <td>{message}</td>;
};

const TdBuyerQualLimitMessage = () => {
  return (
    <td colSpan={5}>
      Congratulations. A Complete Report is available for you. The PDF file includes Total Loan Amount, Down Payment, Closing Cost, Seller Credits, Additional Cash Required, Breakdown of Monthly
      Payment, Tax Deductions, Tax Savings and a Complete Display of Closing Cost plus much more.
    </td>
  );
};

const TdReverseLimitMessage = () => {
  return (
    <td colSpan={3}>
      A Complete Report of Your Reverse Mortgage Purchasing Options is available for you. The PDF file Includes complete details including Base Loan, Total Loan, Down Payment, Closing Cost, Required
      Cash, Annual Obligations, Future Loan Balance, Accrued Interest and Optional Interest Only Payment.
    </td>
  );
};

const TdVactionLimitMessage = () => {
  return (
    <td colSpan={2}>
      A Complete Report of Vacation Home Buying Options is available. The PDF file Includes Monthly Payments Details, Cash Report Analysis for Best Allocation of Cash, Mortgage details, Estimated
      Deductions for Primary and Vacations Homes, Detailed Closing Cost Report and Potential Property Tax Increase on Non-Owner-Occupied Homes.
    </td>
  );
};

const TdSellerNetLimitMessage = () => {
  return (
    <td colSpan={2}>A complete Seller Net report is available for you. The complete PDF report will include complete details for the estimated Net Proceeds plus explanations and other details.</td>
  );
};

const TdInvestmentLimitMessage = () => {
  return (
    <td colSpan={2}>
      A detailed Investment Analysis Report is available. The PDF file will include Cash Requirements including closing cost details, Mortgage Details, Annual Rents Analysis, Future Values, Equity,
      Capital Gains, Depreciation Details, Potential Tax Liabilities on Sale, ROI, Cap Rate, Debt Service plus much more.
    </td>
  );
};

const TdAlternativePdfMessage = () => {
  return <td colSpan={2}>The report will be sent to the email associated with your login, or the email entered in the form.</td>;
};

export {
  TdAlternativePdfMessage,
  DetailCashNaked,
  DetailPercent,
  DetailText,
  DetailTextBold,
  DetailTextBoldTip,
  DetailTextCenter,
  DolPerText,
  DolText,
  HtmlDetailTextTwo,
  HtmlDetailTwoPercents,
  HtmlDetailTextThree,
  HtmlDetailTextFour,
  HtmlDetailTextFive,
  HtmlDetailTextSix,
  HtmlInterestRate,
  HtmlInterestRateRefactor,
  TdBlank,
  TdCash,
  TdCashPercent,
  TdCenter,
  TdPercent,
  TdPrefixPrompt,
  TdText,
  TdTextPrefix,
  TdTextTwoElements,
  TdDownloadPdf,
  TdDownloadPdfColSpan,
  BrowMess,
  RowMessage,
  TdBuyerQualLimitMessage,
  TdReverseLimitMessage,
  TdVactionLimitMessage,
  TdSellerNetLimitMessage,
  TdInvestmentLimitMessage,
  TdTextTwoElementsCenter,
  TdPrefixPromptNoCenter,
  HtmlDollarPercent,
};

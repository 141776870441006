import React from 'react';

import './styles.css';

type Props = {
  value?: string;
};

export const ReportSectionElementSingle = ({ value }: Props) => (
  <div className="report-values-section__element report-values-section-element">
    <div className="report-values-section-element__title">{value}</div>
  </div>
);

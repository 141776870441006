import React from 'react';
import './styles.css';

type Props = {
  title: string;
  value?: string;
  val2?: string;
};

export const ReportSectionElementDouble = ({ title, value, val2 }: Props) => (
  <div className="report-values-section__element report-values-section-element">
    <div className="report-values-section-element__title">{title}</div>
    <div className="report-values-section-element__title">{value}</div>
    <div className="report-values-section-element__title">{val2}</div>
  </div>
);

/*
 * Copyright 2020-2021 AskChristee, LLC. All rights reserved.
 */

import React, { useContext, useEffect, useRef, useState } from 'react';
import { Accordion, Tab, Tabs } from 'react-bootstrap';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { fetchBuyerChoiceQuick, hBUYERCHOICEQUICK as hBUYERCHOICE } from '../../../../api';
import { ResponseLoanTypes, TBuyerQualChoiceResponseV2 } from '../../../../types/api';
import {
  PoweredBy,
  tipClose_date,
  tipCondo,
  tipCust_name,
  tipEmail,
  tipFiling_status,
  tipFtbuyer,
  tipInput_dol_cred,
  tipInput_ins,
  tipInput_ltv,
  tipInput_ma,
  tipInput_uw_fee,
  tipLend_cred,
  tipLim_piti,
  tipOrg_fee,
  tipProp_t,
  tipSp,
  tipS_cred,
  tipT_cash,
  tipVa_fee_ex,
  tipVa_first,
  tipZip,
  UiSettingsProviderContext,
  tipInput_pmi_dollar,
} from '../../../atoms';
import { ChristeeConfigCtx } from '../../../context';
import {
  ComparisonLoansReport,
  FormInputChangeRate,
  FormInputCreditScore,
  FormInputDatePicker2,
  FormInputDollar,
  FormInputEmail,
  FormInputLoanType,
  FormInputLoanTypeHandle,
  FormInputPercent2,
  FormInputRadioButtons,
  FormInputStateAreaDropdowns,
  FormInputStateAreaDropdownsHandle,
  FormInputTerm,
  FormInputTermHandle,
  FormInputText,
  FormInputZip, FormLoader,
  FormWrapper,
  ValidationModal,
} from '../../../molecules';
import ButtonDownloadPdf from '../../../molecules/ButtonDownloadPdf';
import { ButtonSendPdf } from '../../../molecules/ButtonSendPdf';
import FormInputFirstVaExempt from '../../../molecules/FormInputFirstVaExempt';
import FormInputFirstVaLoan from '../../../molecules/FormInputFirstVaLoan';
import FormMessageVaLoan from '../../../molecules/FormMessageVaLoan';
import { Help } from '../../../molecules/QuickStart';
import { BuyerQualGraphV2 } from '../../Graphs';
import { BuyerChoiceCashReportQuick, BuyerChoiceReportQuick, BuyerQualChoiceReportQuick } from '../../Reports';
import FormInputPMICalculation from '../../../molecules/FormInputPMICalculation';
import { useRecommendations } from '../../../../hooks/useRecommendations';
import { Recommended } from '../../Recommendations';

const BuyersChoiceQuick = () => {
  const defaultValues = {
    account_type: '',
    condo: '',
    cust_email: '',
    cust_name: '',
    cust_phone: '',
    filing_status: 's',
    ftbuyer: 'n',
    input_dol_cred: '',
    input_ltv: '',
    input_ma: '',
    input_uw_fee: '',
    inputcred: '',
    lend_cred: '',
    lim_piti: '',
    m_debts: '',
    org_fee: '',
    prop_t: '',
    q_income: '',
    s_cred: '',
    sp: '',
    t_cash: '',
  };
  const {
    control,
    handleSubmit,
    watch,
    reset,
    trigger,
    setValue,
    formState: { errors },
  } = useForm<any>({ mode: 'onTouched', reValidateMode: 'onBlur', defaultValues });
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { currentSettings } = useContext(UiSettingsProviderContext);
  const { recommendations, requestRecommendations } = useRecommendations();

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  };

  useEffect(() => {
    scrollTop();
  }, []);

  const [dats, setDats] = useState<TBuyerQualChoiceResponseV2>();
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const ctx = useContext(ChristeeConfigCtx);
  const stateAreaRef = useRef<FormInputStateAreaDropdownsHandle>(null);
  const loanTypeRef = useRef<FormInputLoanTypeHandle>(null);
  const termRef = useRef<FormInputTermHandle>(null);

  const onSubmit = async (data: any) => {
    await trigger();
    scrollTop();
    setLoading(true);
    await new Promise((r) => setTimeout(r, 800));
    const doTypeArr = data.doType && data.doType.length > 0 ? data.doType.map((m: any) => m.value) : [];
    const filteredData = {
      ...data,
      state: data.state[0].code,
      county_select: data.county_select[0].code,
      doType: doTypeArr,
      term: Array.isArray(data.term) ? data.term[0].value : data.term ? data.term : '30',
    };

    /**
     * I've changed the params to logged_in_* for all of the forms.  We have
     * an ability to catch the users email from the login, but we also need
     * to be able to update it from the form.
     */
    try {
      let captchaToken;
      if (executeRecaptcha) {
        captchaToken = await executeRecaptcha();
      }
      const modulePromise = fetchBuyerChoiceQuick(
        {
          ...filteredData,
          company_name: currentSettings.companyId,
          logged_in_email: currentSettings.email,
          logged_in_name: currentSettings.name,
          logged_in_phone: currentSettings.phone,
          account_type: currentSettings.accountType,
        },
        ctx?.stage,
        ctx?.key,
        captchaToken
      );

      const recommendationPromise = requestRecommendations({
        zipCode: data.zip,
        state: data.state?.[0].text
      });

      const [res] = await Promise.all([modulePromise, recommendationPromise]);

      if (res.meta?.displayMessage) toast.info(res.meta.displayMessage);

      setDats(res);
      setLoaded(true);
      setLoading(false);
      scrollTop();
    } catch (error) {
      setLoading(false);
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error('Something went wrong!');
      }
    }
  };

  const clearForm = () => {
    stateAreaRef.current?.clear();
    loanTypeRef.current?.clear();
    termRef.current?.clear();
    reset(defaultValues);
    setDats({} as TBuyerQualChoiceResponseV2);
    setLoaded(false);
    setLoading(false);
    scrollTop();
  };

  const watchDoType = watch('doType');

  const show1stTimeBuyer = !!(watch('state')?.find((type: { code: string }) => type.code === 'md') || watchDoType?.find((type: { value: string }) => type.value === 'CONV'));

  return (
    <FormWrapper>
      <div className="buyers-choice">
        <div className="container-fluid">
          <div className="row g-2">
            <h3 className="h2">Quick Buyer Choice</h3>
            <div className="col-lg-3">
              <form className="christy" autoComplete="off">
                <fieldset>
                  <legend className="sr-only">Required Fields:</legend>
                  <FormInputStateAreaDropdowns setValue={setValue} stateWatch={watch('state')} areaWatch={watch('county_select')} control={control} errors={errors} ref={stateAreaRef} />
                  <FormInputZip label="Zip Code" tip={tipZip} error={errors.zip} name="zip" control={control} required={true} />
                  <FormInputChangeRate watchCalcForm={watch()} resetCalcForm={reset} />
                  <FormInputDollar label="Sales Price" error={errors.sp} name="sp" control={control} required={true} tip={tipSp} />
                  <FormInputLoanType watch={watchDoType} ref={loanTypeRef} control={control} errors={errors} required={true} />
                  {watchDoType && watchDoType?.findIndex((type: any) => type.value === 'VA') !== -1 && <FormMessageVaLoan />}
                  <FormInputTerm watch={watch('term')} ref={termRef} control={control} errors={errors} required={true} />
                  <FormInputRadioButtons
                    label="Married"
                    name="filing_status"
                    tip={tipFiling_status}
                    defaultValue="s"
                    required={true}
                    ids={['married', 'single']}
                    values={['m', 's']}
                    control={control}
                    errors={errors}
                  />
                  {show1stTimeBuyer && (
                    <FormInputRadioButtons
                      label="1st Time Buyer"
                      name="ftbuyer"
                      tip={tipFtbuyer}
                      defaultValue="n"
                      required={true}
                      ids={['first_time_yes', 'first_time_no']}
                      control={control}
                      errors={errors}
                    />
                  )}
                </fieldset>
                <Accordion id="optional-inputs">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header data-cy="btn-optional">Optional Inputs</Accordion.Header>
                    <Accordion.Body>
                      <fieldset>
                        <FormInputCreditScore error={errors.inputcred} control={control} />
                        <FormInputDollar label="Enter Cash" tip={tipT_cash} error={errors.t_cash} name="t_cash" control={control} />
                        <FormInputDollar label="Limit Payment" tip={tipLim_piti} error={errors.lim_piti} name="lim_piti" control={control} />
                        <FormInputDollar label="Annual Property Taxes" tip={tipProp_t} error={errors.prop_t} name="prop_t" control={control} />
                        <FormInputDollar label="Annual Property Insurance" tip={tipInput_ins} error={errors.input_ins} name="input_ins" control={control} />
                        <FormInputDatePicker2 label="Close Date" tip={tipClose_date} error={errors.close_date} name="close_date" control={control} />
                        <FormInputPercent2 label="Seller Credit" tip={tipS_cred} error={errors.s_cred} name="s_cred" control={control} />
                        <FormInputDollar label="Seller Credit" tip={tipInput_dol_cred} error={errors.input_dol_cred} name="input_dol_cred" control={control} />
                        <FormInputPercent2 label="Lender Credit" tip={tipLend_cred} error={errors.lend_cred} name="lend_cred" control={control} />
                        <FormInputDollar label="Lender Fees" tip={tipInput_uw_fee} error={errors.input_uw_fee} name="input_uw_fee" control={control} />
                        <FormInputPercent2 label="Loan Origination" tip={tipOrg_fee} error={errors.org_fee} name="org_fee" control={control} max={2} />
                        <FormInputPercent2 label="Down Payment" tip={tipInput_ltv} error={errors.input_ltv} name="input_ltv" control={control} />
                        <FormInputDollar label="Limit Loan Amount" tip={tipInput_ma} error={errors.input_ma} name="input_ma" control={control} />
                        <FormInputDollar label="Condo Fee" tip={tipCondo} error={errors.condo} name="condo" control={control} />
                        <FormInputFirstVaExempt loanTypes={watch('doType')} setValue={setValue} tip={tipVa_fee_ex} control={control} errors={errors} />
                        <FormInputFirstVaLoan loanTypes={watch('doType')} setValue={setValue} tip={tipVa_first} control={control} errors={errors} />
                        <FormInputPMICalculation accountType={currentSettings.accountType} tip={tipInput_pmi_dollar} control={control} errors={errors} />
                        <FormInputText label="Name" tip={tipCust_name} error={errors.cust_name} name="cust_name" control={control} />
                        <FormInputText label="Phone" tip={tipCust_name} error={errors.cust_phone} name="cust_phone" control={control} />
                        <FormInputEmail accountType={currentSettings.accountType} tip={tipEmail} error={errors.cust_email} control={control} />
                      </fieldset>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <p className="mt-3">
                  <ValidationModal label="Submit" errors={errors} onClick={handleSubmit(onSubmit)} />
                  <button type="reset" className="btn btn-outline-secondary me-2" onClick={clearForm}>
                    Clear
                  </button>
                </p>
              </form>
            </div>
            <div className="col-lg-9 position-relative ps-4">
              {!loading && recommendations && <Recommended recommendations={recommendations} />}
              <div className="form-results">
                <div className="w-100 d-flex align-items-center justify-content-end">
                  <Help sFile={hBUYERCHOICE} />
                </div>

                {loading ? (
                 <FormLoader />
                ) : loaded && dats ? (
                  <>
                    <Tabs className="mb-3">
                      {dats.params.pForm.doType.map((doType) => (
                        <Tab className="pt-3" key={doType} eventKey={doType} title={doType}>
                          <BuyerQualChoiceReportQuick dats={dats} loanType={ResponseLoanTypes[doType]} />
                        </Tab>
                      ))}
                      <Tab eventKey="comparison" title="Comparison">
                        <br />

                        <ComparisonLoansReport
                          isBCalcAvailable={dats.meta.is_bCalc}
                          regularReport={<BuyerChoiceReportQuick {...dats!} />}
                          cashReport={<BuyerChoiceCashReportQuick {...dats!} />}
                        />
                      </Tab>
                      <Tab eventKey="spendingGraphs" title="Graphs">
                        <BuyerQualGraphV2 dats={dats} loanTypes={dats.params.pForm.doType.map((loanType) => ResponseLoanTypes[loanType])} />
                      </Tab>
                    </Tabs>

                    <div className="d-flex mt-3">
                      <ButtonDownloadPdf data={dats} fileName="bchoice-quick" />
                      {dats?.meta.email && dats?.meta.email !== 'nil' && <ButtonSendPdf dats={dats} />}
                    </div>
                  </>
                ) : (
                  <>
                    <h4 id="house-title" className="h4">
                      You're seconds away from unlocking your Quick Buyer Choice data.
                    </h4>
                    <img id="house-image" src="https://modules.askchristee.com/images/main.png" alt="home" />
                  </>
                )}
              </div>
              {/* only guests can see this */}

              <div className="w-100 d-flex justify-content-end"></div>
              <div className="w-100 mt-3">
                <PoweredBy />
              </div>
            </div>
          </div>
        </div>
      </div>
    </FormWrapper>
  );
};

export default BuyersChoiceQuick;

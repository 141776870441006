import React from "react";

import {ReportSectionElement, ReportSectionHeader, ReportSectionItem} from "../../../molecules";

import {BuyerQualChoiceReport} from "./index";

interface Props extends BuyerQualChoiceReport {
  calcType: 'aCalc' | 'bCalc'
}

export const ProjectedPaymentDetails = ({ dats, loanType, calcType }: Props) => {
  return (
    <ReportSectionItem>
      <ReportSectionHeader>Projected Payment Details</ReportSectionHeader>
      <ReportSectionElement title="Principal & Interest" value={dats[loanType][calcType]?.disp_pi} />
      <ReportSectionElement title="Mortgage Insurance" value={dats[loanType][calcType]?.disp_mi} />
      <ReportSectionElement title="Property Taxes" value={dats[loanType][calcType]?.disp_pt} />
      <ReportSectionElement title="Property Insurance" value={dats[loanType][calcType]?.disp_ins} />
      <ReportSectionElement title="Condo Fee" value={dats[loanType][calcType]?.disp_condo} />
      <ReportSectionElement title="Total Payment" value={dats[loanType][calcType]?.disp_t_piti} />
    </ReportSectionItem>
  );
}

import React from 'react';
import { TBudgetCalc } from '../../../../types/api';
import { ReportContainer, ReportHeader, ReportSection, ReportSectionHeader, ReportSectionItem, ReportSectionMessElement } from '../../../molecules';

interface Props {
  calc: TBudgetCalc;
}

export type TDatBudget = Props;

const Budget: React.FC<Props> = ({ calc }) => {
  return (
    <ReportContainer>
      <ReportHeader>{calc.browhead}</ReportHeader>
      <ReportHeader>{calc.browhead2}</ReportHeader>
      <ReportSection>
        <ReportSectionItem>
          <ReportSectionHeader>{calc.header1}</ReportSectionHeader>
          <ReportSectionMessElement>{calc.disp_sp}</ReportSectionMessElement>
          <ReportSectionMessElement>{calc.disp_ma}</ReportSectionMessElement>
          <ReportSectionMessElement>{calc.disp_loan}</ReportSectionMessElement>
          <ReportSectionMessElement>{calc.disp_pi}</ReportSectionMessElement>
          <ReportSectionMessElement>{calc.disp_mi}</ReportSectionMessElement>
          <ReportSectionMessElement>{calc.disp_pt}</ReportSectionMessElement>
          <ReportSectionMessElement>{calc.disp_ins}</ReportSectionMessElement>
          <ReportSectionMessElement>{calc.disp_condo}</ReportSectionMessElement>
          <ReportSectionMessElement>{calc.disp_piti}</ReportSectionMessElement>
        </ReportSectionItem>
        <ReportSectionItem>
          <ReportSectionHeader>{calc.header2}</ReportSectionHeader>
          <ReportSectionMessElement>{calc.disp_fed_tax}</ReportSectionMessElement>
          <ReportSectionMessElement>{calc.disp_ss}</ReportSectionMessElement>
          <ReportSectionMessElement>{calc.disp_medicare}</ReportSectionMessElement>
          <ReportSectionMessElement>{calc.disp_state_tax}</ReportSectionMessElement>
          <ReportSectionMessElement>{calc.disp_retire}</ReportSectionMessElement>
        </ReportSectionItem>
        <ReportSectionItem>
          <ReportSectionHeader>{calc.header3}</ReportSectionHeader>
          <ReportSectionMessElement>{calc.disp_alimony}</ReportSectionMessElement>
          <ReportSectionMessElement>{calc.disp_cable}</ReportSectionMessElement>
          <ReportSectionMessElement>{calc.disp_car}</ReportSectionMessElement>
          <ReportSectionMessElement>{calc.disp_phone}</ReportSectionMessElement>
          <ReportSectionMessElement>{calc.disp_care}</ReportSectionMessElement>
          <ReportSectionMessElement>{calc.disp_support}</ReportSectionMessElement>
        </ReportSectionItem>
        <ReportSectionItem>
          <ReportSectionHeader>{calc.header4}</ReportSectionHeader>
          <ReportSectionMessElement>{calc.disp_card}</ReportSectionMessElement>
          <ReportSectionMessElement>{calc.disp_food}</ReportSectionMessElement>
          <ReportSectionMessElement>{calc.disp_health_ins}</ReportSectionMessElement>
          <ReportSectionMessElement>{calc.disp_auto_ins}</ReportSectionMessElement>
          <ReportSectionMessElement>{calc.disp_pet}</ReportSectionMessElement>
          <ReportSectionMessElement>{calc.disp_tuition}</ReportSectionMessElement>
          <ReportSectionMessElement>{calc.disp_util}</ReportSectionMessElement>
          <ReportSectionMessElement>{calc.disp_other_amt}</ReportSectionMessElement>
        </ReportSectionItem>
        <ReportSectionItem>
          <ReportSectionHeader>{calc.header5}</ReportSectionHeader>
          <ReportSectionMessElement>{calc.disp_m_income}</ReportSectionMessElement>
          <ReportSectionMessElement>{calc.disp_obligation}</ReportSectionMessElement>
          <ReportSectionMessElement>{calc.disp_obligation_percent}</ReportSectionMessElement>
          <ReportSectionMessElement>{calc.disp_disc_income}</ReportSectionMessElement>
          <ReportSectionMessElement>{calc.disp_disc_percent}</ReportSectionMessElement>
        </ReportSectionItem>
      </ReportSection>
    </ReportContainer>
  );
};

export default Budget;

import React from 'react';

import { ReportSectionHeader, ReportSectionItem, ReportSectionElement, ReportSectionElementSingle } from '../../../molecules';

import { TVacationResultsV2 } from '../../../../types/api';

type Props = {
  dats: TVacationResultsV2;
  calcType: 'aCalc1' | 'aCalc2' | 'aCalc3' | 'aCalc4';
};

export const LoanDetails = ({ dats, calcType }: Props) => {
  return (
    <ReportSectionItem>
      <ReportSectionHeader>Loan Details</ReportSectionHeader>
      <ReportSectionElement title="Payment Interest Rate" value={dats[calcType]?.rate_display} />
      <ReportSectionElement title="Base Loan Amount" value={dats[calcType]?.disp_ma} />
      <ReportSectionElement title="Total Loan Amount" value={dats[calcType]?.disp_total_mtge} />
      <ReportSectionElement title="Total Monthly Payment" value={dats[calcType]?.disp_t_piti} />
      <ReportSectionElement title="Debt Ratio" value={dats[calcType]?.disp_cal_br} />
      <ReportSectionElementSingle value={dats[calcType]?.ratios_results} />
    </ReportSectionItem>
  );
};

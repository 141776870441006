/*
 * Copyright 2020-2021 AskChristee, LLC. All rights reserved.
 */

import React, { useContext, useEffect, useRef, useState } from 'react';
import {Accordion, Tab, Tabs} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import {hSELLERNET} from '../../../../api';
import {
  FormInputDatePicker2,
  FormInputDollar,
  FormInputEmail,
  FormInputLoanTypeSingle,
  FormInputLoanTypeSingleHandle,
  FormInputPercent2,
  FormInputRadioButtons,
  FormInputStateAreaDropdowns,
  FormInputStateAreaDropdownsHandle,
  FormInputTerm,
  FormInputTermHandle,
  FormInputText,
  FormInputZip, FormLoader, FormWrapper,
  ValidationModal,
} from '../../../molecules';
import { Help } from '../../../molecules/QuickStart';
import {
  tipBuyer_ma,
  tipClose_date,
  tipCust_addr,
  tipCust_name,
  tipEmail,
  tipEx_mtge,
  tipInput_dol_cred,
  tipMa_bal,
  tipMtge_date,
  tipOrg_fee,
  tipProp_t,
  tipSales_cost,
  tipSellerNetInt_rate,
  tipSp,
  tipS_cred,
  tipTaxes_paid,
  tipZip,
  UiSettingsProviderContext, PoweredBy,
} from '../../../atoms';
import { ChristeeConfigCtx } from '../../../context';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { toast } from 'react-toastify';
import { fetchSellerNet } from '../../../../api';
import { TSellerNetResponse } from '../../../../types/api';
import { ButtonSendPdf } from '../../../molecules/ButtonSendPdf';
import { SellerNetReportV2 } from '../../Reports';
import ButtonDownloadPdf from "../../../molecules/ButtonDownloadPdf";
import { useRecommendations } from '../../../../hooks/useRecommendations';
import { Recommended } from '../../Recommendations';
import HomeMainImage from "../../../../images/main.png"

const SellerNet = () => {
  const defaultValues = {
    account_type: '',
    buyer_ma: '',
    comm: '',
    condo: '0',
    cust_addr: '',
    cust_email: '',
    cust_name: '',
    cust_phone: '',
    ex_mtge: '',
    input_dol_cred: '',
    int_rate: '',
    ma_bal: '',
    main_amt: '',
    org_fee: '',
    prop_app: '',
    prop_mgt: '',
    prop_t: '',
    rent_income: '',
    rent_increase: '',
    report_years: '',
    s_cred: '',
    sales_cost: '',
    sp: '',
    stateSelect: '',
    vac_allow: '',
  };
  const {
    control,
    handleSubmit,
    watch,
    reset,
    trigger,
    setValue,
    formState: { errors },
  } = useForm<any>({ mode: 'onTouched', reValidateMode: 'onBlur', defaultValues });
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { currentSettings } = useContext(UiSettingsProviderContext);
  const { recommendations, requestRecommendations } = useRecommendations();

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  };

  useEffect(() => {
    scrollTop();
  }, []);

  const [dats, setDats] = useState<TSellerNetResponse>();
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const ctx = useContext(ChristeeConfigCtx);
  const stateAreaRef = useRef<FormInputStateAreaDropdownsHandle>(null);
  const loanTypeRef = useRef<FormInputLoanTypeSingleHandle>(null);
  const termRef = useRef<FormInputTermHandle>(null);

  const onSubmit = async (data: any) => {
    await trigger();
    scrollTop();
    setLoading(true);
    await new Promise((r) => setTimeout(r, 800));
    const doTypeArr = data.doType && data.doType.length > 0 ? data.doType.map((m: any) => m.value) : [];
    const filteredData = {
      ...data,
      state: data.state[0].code,
      county_select: data.county_select[0].code,
      doType: doTypeArr,
      term: Array.isArray(data.term) ? data.term[0].value : data.term ? data.term : '30',
    };

    try {
      let captchaToken;
      if (executeRecaptcha) {
        captchaToken = await executeRecaptcha();
      }
      const modulePromise = fetchSellerNet(
        {
          ...filteredData,
          company_name: currentSettings.companyId,
          logged_in_email: currentSettings.email,
          logged_in_name: currentSettings.name,
          logged_in_phone: currentSettings.phone,
          account_type: currentSettings.accountType,
        },
        ctx?.stage,
        ctx?.key,
        captchaToken
      );

      const recommendationPromise = requestRecommendations({
        zipCode: data.zip,
        state: data.state?.[0].text
      });

      const [res] = await Promise.all([modulePromise, recommendationPromise]);

      if (res.meta?.displayMessage) toast.info(res.meta.displayMessage);

      setDats(res);
      setLoaded(true);
      setLoading(false);
      scrollTop();
    } catch (error) {
      setLoading(false);
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error('Something went wrong!');
      }
    }
  };

  const clearForm = () => {
    stateAreaRef.current?.clear();
    loanTypeRef.current?.clear();
    termRef.current?.clear();
    reset(defaultValues);
    setDats({} as TSellerNetResponse);
    setLoaded(false);
    setLoading(false);
    scrollTop();
  };

  return (
    <FormWrapper>
      <div className="buyers-choice">
        <div className="container-fluid">
          <div className="row g-2">
            <div className="col-lg-3">
              <h3 className="h2">Seller Net</h3>
              <form className="christy" autoComplete="off">
                <fieldset>
                  <legend className="sr-only">Required Fields:</legend>
                  <FormInputStateAreaDropdowns setValue={setValue} stateWatch={watch('state')} areaWatch={watch('county_select')} control={control} errors={errors} ref={stateAreaRef} />
                  <FormInputZip label="Zip Code" tip={tipZip} error={errors.zip} name="zip" control={control} required={true} />
                  <FormInputDollar label="Sales Price" tip={tipSp} error={errors.sp} name="sp" control={control} required={true} />
                  <FormInputPercent2 label="Sales Cost" tip={tipSales_cost} error={errors.comm} name="comm" control={control} required={true} />
                </fieldset>
                <Accordion id="optional-inputs">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header data-cy="btn-optional">Optional Inputs</Accordion.Header>
                    <Accordion.Body>
                      <fieldset>
                        <FormInputDollar label="Annual Property Taxes" tip={tipProp_t} error={errors.prop_t} name="prop_t" control={control} />
                        <FormInputDatePicker2 label="Close Date" tip={tipClose_date} error={errors.close_date} name="close_date" control={control} />
                        <FormInputPercent2 label="Seller Credit" tip={tipS_cred} error={errors.s_cred} name="s_cred" control={control} />
                        <FormInputDollar label="Seller Credit" tip={tipInput_dol_cred} error={errors.input_dol_cred} name="input_dol_cred" control={control} />
                        <FormInputPercent2 label="Loan Origination" tip={tipOrg_fee} error={errors.org_fee} name="org_fee" control={control} max={2} />
                        <FormInputRadioButtons
                          label="Property Taxes Paid"
                          name="taxes_paid"
                          tip={tipTaxes_paid}
                          defaultValue="y"
                          ids={['taxes_paid_yes', 'taxes_paid_no']}
                          control={control}
                          errors={errors}
                        />
                        <FormInputDollar label="Mortgage Balance" tip={tipMa_bal} error={errors.ma_bal} name="ma_bal" control={control} />
                        <FormInputPercent2 label="Existing Loan Int Rate" tip={tipSellerNetInt_rate} error={errors.int_rate} name="int_rate" control={control} />
                        <FormInputDollar label="Original Loan Amount" tip={tipEx_mtge} error={errors.ex_mtge} name="ex_mtge" control={control} />
                        <FormInputDatePicker2 label="Original Loan Date" tip={tipMtge_date} error={errors.mtge_date} name="mtge_date" control={control} />
                        <FormInputTerm label="Original Loan Term" watch={watch('term')} ref={termRef} control={control} errors={errors} />
                        <FormInputDollar label="Buyer Loan Amount" tip={tipBuyer_ma} error={errors.buyer_ma} name="buyer_ma" control={control} />
                        <FormInputLoanTypeSingle label="Buyer Loan Type" watch={watch('doType')} ref={loanTypeRef} control={control} errors={errors} />
                        <FormInputText label="Name" tip={tipCust_name} error={errors.cust_name} name="cust_name" control={control} />
                        <FormInputText label="Address" tip={tipCust_addr} error={errors.cust_addr} name="cust_addr" control={control} />
                        <FormInputText label="Phone" tip={tipCust_name} error={errors.cust_phone} name="cust_phone" control={control} />
                        <FormInputEmail accountType={currentSettings.accountType} tip={tipEmail} error={errors.cust_email} control={control} />
                      </fieldset>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <p className="mt-3">
                  <ValidationModal label="Submit" errors={errors} onClick={handleSubmit(onSubmit)} />
                  <button type="reset" className="btn btn-outline-secondary me-2" onClick={clearForm}>
                    Clear
                  </button>
                </p>
              </form>
            </div>
            <div className="col-lg-9 position-relative ps-4">
              {!loading && recommendations && <Recommended recommendations={recommendations} />}
              <div className="form-results">
                <div className="w-100 d-flex align-items-center justify-content-end">
                  <Help sFile={hSELLERNET} />
                </div>
                {loading ? (
                  <FormLoader />
                ) : (loaded && dats) ? (
                  <>
                    <Tabs id="controlled-tab" activeKey='main' className="mb-3">
                      <Tab className="pt-3" eventKey="main" title="Results">
                        <div className="mb-3" style={{ maxWidth: "720px" }}>
                          <SellerNetReportV2 dats={dats.results} />
                          {/* only guests can see this */}

                        </div>
                      </Tab>
                    </Tabs>

                    <div className="d-flex mt-3">
                      <ButtonDownloadPdf data={dats} fileName="seller-net" />
                      {dats?.meta.email && dats?.meta.email !== 'nil' && <ButtonSendPdf dats={dats} />}
                    </div>
                  </>
                ) : (
                  <>
                    <h4 id="house-title" className="h4">
                      You're seconds away from unlocking your Seller Net data.
                    </h4>
                    <img id="house-image" src={HomeMainImage} alt="home" />
                  </>
                )}
              </div>
              <div className="w-100 mt-3">
                <PoweredBy />
              </div>
            </div>
          </div>
        </div>
      </div>
    </FormWrapper>
  );
};

export default SellerNet;

import React from 'react';

import Switch from 'react-switch';

type Props = {
  text: string,
  onChange: () => void;
  checked: boolean;
};

const CustomSwitch = ({ text, onChange, checked }: Props) => {
  return (
    <div className="d-flex">
      <div className="me-2" style={{ whiteSpace: 'nowrap' }}>
        {text}
      </div>
      <Switch size={18} checkedIcon={false} uncheckedIcon={false} onColor="#3CBBC7" offColor="#87909D" onChange={onChange} checked={checked} />
    </div>
  );
};

export default CustomSwitch;

import React from "react";

import {
  ReportSectionHeader,
  ReportSectionItemMess,
  ReportSectionMessElement
} from "../../../molecules";

import {TBuyOrRentResponseObjectV2} from "../../../../types/api";

export const LoanMess = ({ aCalc }: TBuyOrRentResponseObjectV2) => {
  return (
    <ReportSectionItemMess>
      <ReportSectionHeader disableBottomLine>{aCalc?.loan_mess}</ReportSectionHeader>
      <ReportSectionMessElement>{aCalc?.m_note}</ReportSectionMessElement>
      <ReportSectionMessElement>{aCalc?.m_note1}</ReportSectionMessElement>
      <ReportSectionMessElement>{aCalc?.m_note2}</ReportSectionMessElement>
      <ReportSectionMessElement>{aCalc?.m_note3}</ReportSectionMessElement>
      <ReportSectionMessElement>{aCalc?.m_note4}</ReportSectionMessElement>
      <ReportSectionMessElement>{aCalc?.m_note5}</ReportSectionMessElement>
    </ReportSectionItemMess>
  );
}

/*
 * Copyright 2020-2021 AskChristee, LLC. All rights reserved.
 */


import React from 'react';

import { Container, Row } from 'react-bootstrap';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import GenericPie from '../InitialPie/GenericPie';
import {ResponseLoanTypes, TBuyerQualChoiceResponseV2} from '../../../../types/api';

type TcalcTypes = "aCalc" | "bCalc";

type Props = {
  dats: TBuyerQualChoiceResponseV2,
  loanTypes: ResponseLoanTypes[],
}

const BuyerQualChoice = ({ dats, loanTypes }: Props) => {
  const calcTypes: TcalcTypes[] = ["aCalc"];
  if (dats?.[loanTypes[0]].bCalc) calcTypes.push("bCalc");

  return (
    <Container>
      {loanTypes.map((loanType) => calcTypes.map((calcType) => (
          <Row>
            {dats?.[loanType]?.[calcType] && (
              <GenericPie
                report_type={
                  dats?.[loanType]?.[calcType]?.report_type
                  + ': '
                  + (calcType === "aCalc"
                    ? 'Minimum Cash'
                    : 'Spending Cash')
                }
                fumess={dats[loanType][calcType]!.fumess}
                temp_pi={dats?.[loanType]?.[calcType]!.res_pi}
                temp_pt={dats?.[loanType]?.[calcType]!.res_pt}
                temp_mi={dats?.[loanType]?.[calcType]!.res_mi}
                temp_ins={dats?.[loanType]?.[calcType]!.res_ins}
                condo={dats?.[loanType]?.[calcType]!.res_condo}
                t_piti={Number(dats?.[loanType]?.[calcType]!.t_piti)}
              />
            )}
          </Row>
        ))
      )}
    </Container>
  );
};

export default BuyerQualChoice;

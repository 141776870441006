import React, { useEffect, useState } from 'react';

import { Button, Form, Modal, Spinner } from 'react-bootstrap';

import FormInputNumber from '../FormInputNumber';
import { generateRenderRatesFields, RenderRatesFields } from './helpers';

type Props = {
  errors: any,
  control: any,
  show: boolean,
  hideModal: any,
  watchRates: any,
  handleSubmit: any,
  isLoading: boolean,
  resetCalcForm: any,
  watchCalcForm: any,
}

export const FormInterestRatesModal = ({
  show,
  hideModal,
  resetCalcForm,
  watchCalcForm,
  errors,
  watchRates,
  handleSubmit,
  isLoading,
  control,
}: Props) => {
  const [ratesToRender, setRatesToRender] = useState<RenderRatesFields | null>(null);

  const onSubmit = (data: any) => {
    resetCalcForm({
      ...watchCalcForm,
      interest_rates: data,
    });
    hideModal();
  };

  useEffect(() => {
    if (watchRates) {
      const newRatesToRender = generateRenderRatesFields(watchRates);
      setRatesToRender(newRatesToRender);
    }
  }, [watchRates]);

  return (
    <>
      <Modal
        show={show}
        onHide={hideModal}
        backdrop='static'
        centered
        size='lg'
        aria-labelledby='amortization-help-title'
      >
        <Modal.Header closeButton className='display'>
          <h4 className='fw-bold px-4 my-0'>Interest Rates</h4>
          <Modal.Title id='amortization-help-title'>&nbsp;</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {isLoading ? <div style={{ height: '350px' }} className='d-flex justify-content-center align-items-center'>
              <Spinner
                className='loader'
                animation='border'
                variant='primary'
              />
            </div> : <div className='container content spacebelow'>
              <div className='row'>
                {ratesToRender && <Form onSubmit={handleSubmit(onSubmit)} className='col-md-12'>
                  <div className='d-flex justify-content-center flex-column flex-lg-row'>
                    {Object.entries(ratesToRender).map(([year, rates]) => rates.length > 0 && (
                      <div className='mx-1 mx-lg-4' key={year + rates}>
                        <h3 className='text-center'>{year}</h3>
                        <div>
                          {rates.map((rate) => (
                            <FormInputNumber
                              required
                              label={rate.label}
                              control={control}
                              min={{ value: 2, message: 'Minimum value is 2' }}
                              max={{ value: 16, message: 'Minimum value is 16' }}
                              tip={rate.tip}
                              key={rate.name}
                              name={rate.name}
                              error={errors.interest_rates?.[rate.name]}
                            />
                          ))}
                        </div>
                      </div>))}
                  </div>
                  <div className='px-0 px-lg-5'>
                    <div className='mx-1 mx-lg-5 d-flex justify-content-center justify-content-lg-end'>
                      <Button type='submit' className='mx-0 mx-lg-5 mt-4 col-12 col-lg-auto' variant='primary'>
                        Save
                      </Button>
                    </div>
                  </div>
                </Form>}
              </div>
            </div>
            }
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

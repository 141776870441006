import React from 'react';

import {ReportSectionElement, ReportSectionHeader, ReportSectionItem} from "../../../molecules";

import {TBuyOrRentResponseObjectV2} from "../../../../types/api";

export const MortgageDetails = ({ aCalc }: TBuyOrRentResponseObjectV2) => {
  return (
    <ReportSectionItem>
      <ReportSectionHeader>Mortgage Details {aCalc.rate_display}</ReportSectionHeader>
      <ReportSectionElement title="Prin & Interest" value={aCalc.disp_pi} />
      <ReportSectionElement title="Mortgage Ins" value={aCalc.disp_ins} />
      <ReportSectionElement title="Property Tax" value={aCalc.disp_pt} />
      <ReportSectionElement title="Property Ins" value={aCalc.disp_ins} />
      <ReportSectionElement title="Condo Fee" value={aCalc.disp_condo} />
      <ReportSectionElement title="Total Payment" value={aCalc.disp_t_piti} />
    </ReportSectionItem>
  );
};

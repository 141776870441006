import React from 'react';

import CustomLoader from '../../atoms/CustomLoader';

const FormLoader = () => {
  return (
    <div className="w-100 d-flex justify-content-center align-items-center" style={{ height: "350px" }}>
      <CustomLoader />
    </div>
    )
}

export default FormLoader;

import React from 'react';
import { ReportSectionElement, ReportSectionHeader, ReportSectionItem } from '../../../molecules';
import { BuyerQualChoiceReport } from './index';

interface Props extends BuyerQualChoiceReport {
  calcType: 'aCalc' | 'bCalc';
}

export const LoanDetails = ({ dats, loanType, calcType }: Props) => {
  return (
    <ReportSectionItem>
      <ReportSectionHeader>Loan Details</ReportSectionHeader>
      <ReportSectionElement title="Payment Interest Rate" value={dats[loanType][calcType]?.rate_display} />
      <ReportSectionElement title="Base Loan Amount" value={dats[loanType][calcType]?.disp_ma} />
      <ReportSectionElement title="Total Loan Amount" value={dats[loanType][calcType]?.disp_total_mtge} />
      <ReportSectionElement title="Total Monthly Payment" value={dats[loanType][calcType]?.disp_t_piti} />
      <ReportSectionElement title=" " value={dats[loanType][calcType]?.ratio_string} />
    </ReportSectionItem>
  );
};

import React from 'react';

import CustomSwitch from '../CustomSwitch';


type Props = {
  onChange: () => void;
  checked: boolean;
}

const MinimumCashSwitch = (props: Props) => {
  return (
    <CustomSwitch text="See Different Cash Options" {...props} />
  );
};

export default MinimumCashSwitch;

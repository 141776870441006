import React from 'react';

import {ReportSectionHeader, ReportSectionItem, ReportSectionElement} from "../../../molecules";

import {TVacationResultsV2} from "../../../../types/api";

type Props = {
  dats: TVacationResultsV2,
  calcType: "aCalc1" | "aCalc2" | "aCalc3" | "aCalc4"
}

export const CashRequirements = ({ dats, calcType }: Props) => {
  return (
    <ReportSectionItem>
      <ReportSectionHeader>Cash Requirements</ReportSectionHeader>
      <ReportSectionElement title="Down Payment" value={dats[calcType]?.disp_d_payment} />
      <ReportSectionElement title="Closing Cost" value={dats[calcType]?.disp_totalcc} />
      <ReportSectionElement title="Seller Credit" value={dats[calcType]?.disp_dol_cred} />
      <ReportSectionElement title="Lender Credit" value={dats[calcType]?.disp_dol_lend} />
      <ReportSectionElement title="Required Cash" value={dats[calcType]?.disp_r_cash} />
      <ReportSectionElement title="Cash Available" value={dats[calcType]?.disp_t_cash} />
      <ReportSectionElement title="Addition Cash Req." value={dats[calcType]?.disp_ex_cash} />
    </ReportSectionItem>
  );
};

import React from "react";

import "./styles.css";

type Props = {
  children: React.ReactNode
}

export const ReportSectionItemMess = ({ children }: Props) => (
  <div className="report__values-section report-values-section-item-mess">
    {children}
  </div>
)

/*
 * Copyright 2020-2021 AskChristee, LLC. All rights reserved.
 */

import React from 'react';

import { TSellerNetResults } from "../../../../types/api";
import {
  ReportHeader,
  ReportSection
} from "../../../molecules";

import {ReportValues} from "./ReportValues";

export type SellerNetReport = {
  dats: TSellerNetResults,
}

const SellerNet = ({ dats }: SellerNetReport) => {
  return (
    <div>
      <ReportHeader>{dats.aCalc?.fumess}</ReportHeader>
      <ReportHeader>{dats.aCalc?.report_type}</ReportHeader>

      <ReportSection>
        <ReportValues calc={dats.aCalc} />
      </ReportSection>
    </div>
  );
};

export default SellerNet;

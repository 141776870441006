import React from 'react';
import { Controller } from 'react-hook-form';
import { FloatingLabel, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

type FormInputDatePickerProps = {
    label: string;
    tip: string;
    name: string;
    error: any;
    control: any;
    required?: boolean;
};

const FormInputDatePicker2 = ({ label, tip, name, error, control, required=false }: FormInputDatePickerProps) => {

    const options = {
        required: required,
    }
    const idName = name.toLocaleLowerCase().replace(/_/, '-');

    const errorMessage = required ? 'Required' : 'Incorrect';

    return (
        <div className="date-picker-input" id={idName}>
            <FloatingLabel controlId="floatingSelect"
                           label={error ? label + ' ' + errorMessage : label}
                           className={error ? 'alert-expand' : ''}
            >
                <Controller
                  name={name}
                  rules={options}
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      className="date-picker form-control"
                      onChange={field.onChange}
                      value={field.value}
                      clearIcon={null}
                    />
                  )}
                />

                <OverlayTrigger placement="right" trigger="click" rootClose overlay={<Tooltip>{tip}</Tooltip>} >
                    <a className="tooltippanel">
                        <FontAwesomeIcon icon={faInfoCircle} />
                    </a>
                </OverlayTrigger>
            </FloatingLabel>
        </div>
    );
};

export default FormInputDatePicker2;

/*
 * Copyright 2020-2021 AskChristee, LLC. All rights reserved.
 */

import React, { useContext, useEffect, useRef, useState } from 'react';
import {Accordion, Tab, Tabs} from 'react-bootstrap';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {fetchBuyOrRentV2, hBUYRENT} from '../../../../api';
import {ResponseLoanTypes, TBuyOrRentResponseV2} from '../../../../types/api';
import {
  tipCondo,
  tipCust_name,
  tipEmail,
  tipFiling_status,
  tipFtbuyer,
  tipInc_rent,
  tipInput_ltv,
  tipInput_ma,
  tipInvest_return,
  tipM_rent,
  tipProp_app,
  tipProp_t,
  tipQ_income,
  tipReport_years,
  tipRob_doType,
  tipSp,
  tipS_cred,
  tipVa_fee_ex,
  tipVa_first,
  tipZip,
  UiSettingsProviderContext, PoweredBy,
} from '../../../atoms';
import { ChristeeConfigCtx } from '../../../context';
import {
  FormInputChangeRate,
  FormInputCreditScore,
  FormInputEmail,
  FormInputDollar,
  FormInputLoanTypeSingle,
  FormInputLoanTypeSingleHandle,
  FormInputNumber,
  FormInputPercent2,
  FormInputRadioButtons,
  FormInputStateAreaDropdowns,
  FormInputStateAreaDropdownsHandle,
  FormInputTerm,
  FormInputTermHandle,
  FormInputText,
  FormInputZip,
  ValidationModal,
  FormWrapper, FormLoader,
} from '../../../molecules';
import FormInputFirstVaLoan from '../../../molecules/FormInputFirstVaLoan';
import FormMessageVaLoan from '../../../molecules/FormMessageVaLoan';
import {BuyOrRentReportV2} from '../../Reports';
import FormInputFirstVaExempt from "../../../molecules/FormInputFirstVaExempt";
import {Help} from "../../../molecules/QuickStart";
import ButtonDownloadPdf from "../../../molecules/ButtonDownloadPdf";
import {ButtonSendPdf} from "../../../molecules/ButtonSendPdf";
import { useRecommendations } from '../../../../hooks/useRecommendations';
import { Recommended } from '../../Recommendations';
import HomeMainImage from "../../../../images/main.png"

const BuyOrRent = () => {
  const defaultValues = {
    account_type: '',
    condo: '',
    cust_email: '',
    cust_name: '',
    cust_phone: '',
    filing_status: 's',
    ftbuyer: 'n',
    inc_rent: '',
    input_dol_cred: '',
    input_ltv: '',
    input_ma: '',
    input_uw_fee: '',
    inputcred: '',
    int_rate2: '',
    int_rate: '',
    invest_return: '',
    lend_cred: '',
    lim_piti: '',
    m_debts: '',
    m_rent: '',
    org_fee: '',
    prop_app: '',
    prop_t: '',
    q_income: '',
    report_years: '',
    s_cred: '',
    sp: '',
    t_cash: '',
  };
  const {
    control,
    handleSubmit,
    watch,
    reset,
    trigger,
    setValue,
    formState: { errors },
  } = useForm<any>({ mode: 'onTouched', reValidateMode: 'onBlur', defaultValues });
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { currentSettings } = useContext(UiSettingsProviderContext);
  const { recommendations, requestRecommendations } = useRecommendations();

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  };

  useEffect(() => {
    scrollTop();
  }, []);

  const [dats, setDats] = useState<TBuyOrRentResponseV2>();
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);

  const ctx = useContext(ChristeeConfigCtx);

  const stateAreaRef = useRef<FormInputStateAreaDropdownsHandle>(null);
  const loanTypeRef = useRef<FormInputLoanTypeSingleHandle>(null);
  const termRef = useRef<FormInputTermHandle>(null);

  const onSubmit = async (data: any) => {
    await trigger();

    scrollTop();
    setLoading(true);
    await new Promise((r) => setTimeout(r, 800));

    const doTypeArr = data.doType && data.doType.length > 0 ? data.doType.map((m: any) => m.value) : [];
    const filteredData = {
      ...data,
      state: data.state[0].code,
      county_select: data.county_select[0].code,
      doType: doTypeArr,
      term: Array.isArray(data.term) ? data.term[0].value : data.term ? data.term : '30',
    };

    try {
      let captchaToken;
      if (executeRecaptcha) {
        captchaToken = await executeRecaptcha();
      }
      const modulePromise = fetchBuyOrRentV2(
        {
          ...filteredData,
          company_name: currentSettings.companyId,
          logged_in_email: currentSettings.email,
          logged_in_name: currentSettings.name,
          logged_in_phone: currentSettings.phone,
          account_type: currentSettings.accountType,
        },
        ctx?.stage,
        ctx?.key,
        captchaToken
      );

      const recommendationPromise = requestRecommendations({
        zipCode: data.zip,
        state: data.state?.[0].text
      });

      const [res] = await Promise.all([modulePromise, recommendationPromise]);

      if (res.meta?.displayMessage) toast.info(res.meta.displayMessage);

      setDats(res);
      setLoaded(true);
      setLoading(false);
      scrollTop();
    } catch (error) {
      setLoading(false);
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error('Something went wrong!');
      }
    }
  };

  const clearForm = () => {
    stateAreaRef.current?.clear();
    loanTypeRef.current?.clear();
    termRef.current?.clear();

    reset(defaultValues);
    setDats({} as TBuyOrRentResponseV2);
    setLoaded(false);
    setLoading(false);
    scrollTop();
  };

  const watchDoType = watch('doType');

  const show1stTimeBuyer = !!(
    watch('state')?.find((type: { code: string; text: string }) => type.code === 'md') || watchDoType?.find((type: { value: string; text: string }) => type.value === 'CONV')
  );

  return (
    <FormWrapper>
      <div className="buyers-choice">
        <div className="container-fluid">
          <div className="row g-1">
            <div className="col-lg-3">
              <h3 className="h2">Buy or Rent</h3>
              <form className="christy" autoComplete="off">
                <fieldset>
                  <legend className="sr-only">Required Fields:</legend>
                  <FormInputStateAreaDropdowns setValue={setValue} stateWatch={watch('state')} areaWatch={watch('county_select')} control={control} errors={errors} ref={stateAreaRef} />
                  <FormInputZip label="Zip Code" tip={tipZip} error={errors.zip} name="zip" control={control} required={true} />
                  <FormInputDollar label="Sales Price" error={errors.sp} name="sp" tip={tipSp} control={control} required={true} />
                  <FormInputDollar label="Monthly Rent" tip={tipM_rent} error={errors.m_rent} name="m_rent" control={control} required={true} />
                  <FormInputPercent2 label="Annual Rent Increase" tip={tipInc_rent} error={errors.inc_rent} name="inc_rent" required={true} control={control} />
                  <FormInputLoanTypeSingle watch={watchDoType} ref={loanTypeRef} tip={tipRob_doType} required={true} control={control} errors={errors} />
                  {watchDoType && watchDoType?.findIndex((type: any) => type.value === 'VA') !== -1 && <FormMessageVaLoan />}
                  <FormInputChangeRate watchCalcForm={watch()} resetCalcForm={reset} />
                  <FormInputTerm watch={watch('term')} ref={termRef} control={control} errors={errors} required={true} />
                  <FormInputDollar label="Annual Gross Income" tip={tipQ_income} error={errors.q_income} name="q_income" required={true} control={control} />
                  <FormInputNumber label="Years for Report" tip={tipReport_years} error={errors.report_years} name="report_years" required={true} control={control} />
                  <FormInputPercent2 label="Return on Investment" tip={tipInvest_return} error={errors.invest_return} name="invest_return" control={control} required={true} />
                  <FormInputPercent2 label="Annual Appreciation" tip={tipProp_app} error={errors.prop_app} name="prop_app" required={true} min={-100} control={control} />
                  <FormInputCreditScore error={errors.inputcred} control={control} required={true} />
                  <FormInputRadioButtons
                    label="Married"
                    name="filing_status"
                    tip={tipFiling_status}
                    defaultValue="s"
                    required={true}
                    ids={['married', 'single']}
                    values={['m', 's']}
                    control={control}
                    errors={errors}
                  />

                  {show1stTimeBuyer && (
                    <FormInputRadioButtons
                      label="1st Time Buyer"
                      name="ftbuyer"
                      tip={tipFtbuyer}
                      defaultValue="n"
                      required={true}
                      ids={['first_time_yes', 'first_time_no']}
                      control={control}
                      errors={errors}
                    />
                  )}
                </fieldset>

                <Accordion id="optional-inputs">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header data-cy="btn-optional">Optional Inputs</Accordion.Header>
                    <Accordion.Body>
                      <fieldset>
                        <FormInputDollar label="Annual Property Taxes" tip={tipProp_t} error={errors.prop_t} name="prop_t" control={control} />
                        <FormInputPercent2 label="Seller Credits" tip={tipS_cred} error={errors.s_cred} name="s_cred" control={control} />
                        <FormInputPercent2 label="Down Payment" tip={tipInput_ltv} error={errors.input_ltv} name="input_ltv" control={control} />
                        <FormInputDollar label="Limit Loan Amount" tip={tipInput_ma} error={errors.input_ma} name="input_ma" control={control} />
                        <FormInputDollar label="Condo Fee" tip={tipCondo} error={errors.condo} name="condo" control={control} />
                        <FormInputFirstVaExempt loanTypes={watch('doType')} setValue={setValue} tip={tipVa_fee_ex} control={control} errors={errors} />
                        <FormInputFirstVaLoan loanTypes={watchDoType} setValue={setValue} tip={tipVa_first} control={control} errors={errors} />
                        <FormInputText label="Name" tip={tipCust_name} error={errors.cust_name} name="cust_name" control={control} />
                        <FormInputText label="Phone" tip={tipCust_name} error={errors.cust_phone} name="cust_phone" control={control} />
                        <FormInputEmail accountType={currentSettings.accountType} tip={tipEmail} error={errors.cust_email} control={control} />
                      </fieldset>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <p className="mt-3">
                  <ValidationModal label="Submit" errors={errors} onClick={handleSubmit(onSubmit)} />
                  <button type="reset" className="btn btn-outline-secondary me-2" onClick={clearForm}>
                    Clear
                  </button>
                </p>
              </form>
            </div>
            <div className="col-lg-9 position-relative ps-4">
              {!loading && recommendations && <Recommended recommendations={recommendations} />}
              <div className="form-results">
                <div className="w-100 d-flex align-items-center justify-content-end">
                  <Help sFile={hBUYRENT} />
                </div>
                {loading ? (
                  <FormLoader />
                ) : (loaded && dats) ? (
                  <>
                    <Tabs id="controlled-tab" activeKey='main' className="mb-3">
                      <Tab className="pt-3" eventKey="main" title="Results">
                        <div style={{ maxWidth: "720px" }}>
                          <BuyOrRentReportV2 dats={dats} loanType={ResponseLoanTypes[dats.params.pForm.doType[0]]} />

                        </div>
                      </Tab>
                    </Tabs>
                    <div className="d-flex mt-3">
                      <ButtonDownloadPdf data={dats} fileName="buy-or-rent" />
                      {dats?.meta.email && dats?.meta.email !== 'nil' && <ButtonSendPdf dats={dats} />}
                    </div>
                  </>
                ) : (
                  <div className="mt-2">
                    <h4 id="house-title" className="h4">
                      You're seconds away from unlocking your Buy or Rent data.
                    </h4>
                    <img id="house-image" src={HomeMainImage} alt="home" />
                  </div>
                )}
              </div>
              <div className="w-100 mt-3">
                <PoweredBy />
              </div>
            </div>
          </div>
        </div>
      </div>
    </FormWrapper>
  );
};

export default BuyOrRent;

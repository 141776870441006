/*
 * Copyright 2020-2021 AskChristee, LLC. All rights reserved.
 */

import React from 'react';

import {ResponseLoanTypes, TBuyOrRentResponseObjectV2} from '../../../../types/api';
import {ReportHeader, ReportNotesSection, ReportResults, ReportSection} from "../../../molecules";

import {Cash} from "./Cash";
import {LoanMess} from "./LoanMess";
import {ProjectEquity} from "./ProjectEquity";
import {PurchaseDetails} from "./PurchaseDetails";
import {MortgageDetails} from "./MortgageDetails";
import {PaymentComparison} from "./PaymentComparison";
import {TaxConsiderations} from "./TaxConsiderations";

export type BuyOrRentDats = {
  [key in ResponseLoanTypes]: TBuyOrRentResponseObjectV2;
};

export type BuyOrRentReport = {
  dats: BuyOrRentDats;
  loanType: ResponseLoanTypes;
};

const BuyOrRent = ({ dats, loanType }: BuyOrRentReport) => {
  return (
    <div>
      <ReportHeader>{dats[loanType]?.aCalc.brow_head}</ReportHeader>
      <ReportHeader>{dats[loanType]?.aCalc.brow_head_2}</ReportHeader>

      <ReportSection>
        <PaymentComparison {...dats[loanType]} />
        <ProjectEquity {...dats[loanType]} />
        <PurchaseDetails {...dats[loanType]} />
        <MortgageDetails {...dats[loanType]} />
        <Cash {...dats[loanType]} />
        <TaxConsiderations {...dats[loanType]} />
      </ReportSection>

      <ReportResults>{dats[loanType]?.aCalc.results$}</ReportResults>

      <ReportNotesSection>
        <LoanMess {...dats[loanType]} />
      </ReportNotesSection>
    </div>
  );
};

export default BuyOrRent;

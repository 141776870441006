import React from 'react';
import { ReportSectionHeader, ReportSectionItem, ReportSectionElement } from '../../../molecules';
import { THecmCalc } from '../../../../types/api';

type Props = {
  calc: THecmCalc;
};

export const ReportValues = ({ calc }: Props) => {
  return (
    <ReportSectionItem>
      <ReportSectionHeader>{calc.report_type}</ReportSectionHeader>
      <div className="mb-3" style={{ fontSize: 17 }}>
        {calc.fumess}
      </div>
      <ReportSectionElement title="Projected Sales Price" value={calc.disp_sp} />
      <ReportSectionElement title="Base Loan Amount" value={calc.disp_ma} />
      <ReportSectionElement title="Total Loan Amount" value={calc.disp_total_mtge} />
      <ReportSectionElement title="Down payment" value={calc.disp_d_payment} />
      <ReportSectionElement title="Estimated Closing Cost" value={calc.disp_totalcc} />
      <ReportSectionElement title="Required Cash" value={calc.disp_r_cash} />
      <ReportSectionElement title="Cash available" value={calc.disp_t_cash} />
      <ReportSectionElement title="Addition Cash Required" value={calc.disp_ex_cash} />
      <ReportSectionElement title="Estimated Annual Obligations" value={' '} />
      <ReportSectionElement title="Annual Property Taxes" value={calc.disp_pt} />
      <ReportSectionElement title="Annual Property Insurance" value={calc.disp_ins} />
    </ReportSectionItem>
  );
};

import React, { useCallback, useEffect, useState } from 'react';

import { Amplify } from 'aws-amplify';
import { ToastContainer } from "react-toastify";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { ChristeeConfigCtx} from "../../context";

import { SettingsParamsType, ProviderProps } from './types';

import "react-toastify/dist/ReactToastify.css";

const initialSettingsState: SettingsParamsType = {
    companyId: "",
    email: "nil",
    name: "nil",
    phone: "nil",
    availableStates: [],
    isPartner: false,
    apiUrls: {
        sendPdf: "",
        downloadPdf: "",
        agentZip: "",
        pricingRequest: "",
        interestRates: ""
    },
    accountType: "",
    isApproved: false,
    initialCompletion: 0
}

export const UiSettingsProviderContext = React.createContext({
    currentSettings: initialSettingsState,
    // @ts-ignore
    updateCurrentSettings(newSetting: ParamsType) {}
});

export const UiSettingsProvider = ({
   recaptchaKey,
   isPartner,
   stage,
   availableStates,
   companyId,
   apiUrls,
   amplifyConfig,
   children
}: ProviderProps) => {
    const [currentSettings, setCurrentSettings] = useState(initialSettingsState);

    const updateCurrentSettings = useCallback((newSettings: SettingsParamsType) => {
      setCurrentSettings(prevState => ({
        ...prevState,
        ...newSettings
      }));
    }, []);

    useEffect(() => {
        setCurrentSettings(prevState => ({
            ...prevState,
            companyId,
            isPartner,
            apiUrls,
            availableStates
        }));

        // setup cognito auth for getting access token
        if (amplifyConfig) {
            Amplify.configure(amplifyConfig);
        }
    }, []);

    return (
      <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey}>
          <ToastContainer />
          <ChristeeConfigCtx.Provider value={{
              stage,
              key: null
          }}>
              <UiSettingsProviderContext.Provider value={{
                  currentSettings,
                  updateCurrentSettings
              }}>
                  {children}
              </UiSettingsProviderContext.Provider>
          </ChristeeConfigCtx.Provider>
      </GoogleReCaptchaProvider>
    );
}

export default UiSettingsProvider;

import React, { ReactChildren, ReactChild } from "react";

import { Button, Spinner } from "react-bootstrap";

type Props = {
  loadingIndicator?: string,
  spinnerVariant?: string,
  loading: boolean,
  disabled?: boolean,
  children: ReactChild | ReactChildren,
  [x:string]: any
}

export const ButtonWithSpinner = ({
  loadingIndicator,
  spinnerVariant,
  loading,
  children,
  disabled,
  ...rest
}: Props) => {
  return (
    <Button {...rest} disabled={disabled || loading}>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner size="sm" animation="border" variant={spinnerVariant || "primary"} />
          <span
            style={{
              marginLeft: "7px",
              textTransform: loadingIndicator ? "capitalize" : "inherit",
            }}
          >
            {loadingIndicator || children}
          </span>
        </div>
      ) : (
        children
      )}
    </Button>
  );
};

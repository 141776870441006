import React from 'react';

import ArrowIcon from '../../../images/icons/arrow-down-icon.svg';

export const ExpandableHeader = ({ isOpen, toggle }: { isOpen: boolean, toggle: () => void }) => (
  <div className='recommendations__header' onClick={toggle}>
    <div>
      <h5 className='recommendations__title'>Recommended</h5>
    </div>
    <div className='recommendations__control'>
      <div className='recommendations__control-status'>{isOpen ? 'HIDE' : 'SHOW'}</div>
      <div className='recommendations__control-icon'>
        <img
          src={ArrowIcon}
          width={24}
          alt='arrow'
          style={{
            transform: `rotate(${isOpen ? 180 : 0}deg)`,
            transition: 'all 0.25s',
          }}
        />
      </div>
    </div>
  </div>
);

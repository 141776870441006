import React from 'react';

import { ReportSectionItem, ReportSectionElement, ReportSectionHeader } from '../../../molecules';
import { TSellerNetCalc } from '../../../../types/api';

type Props = {
  calc: TSellerNetCalc;
};

export const ReportValues = ({ calc }: Props) => {
  return (
    <ReportSectionItem fullWidth>
      <ReportSectionHeader>Results</ReportSectionHeader>
      <ReportSectionElement title="Sales Price" value={calc.disp_sp} />
      <ReportSectionElement title="Mortgage Payoff" value={calc.disp_total_payoff} />
      <ReportSectionElement title="Transfer Tax" value={calc.disp_transfer} />
      <ReportSectionElement title="Documentary Stamps" value={calc.disp_doc_stamps} />
      <ReportSectionElement title="Survey Charge" value={calc.disp_s_survey} />
      <ReportSectionElement title="Seller Closing Fee" value={calc.disp_s_clo_fee} />
      <ReportSectionElement title="Seller Doc Prep Fee" value={calc.disp_s_docprep} />
      <ReportSectionElement title="Title Abstract Fee" value={calc.disp_s_abs_fee} />
      <ReportSectionElement title="Closing Attorney Fee" value={calc.disp_s_attorney} />
      <ReportSectionElement title="Title Agent Fee" value={calc.disp_s_title_agent} />
      <ReportSectionElement title="Title Insurance" value={calc.disp_s_tit_ins} />
      <ReportSectionElement title="Mortgage Release" value={calc.disp_mtge_release} />
      <ReportSectionElement title="Real Estate Commission" value={calc.disp_broker_fee} />
      <ReportSectionElement title="Buyer Closing Cost Credit" value={calc.disp_dol_cred} />
      <ReportSectionElement title="Buyer Loan Discount Fees" value={calc.disp_loan_org} />
      <ReportSectionElement title="Closing Charges to Seller" value={calc.disp_seller_charges} />
      <ReportSectionElement title={calc.tax_adj$} value={calc.disp_dol_tax_adj} />
      <ReportSectionElement title="Estimated Seller Proceeds" value={calc.disp_seller_net} />
    </ReportSectionItem>
  );
};

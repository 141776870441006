import React from "react";

import "./styles.css";

type Props = {
  children: React.ReactNode;
  disableBottomLine?: boolean
}

export const ReportSectionHeader = ({ disableBottomLine, children }: Props) => (
  <h3 className={`report-values-section__header ${disableBottomLine ? "" : "bottom-line"}`}>
    {children}
  </h3>
)

import React from "react";

import "./styles.css";

type Props = {
  children: React.ReactNode
}

export const ReportHeader = ({ children }: Props) => (
  <div className="report__header">{children}</div>
)

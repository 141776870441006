import React from 'react';

import { ContactCard, IconPopoverOverlay } from '../../molecules';

import { RecommendedUser } from '../../../types/recommended';

export const RecommendationSection = ({
  items,
  title,
  isShortSize,
  showVerticalLine,
  popoverContent,
  linkUrl
}: {
  title: string,
  popoverContent: string,
  items: RecommendedUser[],
  linkUrl: string,
  isShortSize?: boolean,
  showVerticalLine?: boolean,
}) => {
  const sectionClasses = [
    'recommendations__section',
    isShortSize ? 'recommendations__section--short' : '',
    showVerticalLine ? 'recommendations__section-vertical-line' : '',
  ].join(' ').trim();

  return (
    <div className={sectionClasses}>
      <div className='d-flex'>
        <p className='recommendations__subtitle'>{title}</p>
        <IconPopoverOverlay title={title}>{popoverContent}</IconPopoverOverlay>
      </div>
      <div className='recommendations__cards-wrapper'>
        {items.map((item) => (
          <ContactCard
            key={item.id}
            linkUrl={`${linkUrl}/${item.id}`}
            name={`${item.first_name} ${item.last_name}`}
            phone={item.primary_phone}
            photo={item.realtor_photo}
          />
        ))}
      </div>
    </div>
  );
}

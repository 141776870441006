import React from 'react';

import {ReportSectionElement, ReportSectionHeader, ReportSectionItem} from "../../../molecules";

import { BuyerQualChoiceReport } from './index';


interface Props extends BuyerQualChoiceReport {
  calcType: 'aCalc' | 'bCalc';
}

export const CashRequirements = ({ dats, loanType, calcType }: Props) => {
  return (
    <ReportSectionItem>
      <ReportSectionHeader>Cash Requirements</ReportSectionHeader>
      <ReportSectionElement title="Down Payment" value={dats[loanType][calcType]?.disp_d_payment} />
      <ReportSectionElement title="Closing Cost" value={dats[loanType][calcType]?.disp_totalcc} />
      <ReportSectionElement title="Seller Credit" value={dats[loanType][calcType]?.disp_dol_cred} />
      <ReportSectionElement title="Lender Credit" value={dats[loanType][calcType]?.disp_dol_lend} />
      <ReportSectionElement title="Required Cash" value={dats[loanType][calcType]?.disp_r_cash} />
      <ReportSectionElement title="Cash Available" value={dats[loanType][calcType]?.disp_t_cash} />
      <ReportSectionElement title="Addition Cash Req." value={dats[loanType][calcType]?.disp_ex_cash} />
    </ReportSectionItem>
  );
};

import React, { useEffect, useState } from 'react';

import FormInputRadioButtons from '../FormInputRadioButtons';
import { tipVa_fee_ex } from '../../atoms';

type FormInputUseRatiosProps = {
  loanTypes?: [];
  setValue: any;
  control: any;
  errors: any;
  tip?: string;
  disabled?: boolean;
};

const FormInputFirstVaExempt = ({ loanTypes, setValue, ...rest }: FormInputUseRatiosProps) => {
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    const newIsDisabled = !loanTypes?.find((type: { value: string; text: string }) => type.value === 'VA');
    setValue('va_fee_ex', 'n');
    setIsDisabled(newIsDisabled);
  }, [loanTypes]);

  return <FormInputRadioButtons name="va_fee_ex" label="VA Exempt" disabled={isDisabled} tip={tipVa_fee_ex} defaultValue="n" ids={['va_fee_ex_yes', 'va_fee_ex_no']} {...rest} />;
};

export default FormInputFirstVaExempt;

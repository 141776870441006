import React from 'react';
import { TConsumerDebtResponseObject } from '../../../../types/api';
import { DetailText, DetailTextBold } from '../../../atoms';

const Consumerdebt = (consumerdebt: TConsumerDebtResponseObject | undefined) => {
  return (
    <table className="debt">
      <tbody>
        <tr className="active-row">
          <td>{consumerdebt!.browser && <DetailText v={consumerdebt!.browser.brow_head} />}</td>
        </tr>
        <tr>
          <td>&nbsp;</td>
        </tr>
        <tr>{consumerdebt!.browser && <DetailTextBold v={consumerdebt!.browser.header1} />}</tr>
        <tr>{consumerdebt!.browser && <DetailText v={consumerdebt!.browser.pdfmess1} />}</tr>
        <tr>{consumerdebt!.browser && <DetailText v={consumerdebt!.browser.pdfmess1a} />}</tr>
        <tr>
          <td>&nbsp;</td>
        </tr>
        <tr>{consumerdebt!.browser && <DetailTextBold v={consumerdebt!.browser.header2} />}</tr>
        <tr>{consumerdebt!.browser && <DetailText v={consumerdebt!.browser.pdfmess2} />}</tr>
        <tr>{consumerdebt!.browser && <DetailText v={consumerdebt!.browser.pdfmess3} />}</tr>
        <tr>{consumerdebt!.browser && <DetailText v={consumerdebt!.browser.pdfmess3a} />}</tr>
        <tr>
          <td>&nbsp;</td>
        </tr>
        <tr>{consumerdebt!.browser && <DetailTextBold v={consumerdebt!.browser.header3} />}</tr>
        <tr>{consumerdebt!.browser && <DetailText v={consumerdebt!.browser.pdfmess4} />}</tr>
        <tr>{consumerdebt!.browser && <DetailText v={consumerdebt!.browser.pdfmess5} />}</tr>
        <tr>
          <td>&nbsp;</td>
        </tr>
        <tr>{consumerdebt!.browser && <DetailTextBold v={consumerdebt!.browser.loan_mess} />}</tr>
        <tr>{consumerdebt!.browser && <DetailText v={consumerdebt!.browser.m_note} />}</tr>
        <tr>{consumerdebt!.browser && <DetailText v={consumerdebt!.browser.m_note1} />}</tr>
        <tr>{consumerdebt!.browser && <DetailText v={consumerdebt!.browser.m_note2} />}</tr>
        <tr>{consumerdebt!.browser && <DetailText v={consumerdebt!.browser.m_note3} />}</tr>
        <tr>{consumerdebt!.browser && <DetailText v={consumerdebt!.browser.m_note4} />}</tr>
        <tr>{consumerdebt!.browser && <DetailText v={consumerdebt!.browser.m_note5} />}</tr>
        <tr>{consumerdebt!.browser && <DetailText v={consumerdebt!.browser.m_note6} />}</tr>
        <tr>{consumerdebt!.browser && <DetailText v={consumerdebt!.browser.m_note7} />}</tr>
        <tr>
          <td>&nbsp;</td>
        </tr>
        <tr>{consumerdebt!.browser && <DetailText v={consumerdebt!.browser.pdfmess6} />}</tr>
      </tbody>
    </table>
  );
};

export default Consumerdebt;

import React from "react";

import "./styles.css";

type Props = {
  children: React.ReactNode;
}

const FormWrapper = ({ children }: Props) => {
  return (
    <div className="form-wrapper">
      {children}
    </div>
  );
}

export default FormWrapper;

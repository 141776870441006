/*
 * Copyright 2020-2021 AskChristee, LLC. All rights reserved.
 */

import React, { useContext, useEffect, useRef, useState } from 'react';
import {Accordion, Tab, Tabs} from 'react-bootstrap';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {fetchVacationV2, hVACATION} from '../../../../api';
import {TVacationResponseV2} from '../../../../types/api';
import {
  tipClose_date,
  tipCondo,
  tipCust_name,
  tipEmail,
  tipExist_piti,
  tipEx_int_rate,
  tipEx_mtge,
  tipEx_ptax,
  tipFiling_status,
  tipInputcred,
  tipInput_ins,
  tipInput_ltv,
  tipInput_ma,
  tipInput_pmi_dollar,
  tipInput_uw_fee,
  tipLend_cred,
  tipLim_piti,
  tipMtge_date,
  tipM_debts,
  tipOrg_fee,
  tipProp_t,
  tipQ_income,
  tipSp,
  tipS_cred,
  tipT_cash,
  tipUse_ratios,
  tipZip,
  UiSettingsProviderContext, PoweredBy,
} from '../../../atoms';
import { ChristeeConfigCtx } from '../../../context';
import {
  MinimumCashSwitch,
  FormInputChangeRate,
  FormInputCreditScore,
  FormInputDatePicker2,
  FormInputDollar,
  FormInputEmail,
  FormInputPercent2,
  FormInputRadioButtons,
  FormInputStateAreaDropdowns,
  FormInputStateAreaDropdownsHandle,
  FormInputTerm,
  FormInputTermHandle,
  FormInputText,
  FormInputUseRatios,
  FormInputZip, FormWrapper,
  ValidationModal, FormLoader,
} from '../../../molecules';
import FormInputPMICalculation from '../../../molecules/FormInputPMICalculation';
import {
  VacationReportV2,
} from '../../Reports';
import ButtonDownloadPdf from "../../../molecules/ButtonDownloadPdf";
import {ButtonSendPdf} from "../../../molecules/ButtonSendPdf";
import {Help} from "../../../molecules/QuickStart";
import { useRecommendations } from '../../../../hooks/useRecommendations';
import { Recommended } from '../../Recommendations';
import HomeMainImage from "../../../../images/main.png"

const Vacation = () => {
  const defaultValues = {
    account_type: '',
    condo: '',
    cust_email: '',
    cust_name: '',
    cust_phone: '',
    ex_int_rate: '',
    ex_mtge: '',
    ex_ptax: '',
    exist_piti: '',
    filing_status: 's',
    input_ltv: '',
    input_ma: '',
    input_sp: '',
    input_uw_fee: '',
    inputcred: '',
    int_rate: '',
    lend_cred: '',
    lim_piti: '',
    m_debts: '',
    main_amt: '',
    mtge_date: '',
    org_fee: '',
    prop_app: '',
    prop_mgt: '',
    prop_t: '',
    q_income: '',
    rent_income: '',
    rent_increase: '',
    report_years: '',
    s_cred: '',
    sales_cost: '',
    state_select: '',
    t_cash: '',
    vac_allow: '',
  };
  const {
    control,
    handleSubmit,
    watch,
    reset,
    trigger,
    setValue,
    formState: { errors },
  } = useForm<any>({ mode: 'onTouched', reValidateMode: 'onBlur', defaultValues });
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { currentSettings } = useContext(UiSettingsProviderContext);
  const { recommendations, requestRecommendations } = useRecommendations();

  const scrollTop = () => {
    // @ts-ignore
    window.scrollTo({ top: 0, behavior: 'instant' });
  };

  useEffect(() => {
    scrollTop();
  }, []);

  const ctx = useContext(ChristeeConfigCtx);
  const [loaded, setLoaded] = useState(false);
  const [dats, setDats] = useState<TVacationResponseV2>();
  const [loading, setLoading] = useState(false);
  const termRef = useRef<FormInputTermHandle>(null);
  const stateAreaRef = useRef<FormInputStateAreaDropdownsHandle>(null);
  const [availableSpendingCash, setAvailableSpendingCash] = useState(false);
  const [calcType, setCalcType] = useState<'aCalc1' | 'aCalc2' | 'aCalc3' | 'aCalc4'>('aCalc1');
  const [activeCalcGroup, setActiveCalcGroup] = useState<["aCalc1", "aCalc2"] | ["aCalc3", "aCalc4"]>(["aCalc1", "aCalc2"]);

  const onSubmit = async (data: any) => {
    await trigger();

    scrollTop();
    setLoading(true);
    await new Promise((r) => setTimeout(r, 800));

    const doTypeArr = data.doType && data.doType.length > 0 ? data.doType.map((m: any) => m.value) : [];
    const filteredData = {
      ...data,
      state: data.state[0].code,
      county_select: data.county_select[0].code,
      doType: doTypeArr,
      term: Array.isArray(data.term) ? data.term[0].value : data.term ? data.term : '30',
    };

    try {
      let captchaToken;
      if (executeRecaptcha) {
        captchaToken = await executeRecaptcha();
      }
      const modulePromise = fetchVacationV2(
        {
          ...filteredData,
          company_name: currentSettings.companyId,
          logged_in_email: currentSettings.email,
          logged_in_name: currentSettings.name,
          logged_in_phone: currentSettings.phone,
          account_type: currentSettings.accountType,
        },
        ctx?.stage,
        ctx?.key,
        captchaToken
      );

      const recommendationPromise = requestRecommendations({
        zipCode: data.zip,
        state: data.state?.[0].text
      });

      const [res] = await Promise.all([modulePromise, recommendationPromise]);

      if (res.meta?.displayMessage) toast.info(res.meta.displayMessage);

      setDats(res);
      setLoaded(true);
      setLoading(false);

      if (res.results.aCalc3) {
        setActiveCalcGroup(["aCalc3", "aCalc4"]);
      } else {
        setActiveCalcGroup(["aCalc1", "aCalc2"])
      }
      scrollTop();
    } catch (error) {
      setLoading(false);
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error('Something went wrong!');
      }
    }
  };

  const clearForm = () => {
    stateAreaRef.current?.clear();
    termRef.current?.clear();
    reset(defaultValues);
    setDats({} as TVacationResponseV2);
    setLoaded(false);
    setLoading(false);
    scrollTop();
  };

  const switchCalcType = () => {
    setCalcType(calcType === activeCalcGroup[0]
      ? activeCalcGroup[1]
      : activeCalcGroup[0]
    );
    window.scrollTo({ top: 0, behavior: 'auto' });
  }

  useEffect(() => {
    if (dats?.results.aCalc2 || dats?.results.aCalc4) {
      setCalcType(activeCalcGroup[1]);
      setAvailableSpendingCash(true)
    } else {
      setCalcType(activeCalcGroup[0]);
      setAvailableSpendingCash(false);
    }
  }, [dats, activeCalcGroup]);

  return (
    <FormWrapper>
      <div className="buyers-choice">
        <div className="container-fluid">
          <div className="row g-2">
            <div className="col-lg-3">
              <h3 className="h2">Vacation</h3>
              <form className="christy" autoComplete="off">
                <fieldset>
                  <legend className="sr-only">Required Fields:</legend>
                  <FormInputStateAreaDropdowns setValue={setValue} stateWatch={watch('state')} areaWatch={watch('county_select')} control={control} errors={errors} ref={stateAreaRef} />
                  <FormInputZip label="Zip Code" tip={tipZip} error={errors.zip} name="zip" control={control} required={true} />
                  <FormInputChangeRate watchCalcForm={watch()} resetCalcForm={reset} selectedRates={['second_home_conv']} />
                  <FormInputTerm watch={watch('term')} ref={termRef} control={control} errors={errors} required={true} />
                  <FormInputDollar label="Annual Gross Income" tip={tipQ_income} error={errors.q_income} name="q_income" control={control} required={true} />
                  <FormInputDollar label="Monthly Debts Payment" tip={tipM_debts} error={errors.m_debts} name="m_debts" control={control} required={true} />
                  <FormInputDollar label="Primary Loan Amount" tip={tipEx_mtge} error={errors.ex_mtge} name="ex_mtge" control={control} required={true} />
                  <FormInputDollar label="Primary Loan Payment" tip={tipExist_piti} error={errors.exist_piti} name="exist_piti" required={true} control={control} />
                  <FormInputPercent2 label="Primary Loan Int Rate" tip={tipEx_int_rate} error={errors.ex_int_rate} name="ex_int_rate" required={true} control={control} />
                  <FormInputDatePicker2 label="Primary Loan Date" tip={tipMtge_date} error={errors.mtge_date} name="mtge_date" required={true} control={control} />
                  <FormInputDollar label="Primary Property Taxes" tip={tipEx_ptax} error={errors.ex_ptax} name="ex_ptax" control={control} required={true} />
                  <FormInputCreditScore error={errors.inputcred} control={control} required={true} tip={tipInputcred} />
                  <FormInputRadioButtons
                    label="Married"
                    name="filing_status"
                    tip={tipFiling_status}
                    defaultValue="s"
                    required={true}
                    ids={['married', 'single']}
                    values={['m', 's']}
                    control={control}
                    errors={errors}
                  />
                </fieldset>
                <Accordion id="optional-inputs">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header data-cy="btn-optional">Optional Inputs</Accordion.Header>
                    <Accordion.Body>
                      <fieldset>
                        <FormInputDollar label="Sales Price" tip={tipSp} error={errors.input_sp} name="input_sp" control={control} />
                        <FormInputDollar label="Enter Cash" tip={tipT_cash} error={errors.t_cash} name="t_cash" control={control} />
                        <FormInputDollar label="Annual Property Taxes" tip={tipProp_t} error={errors.prop_t} name="prop_t" control={control} />
                        <FormInputDollar label="Annual Property Insurance" tip={tipInput_ins} error={errors.input_ins} name="input_ins" control={control} />
                        <FormInputDatePicker2 label="Close Date" tip={tipClose_date} error={errors.close_date} name="close_date" control={control} />
                        <FormInputPercent2 label="Seller Credit" tip={tipS_cred} error={errors.s_cred} name="s_cred" control={control} />
                        <FormInputPercent2 label="Lender Credit" tip={tipLend_cred} error={errors.lend_cred} name="lend_cred" control={control} />
                        <FormInputDollar label="Lender Fees" tip={tipInput_uw_fee} error={errors.input_uw_fee} name="input_uw_fee" control={control} />
                        <FormInputPercent2 label="Loan Origination" tip={tipOrg_fee} error={errors.org_fee} name="org_fee" control={control} max={2} />
                        <FormInputDollar label="Limit Payment" tip={tipLim_piti} error={errors.lim_piti} name="lim_piti" control={control} />
                        <FormInputPercent2 label="Down Payment" tip={tipInput_ltv} error={errors.input_ltv} name="input_ltv" control={control} />
                        <FormInputDollar label="Limit Loan Amt" tip={tipInput_ma} error={errors.input_ma} name="input_ma" control={control} />
                        <FormInputDollar label="Condo Fee" tip={tipCondo} error={errors.condo} name="condo" control={control} />
                        <FormInputPMICalculation accountType={currentSettings.accountType} tip={tipInput_pmi_dollar} control={control} errors={errors} />
                        <FormInputUseRatios accountType={currentSettings.accountType} tip={tipUse_ratios} control={control} errors={errors} />
                        <FormInputText label="Name" tip={tipCust_name} error={errors.cust_name} name="cust_name" control={control} />
                        <FormInputText label="Phone" tip={tipCust_name} error={errors.cust_phone} name="cust_phone" control={control} />
                        <FormInputEmail accountType={currentSettings.accountType} tip={tipEmail} error={errors.cust_email} control={control} />
                      </fieldset>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <p className="mt-3">
                  <ValidationModal label="Submit" errors={errors} onClick={handleSubmit(onSubmit)} />
                  <button type="reset" className="btn btn-outline-secondary me-2" onClick={clearForm}>
                    Clear
                  </button>
                </p>
              </form>
            </div>
            <div className="col-lg-9 position-relative ps-4">
              {!loading && recommendations && <Recommended recommendations={recommendations} />}
              <div className="form-results">
                <div className="w-100 d-flex align-items-center justify-content-end">
                  {(!loading && loaded && availableSpendingCash)
                    && <div className="me-5">
                      <MinimumCashSwitch onChange={switchCalcType} checked={calcType === activeCalcGroup[0]} />
                    </div>}
                  <Help sFile={hVACATION} />
                </div>
                {loading ? (
                  <FormLoader />
                ) : (loaded && dats) ? (
                  <>
                    <Tabs id="controlled-tab" activeKey='main' className="mb-3">
                      <Tab className="pt-3" eventKey="main" title="Results">
                        <div className="mb-3" style={{ maxWidth: "720px" }}>
                          <VacationReportV2 dats={dats.results} calcType={calcType} />
                        </div>
                      </Tab>
                    </Tabs>

                    <div>
                      <div className="mt-3 d-flex">
                        <ButtonDownloadPdf data={dats} fileName="vacation" />
                        {dats?.meta.email && dats?.meta.email !== 'nil' && <ButtonSendPdf dats={dats} />}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <h4 id="house-title" className="h4">
                      You're seconds away from unlocking your Vacation data.
                    </h4>
                    <img id="house-image" src={HomeMainImage} alt="home" />
                  </>
                )}
              </div>
              {/* only guests can see this */}

              <div className="w-100 mt-3">
                <PoweredBy />
              </div>
            </div>
          </div>
        </div>
      </div>
    </FormWrapper>
  );
};

export default Vacation;

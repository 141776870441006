import { useContext } from 'react';

import { UiSettingsProviderContext } from '../components/atoms';

import { useRequest } from './useRequest';

import { Recommendations } from '../types/recommended';

export const useRecommendations = () => {
  const { currentSettings } = useContext(UiSettingsProviderContext);

  const { data, requestData } = useRequest<Recommendations>();

  const requestRecommendations = async ({ zipCode, state }: { zipCode: string, state?: string }) => {
    const recommendationUrl = currentSettings.apiUrls.recommendations;

    if (recommendationUrl && currentSettings.accountType === "homebuyer") {
      return requestData({
        url: recommendationUrl,
        queryParams: {
          zip_code: zipCode,
          ...(state && { state }),
        }
      });
    }
  }

  return { recommendations: data, requestRecommendations };
}

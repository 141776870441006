import React from "react";

import {
  ReportSectionHeader,
  ReportSectionItemMess,
  ReportSectionMessElement
} from "../../../molecules";

import { THecmCalc } from "../../../../types/api";

type Props = {
  calc: THecmCalc,
}

export const Notes = ({ calc }: Props) => {
  return (
    <ReportSectionItemMess>
      <ReportSectionHeader disableBottomLine>{calc.loan_mess}</ReportSectionHeader>
      <ReportSectionMessElement>{calc.m_note}</ReportSectionMessElement>
      <ReportSectionMessElement>{calc.m_note1}</ReportSectionMessElement>
      <ReportSectionMessElement>{calc.m_note2}</ReportSectionMessElement>
      <ReportSectionMessElement>{calc.m_note3}</ReportSectionMessElement>
      <ReportSectionMessElement>{calc.m_note4}</ReportSectionMessElement>
      <ReportSectionMessElement>{calc.m_note5}</ReportSectionMessElement>
    </ReportSectionItemMess>
  );
}

import React from 'react';
import FormInputText from '../FormInputText';

type FormInputUseRatiosProps = {
  name?: string;
  accountType?: string;
  tip: string;
  error: any;
  control: any;
  required?: boolean;
};

const FormInputEmail = ({ name = 'cust_email', accountType, ...rest }: FormInputUseRatiosProps) => {
  return <FormInputText disabled={accountType === 'never'} label="Email Address" data-test={name} name={name} {...rest} />;
};

export default FormInputEmail;

export const tipState = `Enter State`;
export const tipCounty_select = `Enter City or County`;
export const tipDoType = `Select up to 4 Loan Types`;
export const tipDoTypeSingle = `Select 1 Loan Type`;
export const tipInt_rate = `Enter Mortgage Rate`;
export const tipInt_rate2 = `Enter future interest rate for comparison`;
export const tipTerm = `Enter Mortgage term`;
export const tipQ_income = `Enter combined annual incomes`;
export const tipM_debts = `Enter monthly debts on credit report with more than 12 months remaining`;
export const tipInputcred = `Enter lowest middle credit score for borrowers`;
export const tipFiling_status = `Marital Status`;
export const tipFt_buyer = `1st Time Home Buyer`;
export const tipSp = `Enter Sales Price for Target Property`;
export const tipM_rent = `Enter Monthly Rent`;
export const tipInc_rent = `Enter percent for future rent increase`;
export const tipRent_income = `Enter monthly rent income`;
export const tipLand_value = `Enter Dollar Value of Land only`;
export const tipVac_allow = `Enter annual percent vacancy`;
export const tipMain_amt = `Enter dollar amount annual maintenance`;
export const tipProp_mgt = `Enter annual management fee as a percent`;
export const tipSales_cost = `Real Estate Commission`;
export const tipReport_years = `Enter a whole number between 1 and 5`;
export const tipInvest_return = `Enter cash investment return such as savings account`;
export const tipProp_app = `Enter appreciation or depreciation for report`;
export const tipAge = `Age of Youngest Borrower`;
export const tipEx_mtge = `Primary Home Loan Amount`;
export const tipExist_piti = `Primary Home Loan Payment`;
export const tipEx_int_rate = `Primary Home Loan Rate`;
export const tipMtge_date = `Date of Primary Home Loan`;
export const tipEx_ptax = `Primary Home Property Taxes`;
export const tipT_cash = `Enter total cash available to purchase home`;
export const tipProp_t = `Enter Property Taxes on Target Property`;
export const tipClose_date = `Enter Close Date for Closing Cost Calculations`;
export const tipS_cred = `Enter percent of Sales Price for Seller Credit toward Closing Cost`;
export const tipInput_dol_cred = `Enter Dollar Amount of Seller Credit Toward Closing Cost`;
export const tipLend_cred = `Enter Lender Credit as a Percent of loan amount toward closing cost`;
export const tipInput_uw_fee = `Enter Dollar Amount of fees such as Lender processing or underwriting fees`;
export const tipOrg_fee = `Enter up to 2% for loan origination fee`;
export const tipLim_piti = `Enter desired total monthly payment`;
export const tipInput_ltv = `Enter percent for down payment`;
export const tipInput_ma = `Enter maximum loan amount`;
export const tipCondo = `Enter monthly condo or mandatory HOA fees`;
export const tipVa_fee_ex = `Select 'Yes' if you are exempt from VA Funding Fee`;
export const tipChange_rate = `Update the current rates with your own`;
export const tipVa_first = `Enter yes if this is your first VA loan`;
export const tipInput_pmi_dollar = `Select 'No' if monthly PMI is NOT to be included in mortgage payment`;
export const tipUse_ratios = `Select 'No' to control target payment and max LTV amounts`;
export const tipInput_rpt_age = `Enter age for HECM loan balance calculation`;
export const tipInput_orgfee = `Enter 'y' to include permissible loan origination fee for HECM loan`;
export const tipAll_cash = `Select 'Yes' to base results on all cash transaction.`;
export const tipPri_fin = `Selection of 'Yes' allows up to 100% LTV and no PMI`;
export const tipCost_seg = `Enter Dollar Amount for Cost Segregation Depreciation Method`;
export const tipCost_seg_period = `Enter years for cost segregation depreciation`;
export const tipMa_bal = `Enter principal balance on existing loan. Leave blank if there is not a loan.`;
export const tipSellerNetInt_rate = `Enter interest rate on loan to be paid off`;
export const tipSellerNetEx_mtge = `If you do not know loan balance then enter original amount. Christee will estimate the balance`;
export const tipSellerNetTerm = `Existing loan term`;
export const tipBuyer_ma = `Buyer's loan amount is used to estimate fees such as loan origination fee`;
/** END GOOGLE SHEET TIPS */
export const tipInput_ins = `Enter the annual property insurance premium.  Otherwise, Christee will calculate.`;
export const tipFtbuyer = `1st Time Home Buyer`;
export const tipCust_name = `Enter the name you wish to have displayed in the PDF report.`;
export const tipCust_addr = `Enter the Street Address for the property in order for it to appear in the PDF Report`;
export const tipEmail = `The Christee PDF report contains additional information and analysis. Enter a valid email to receive the PDF file.`;
/** buyer choice optional */
export const tipBcLim_piti = `You may enter a lower payment without any further inputs. Christee will automatically adjust all reports not to exceed the payment limit. You may also increase the payment; however, you must turn ratios ‘off’ – see Ratios Input above. This is Total Payment referred to as PITI`;
export const tipLender_Fees = `Enter total dollar amount for Lender fees such as Underwriting or underwriting fee. Not all Lenders have the same fees.`;
export const tipRob_doType = `Select 1 Loan Type`;
export const tipRob_sp = `Enter the purchase price of a property to be used as comparison to renting.`;
export const tipBwReport_years = `This is the period you wish to use before purchasing a home. You may enter whole numbers (without decimals) from 1 to 5 years.`;
export const tipHecmInt_rate = `Enter the interest rate currently available for reverse mortgages. This is the rate that will be used for HECM calculations also known as the 'expected rate.' If you are uncertain about current fix rate for a HECM loan add ½ percent to the 30-year conventional rate. You can use the same approach for an adjustable rate; however, you will need to add 2% (ARM margin) to the rate.`;
export const tipHecmT_cash = `Enter the amount of total cash the borrower has available to purchase a home utilizing a HECM mortgage.`;
export const tipHecmSp = `If you wish report to be based upon a specific property enter sales price. All calculations will be based upon this sales price.`;
export const tipHecmInput_ma = `Enter the maximum HECM loan the borrower would want. If the amount entered is less than maximum mortgage amount available then Christee will use the maximum loan amount as entered.`;
export const tipAmortInt_rate = `Enter mortgage rate for loan amortization`;
export const tipAmortInput_ma = `Enter the original loan amount`;
export const tipEx_prin = `Enter extra principal payment to be made per month.`;
export const tipShorterTerm = `Enter the term (years) you wish to pay off the loan. If you wish to pay off a 30-year mortgage in 20-years then enter 20 into this field.`;
export const tipUtil = `If blank enter the finished square footage of the property – under "Optional Inputs".`;
export const tipChild = `Enter Number of Dependents`;
export const tipInput_util = `Amount for monthly energy cost. If unsure enter square footage.`;
export const tipFood = `If blank then food cost will be estimated`;
export const tipOtherd = `Enter description of monthly obligation not listed`;
export const tipOtheramt = `Enter Amount of Monthly Obligation not listed`;
export const tipTfree_income = `Annual income not subject to Federal Payroll Taxes`;
export const tipRetire = `Enter Amount of Monthly contribution`;
export const tipC_care = `Monthly Amount you pay for child care`;
export const tipC_support = `Monthly Amount you pay to ex for child support`;
export const tipAlimony = `Monthly amount you pay support to a prior spouse.`;
export const tipColl_debt = `Monthly payment for college loan or school tuition`;
export const tipPets = `Monthly amount for pet care`;
export const tipSq_foot = `The square footage of home under consideration. This will be used to estimate monthly utility expense`;
export const tipInvestSp = `Enter the price of home for report.`;
export const tipInvestQ_income = `Enter combined annual incomes`;
export const tipRent_increase = `Enter percentage for increase in rental income annually`;
export const tipLorem = `NYI`;
export const tipSellerNetSp = `Enter the anticipated sales price for property.`;
export const tipSellerNetClose_date = `You may enter a date for closing. Mortgage payoff calculations and other adjustments will be based upon this date. Otherwise, calculations will be based upon current date plus one month.`;
export const tipTaxes_paid = `Select 'No' if the latest property tax bill is unpaid.`;
export const tipComm = `Enter total commission rate for selling and buyer's agent.  Note. Example enter 5.75 for a 5.75% total commission. `;
export const tipSellerNetProp_t = `Enter current annual property taxes. Otherwise, Christee will estimate the property taxes based upon tax rates for the area selected.`;
export const tipSellerNetS_cred = `Enter percentage of the sale price you are paying toward Buyers closing cost.`;
export const tipSellerNetInput_dol_cred = `Enter the dollar amount of closing seller is paying on behalf of buyer. If you enter a dollar amount and a percent amount, Christee will use dollar amount entered.`;
export const tipSellerNetOrg_fee = `Enter the percent seller is paying for buyer discount fees.`;
export const tipSellerNetDoType = `Select buyer’s loan type. Note. Loan type is used to calculate buyer loan amount (if loan amount is not entered) and establish maximum amount of closing credit is allowable for the buyer.`;
export const tipSellerNetMa_bal = `Enter Seller existing mortgage payoff. Note. Leave blank if there isn’t a loan to be paid off.`;
export const tipBuyDown = `Money is escrowed to reduce monthly payment. 2% 1st year. 1% 2nd year. Christee will calculate cost of payment subsidy. Qualifications based upon 1st year payment.`;
export const tipBudgetHealth_Ins = `Health Insurance costs per month`;
export const tipBudgetAuto_Ins = `Auto Insurance costs per month`;
export const tipBudgetC_Cards = `Total Credit Card payments per month`;
export const tipBudgetCar_Pay = `Car payments per month`;
export const tipBudgetC_Phone = `Cell Phone payments per month`;
export const tipBudgetCable = `Cable bill per month`;
export const tipBudgetSelfEmployed = `Are you Self Employed?`;
export const tipCred_rate = `Credit Card Interest Rate`;
export const tipCred_pay = `Credit Card Payment`;
export const tipCred_amt = `Credit Card Amount`;
// contact form
export const tipContactName = `Enter your name`;
export const tipContactPhone = `Enter your phone number`;
export const tipContactEmail = `Enter your email address`;
export const tipContactMessage = `Enter your message`;
export const tipContactInterest = `Select your interest`;
export const tipContactContactBy = `Select how you would like to be contacted`;
export const tipContactZip = `Enter your zip code`;
// pricing form
export const tipPricingName = `Name`;
export const tipPricingPhone = `Phone`;
export const tipPricingMessage = `Message`;
export const tipPricingEmail = `Email`;
export const tipPricingZip1 = `Zip Code 1`;
export const tipPricingZip2 = `Zip Code 2`;
export const tipPricingZip3 = `Zip Code 3`;
export const tipPricingLicenseNumber = `License Number`;
export const tipZip = `Enter the zip code`;

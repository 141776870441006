import React, { useContext, useEffect, useState } from 'react';

import { Auth } from 'aws-amplify';
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";

import { UiSettingsProviderContext } from '../../atoms';

import { filterRatesValues } from './helpers';
import { ChangeRateOverlay } from './ChangeRateOverlay';
import { FormInterestRatesModal } from "./FormInterestRatesModal";

import "./style.css";

type Props = {
  watchCalcForm: any,
  resetCalcForm: any,
  selectedRates?: string[]
}

const FormInputChangeRate = ({ watchCalcForm, resetCalcForm, selectedRates = ["conv", "fha", "usda", "va"] }: Props) => {
  const { currentSettings: { apiUrls } } = useContext(UiSettingsProviderContext);

  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const {
    control,
    watch,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({ mode: 'onTouched', reValidateMode: 'onBlur' });

  const watchRates = watch();

  const hideModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const session = await Auth.currentSession();
        const accessToken = session.getAccessToken().getJwtToken();

        let response = await fetch(
          apiUrls.interestRates!,
          {
            headers: {
              token: accessToken
            }
          }
        );
        const data = await response.json();

        if (data) {
          const filteredRatesValues = filterRatesValues(data, selectedRates);

          resetCalcForm({
            ...watchCalcForm,
            interest_rates: filteredRatesValues
          });
          reset(filteredRatesValues);
        }
      } catch (err) {
        if (err instanceof Error) {
          toast.error(err.message);
        }
      } finally {
        setIsLoading(false)
      }
    };

    if (Object.keys(watchRates).length === 0) {
      getData();
    }
  }, []);

  return (
    <>
      <div onClick={() => setShowModal(true)} className="sp-input change-rate">
        <ChangeRateOverlay watchRates={watchRates} />
      </div>
      <FormInterestRatesModal
        isLoading={isLoading}
        errors={errors}
        show={showModal}
        control={control}
        hideModal={hideModal}
        watchRates={watchRates}
        handleSubmit={handleSubmit}
        resetCalcForm={resetCalcForm}
        watchCalcForm={watchCalcForm} />
    </>
  );
};

export default FormInputChangeRate;

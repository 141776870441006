import React from "react";

import {
  ReportSectionHeader,
  ReportSectionItemMess,
  ReportSectionMessElement
} from "../../../molecules";

import { BuyerQualChoiceReport } from "./index";

interface Props extends BuyerQualChoiceReport {
  calcType: 'aCalc' | 'bCalc'
}

export const LoanMess = ({ dats, loanType, calcType }: Props) => {
  return (
    <ReportSectionItemMess>
      <ReportSectionHeader disableBottomLine>{dats[loanType][calcType]?.loan_mess}</ReportSectionHeader>
      <ReportSectionMessElement>{dats[loanType][calcType]?.m_note}</ReportSectionMessElement>
      <ReportSectionMessElement>{dats[loanType][calcType]?.m_note1}</ReportSectionMessElement>
      <ReportSectionMessElement>{dats[loanType][calcType]?.m_note2}</ReportSectionMessElement>
      <ReportSectionMessElement>{dats[loanType][calcType]?.m_note3}</ReportSectionMessElement>
      <ReportSectionMessElement>{dats[loanType][calcType]?.m_note4}</ReportSectionMessElement>
      <ReportSectionMessElement>{dats[loanType][calcType]?.m_note5}</ReportSectionMessElement>
    </ReportSectionItemMess>
  );
}

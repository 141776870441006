/*
 * Copyright 2020-2021 AskChristee, LLC. All rights reserved.
 */
import { Auth } from 'aws-amplify';

import {
  TAmortizationResponse,
  TBudgetResponse,
  TBuyersChoiceResponse,
  TBuyerQualResponse,
  TClosingCostResponse,
  TBuyOrRentResponse,
  TBuyWaitResponse,
  THecmResponse,
  TInvestmentResponse,
  TSellerNetResponse,
  TVacationResponse,
  TPaymentSubsidyResponse,
  TConsumerDebtResponse,
  TBuyerQualChoiceResponseV2,
  TVacationResponseV2,
  TBuyOrRentResponseV2,
} from '../types/api';

// Quick Start home folder
export const QS = 'https://resources.askchristee.com/quick-start-files/';
export const SHOW_CONTACT_TAB = false;

// Quick start files
export const hAMORTIZATION = QS + 'amortization-quick-start';
export const hBUDGET = QS + 'monthly-budget';
export const hBUYERCHOICE = QS + 'buyer-choice';
export const hBUYERCHOICEQUICK = QS + 'quick-start-buyer-choice';
export const hBUYERQUAL = QS + 'buyer-prequal';
export const hBUYERQUALQUICK = QS + 'quick-start-prequal';
export const hBUYRENT = QS + 'buy-or-rent';
export const hBUYWAIT = QS + 'buy-today-or-wait';
export const hCLOSINGCOST = QS + 'closing-cost';
export const hCONSUMERDEBT = QS + 'consumer-debt';
export const hEQUITY = QS + 'home-wealth-analysis';
export const hINVESTMENT = QS + 'investment-property-analysis';
export const hREVERSE = QS + 'reverse-mortgage';
export const hSELLERNET = QS + 'seller-net';
export const hSUBSIDY = QS + 'payment-subsidy';
export const hVACATION = QS + 'vacation';

const BASE_URL = 'https://api.askchristee.com/';
const AMORTIZATION = '/engine/amortization';
const BUDGET = '/engine/budget';
const BUY_RENT = '/engine/buy-rent';
const BUY_RENT_V2 = '/engine/v2/buy-rent';
const BUY_WAIT = '/engine/buy-wait';
const BUYER_QUAL = '/engine/buyer-qual';
const BUYER_QUALV2 = '/engine/v2/buyer-qual';
const BUYER_QUAL_QUICK = '/engine/v2/buyer-qual/quick';
const BUYERS_CHOICE = '/engine/buyer-choice';
const BUYERS_CHOICEV2 = '/engine/v2/buyer-choice';
const BUYERS_CHOICE_QUICK = '/engine/v2/buyer-choice/quick';
const CLOSING_COST = '/engine/closing-cost';
const CONSUMER_DEBT = '/engine/consumer-debt';
const EQUITY = '/engine/equity';
const EXEMPLAR = '/engine/exemplar';
const HECM = '/engine/hecm';
const INVEST = '/engine/invest';
const PAYMENT_SUBSIDY = '/engine/payment-subsidy';
const SELLER_NET = '/engine/seller-net';
const VACATION = '/engine/vacation';
const VACATIONV2 = '/engine/v2/vacation';

type ContactRequestProps = {
  data: any;
  url: string;
};

export async function contactApi<T>({ data, url }: ContactRequestProps): Promise<T> {
  return fetch(url, { method: 'POST', body: JSON.stringify(data) }).then((response) => {
    if (!response.ok) {
      if (response.status === 401) {
        throw new Error('Unauthorized');
      }
      throw new Error(response.statusText);
    }
    return response.json() as Promise<T>;
  });
}

function buildRequestOptions({ data, captchaToken, accessToken }: { data: any, captchaToken: string, accessToken: string }): any {
  const requestOptions = {
    method: 'POST',
    headers: {
      'token': accessToken,
      'x-recaptcha-token': captchaToken,
    },
    body: JSON.stringify(data),
  };

  return requestOptions;
}

async function makeEngineApiCall<T>(endpoint: string, data: any, stage: string, _: string, captchaToken = ''): Promise<T> {
  const session = await Auth.currentSession();
  const accessToken = session.getAccessToken().getJwtToken();

  const url = BASE_URL + stage + endpoint;
  const requestOptions = buildRequestOptions({ data, captchaToken, accessToken });
  return fetch(url, requestOptions).then((response) => {
    if (!response.ok) {
      if (response.status === 401) {
        throw new Error('Unauthorized');
      }
      throw new Error(response.statusText);
    }
    return response.json() as Promise<T>;
  });
}

export async function fetchAmortization(data: any, stage: string | null | undefined, key: string | null | undefined, captchaToken = ''): Promise<TAmortizationResponse> {
  return makeEngineApiCall<TAmortizationResponse>(AMORTIZATION, data, stage || 'stage', key || '', captchaToken);
}

export async function fetchBudget(data: any, stage: string | null | undefined, key: string | null | undefined, captchaToken = ''): Promise<TBudgetResponse> {
  return makeEngineApiCall<TBudgetResponse>(BUDGET, data, stage || 'stage', key || '', captchaToken);
}

export async function fetchBuyersChoice(data: any, stage: string | null | undefined, key: string | null | undefined, captchaToken = ''): Promise<TBuyersChoiceResponse> {
  return makeEngineApiCall<TBuyersChoiceResponse>(BUYERS_CHOICE, data, stage || 'stage', key || '', captchaToken);
}

export async function fetchBuyerChoiceV2(data: any, stage: string | null | undefined, key: string | null | undefined, captchaToken = ''): Promise<TBuyerQualChoiceResponseV2> {
  return makeEngineApiCall<TBuyerQualChoiceResponseV2>(BUYERS_CHOICEV2, data, stage || 'stage', key || '', captchaToken);
}

export async function fetchBuyerChoiceQuick(data: any, stage: string | null | undefined, key: string | null | undefined, captchaToken = ''): Promise<TBuyerQualChoiceResponseV2> {
  return makeEngineApiCall<TBuyerQualChoiceResponseV2>(BUYERS_CHOICE_QUICK, data, stage || 'stage', key || '', captchaToken);
}

export async function fetchBuyerQual(data: any, stage: string | null | undefined, key: string | null | undefined, captchaToken = ''): Promise<TBuyerQualResponse> {
  return makeEngineApiCall<TBuyerQualResponse>(BUYER_QUAL, data, stage || 'stage', key || '', captchaToken);
}

export async function fetchBuyerQualV2(data: any, stage: string | null | undefined, key: string | null | undefined, captchaToken = ''): Promise<TBuyerQualChoiceResponseV2> {
  return makeEngineApiCall<TBuyerQualChoiceResponseV2>(BUYER_QUALV2, data, stage || 'stage', key || '', captchaToken);
}

export async function fetchBuyerQualQuick(data: any, stage: string | null | undefined, key: string | null | undefined, captchaToken = ''): Promise<TBuyerQualChoiceResponseV2> {
  return makeEngineApiCall<TBuyerQualChoiceResponseV2>(BUYER_QUAL_QUICK, data, stage || 'stage', key || '', captchaToken);
}

export async function fetchBuyOrRent(data: any, stage: string | null | undefined, key: string | null | undefined, captchaToken = ''): Promise<TBuyOrRentResponse> {
  return makeEngineApiCall<TBuyOrRentResponse>(BUY_RENT, data, stage || 'stage', key || '', captchaToken);
}

export async function fetchBuyOrRentV2(data: any, stage: string | null | undefined, key: string | null | undefined, captchaToken = ''): Promise<TBuyOrRentResponseV2> {
  return makeEngineApiCall<TBuyOrRentResponseV2>(BUY_RENT_V2, data, stage || 'stage', key || '', captchaToken);
}

export async function fetchBuyWait(data: any, stage: string | null | undefined, key: string | null | undefined, captchaToken = ''): Promise<TBuyWaitResponse> {
  return makeEngineApiCall<TBuyWaitResponse>(BUY_WAIT, data, stage || 'stage', key || '', captchaToken);
}

export async function fetchClosingCost(data: any, stage: string | null | undefined, key: string | null | undefined, captchaToken = ''): Promise<TClosingCostResponse> {
  return makeEngineApiCall<TClosingCostResponse>(CLOSING_COST, data, stage || 'stage', key || '', captchaToken);
}

export async function fetchEquity(data: any, stage: string | null | undefined, key: string | null | undefined, captchaToken = ''): Promise<TAmortizationResponse> {
  return makeEngineApiCall<TAmortizationResponse>(EQUITY, data, stage || 'stage', key || '', captchaToken);
}

export async function fetchHecm(data: any, stage: string | null | undefined, key: string | null | undefined, captchaToken = ''): Promise<THecmResponse> {
  return makeEngineApiCall<THecmResponse>(HECM, data, stage || 'stage', key || '', captchaToken);
}

export async function fetchInvest(data: any, stage: string | null | undefined, key: string | null | undefined, captchaToken = ''): Promise<TInvestmentResponse> {
  return makeEngineApiCall<TInvestmentResponse>(INVEST, data, stage || 'stage', key || '', captchaToken);
}

export async function fetchSellerNet(data: any, stage: string | null | undefined, key: string | null | undefined, captchaToken = ''): Promise<TSellerNetResponse> {
  return makeEngineApiCall<TSellerNetResponse>(SELLER_NET, data, stage || 'stage', key || '', captchaToken);
}

export async function fetchVacation(data: any, stage: string | null | undefined, key: string | null | undefined, captchaToken = ''): Promise<TVacationResponse> {
  return makeEngineApiCall<TVacationResponse>(VACATION, data, stage || 'stage', key || '', captchaToken);
}

export async function fetchVacationV2(data: any, stage: string | null | undefined, key: string | null | undefined, captchaToken = ''): Promise<TVacationResponseV2> {
  return makeEngineApiCall<TVacationResponseV2>(VACATIONV2, data, stage || 'stage', key || '', captchaToken);
}

export async function fetchConsumerDebt(data: any, stage: string | null | undefined, key: string | null | undefined, captchaToken = ''): Promise<TConsumerDebtResponse> {
  return makeEngineApiCall<TConsumerDebtResponse>(CONSUMER_DEBT, data, stage || 'stage', key || '', captchaToken);
}

export async function fetchPaymentSubsidy(data: any, stage: string | null | undefined, key: string | null | undefined, captchaToken = ''): Promise<TPaymentSubsidyResponse> {
  return makeEngineApiCall<TPaymentSubsidyResponse>(PAYMENT_SUBSIDY, data, stage || 'stage', key || '', captchaToken);
}

export async function fetchExemplar(data: any, stage: string | null | undefined, key: string | null | undefined, captchaToken = ''): Promise<TBuyersChoiceResponse> {
  return makeEngineApiCall<TBuyersChoiceResponse>(EXEMPLAR, data, stage || 'stage', key || '', captchaToken);
}

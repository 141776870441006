import React, { useEffect, useState } from 'react';
import { FloatingLabel, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import editIcon from '../../../images/edit-icon.svg';

import { generateTableData, RateData, TableDataRow } from './helpers';

export const ChangeRateOverlay = ({ watchRates }: { watchRates: RateData }) => {
  const [tableData, setTableData] = useState<TableDataRow[]>([]);

  useEffect(() => {
    const newTableData = generateTableData(watchRates);

    setTableData(newTableData);
  }, [watchRates]);

  return (
    <OverlayTrigger
      placement='right'
      trigger={['hover', 'focus']}
      rootClose
      overlay={
        <Tooltip className='change-rate__tooltip' style={{ maxWidth: 'auto' }}>
          <table className='change-rate__table change-rate-table'>
            <tbody>
            <tr>
              <th className='change-rate-table__head-type pb-2'>Rate</th>
              <th className='fw-bold pb-2'>30 year</th>
              <th className='fw-bold pb-2'>15 year</th>
            </tr>

            {tableData.map((item, index) => (
              <tr key={index}>
                <td>{item.label}</td>
                <td>{watchRates[item["30 Year"]]}</td>
                <td>{watchRates[item["15 Year"]]}</td>
              </tr>
            ))}
            </tbody>
          </table>
        </Tooltip>
      }
    >
      <FloatingLabel controlId='floatingSelect' label=' '>
        <div className='form-control change-rate__body'>
          <div>Change Rate</div>
          <div className='change-rate__icon'>
            <img src={editIcon} alt='' />
          </div>
        </div>
        <a className='tooltippanel'>
          <FontAwesomeIcon icon={faInfoCircle} />
        </a>
      </FloatingLabel>
    </OverlayTrigger>
  );
};

import React from 'react';
import { TClosingCostBrowser } from '../../../../types/api';
import {
  ReportContainer,
  ReportHeader,
  ReportNotesSection,
  ReportResults,
  ReportSection,
  ReportSectionHeader,
  ReportSectionItem,
  ReportSectionItemMess,
  ReportSectionMessElement,
} from '../../../molecules';

export type TClosingCostReport = {
  browser: TClosingCostBrowser;
};

const ClosingCost = ({ browser }: TClosingCostReport) => {
  return (
    <ReportContainer>
      <ReportHeader>{browser.brwmess1}</ReportHeader>
      <ReportHeader>{browser.brwmess2}</ReportHeader>
      <ReportSection>
        <ReportSectionItem>
          <ReportSectionHeader>{browser.header1}</ReportSectionHeader>
          <ReportSectionMessElement>{browser.brwmess3}</ReportSectionMessElement>
          <ReportSectionMessElement>{browser.brwmess4}</ReportSectionMessElement>
          <ReportSectionMessElement>{browser.brwmess7}</ReportSectionMessElement>
        </ReportSectionItem>
        <ReportSectionItem>
          <ReportSectionHeader>{browser.header4}</ReportSectionHeader>
          <ReportSectionMessElement>{browser.brwmess14}</ReportSectionMessElement>
          <ReportSectionMessElement>{browser.brwmess15}</ReportSectionMessElement>
          <ReportSectionMessElement>{browser.brwmess16}</ReportSectionMessElement>
        </ReportSectionItem>
        <ReportSectionItem>
          <ReportSectionHeader>{browser.header2}</ReportSectionHeader>
          <ReportSectionMessElement>{browser.brwmess5}</ReportSectionMessElement>
          <ReportSectionMessElement>{browser.brwmess6}</ReportSectionMessElement>
          <ReportSectionMessElement>{browser.brwmess8}</ReportSectionMessElement>
          <ReportSectionMessElement>{browser.brwmess9}</ReportSectionMessElement>
        </ReportSectionItem>
        <ReportSectionItem>
          <ReportSectionHeader>{browser.header3}</ReportSectionHeader>
          <ReportSectionMessElement>{browser.brwmess10}</ReportSectionMessElement>
          <ReportSectionMessElement>{browser.brwmess11}</ReportSectionMessElement>
          <ReportSectionMessElement>{browser.brwmess12}</ReportSectionMessElement>
          <ReportSectionMessElement>{browser.brwmess13}</ReportSectionMessElement>
        </ReportSectionItem>
        <ReportSectionItem>
          <ReportSectionHeader>{browser.header6}</ReportSectionHeader>
          <ReportSectionMessElement>{browser.brwmess19}</ReportSectionMessElement>
          <ReportSectionMessElement>{browser.brwmess20}</ReportSectionMessElement>
          <ReportSectionMessElement>{browser.brwmess21}</ReportSectionMessElement>
          <ReportSectionMessElement>{browser.brwmess22}</ReportSectionMessElement>
        </ReportSectionItem>
        <ReportSectionItem>
          <ReportSectionHeader>{browser.header5}</ReportSectionHeader>
          <ReportSectionMessElement>{browser.brwmess17}</ReportSectionMessElement>
          <ReportSectionMessElement>{browser.brwmess30}</ReportSectionMessElement>
        </ReportSectionItem>
        <ReportSectionItem>
          <ReportSectionHeader>{browser.header7}</ReportSectionHeader>
          <ReportSectionMessElement>{browser.brwmess24}</ReportSectionMessElement>
          <ReportSectionMessElement>{browser.brwmess25}</ReportSectionMessElement>
          <ReportSectionMessElement>{browser.brwmess26}</ReportSectionMessElement>
          <ReportSectionMessElement>{browser.brwmess27}</ReportSectionMessElement>
          <ReportSectionMessElement>{browser.brwmess28}</ReportSectionMessElement>
          <ReportSectionMessElement>{browser.brwmess29}</ReportSectionMessElement>
        </ReportSectionItem>
        <ReportSectionItem>
          <ReportSectionHeader>{browser.header8}</ReportSectionHeader>
          <ReportSectionMessElement>{browser.brwmess37}</ReportSectionMessElement>
          <ReportSectionMessElement>{browser.brwmess31}</ReportSectionMessElement>
          <ReportSectionMessElement>{browser.brwmess32}</ReportSectionMessElement>
          <ReportSectionMessElement>{browser.brwmess33}</ReportSectionMessElement>
          <ReportSectionMessElement>{browser.brwmess34}</ReportSectionMessElement>
          <ReportSectionMessElement>{browser.brwmess35}</ReportSectionMessElement>
        </ReportSectionItem>
      </ReportSection>
      <ReportResults>{browser.loan_mess}</ReportResults>
      <ReportNotesSection>
        <ReportSectionItemMess>
          <ReportSectionMessElement>{browser.m_note}</ReportSectionMessElement>
          <ReportSectionMessElement>{browser.m_note1}</ReportSectionMessElement>
          <ReportSectionMessElement>{browser.m_note2}</ReportSectionMessElement>
        </ReportSectionItemMess>
      </ReportNotesSection>
    </ReportContainer>
  );
};

export default ClosingCost;

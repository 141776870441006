import React from 'react';
import FormInputRadioButtons from '../FormInputRadioButtons';

type FormInputPMICalculationProps = {
  accountType?: string;
  control: any;
  errors: any;
  tip?: string;
  disabled?: boolean;
};

// per conversation with Gary on 2023-03-28 we removed the test for user type
const FormInputPMICalculation = ({ ...rest }: FormInputPMICalculationProps) => {
  return <FormInputRadioButtons label="PMI Calculation" name="input_pmi$" defaultValue={'y'} ids={['input_pmi_yes', 'input_pmi_no']} {...rest} />;
};

export default FormInputPMICalculation;

/*
 * Copyright 2020-2021 AskChristee, LLC. All rights reserved.
 */

import React, { useContext, useEffect, useRef, useState } from 'react';

import { Accordion, Tab, Tabs } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { hBUDGET } from '../../../../api';
import {
  FormInputCreditScore,
  FormInputDollar,
  FormInputEmail,
  FormInputFirstVaExempt,
  FormInputLoanTypeSingle,
  FormInputLoanTypeSingleHandle,
  FormInputNumber,
  FormInputPercent2,
  FormInputRadioButtons,
  FormInputStateAreaDropdowns,
  FormInputStateAreaDropdownsHandle,
  FormInputTerm,
  FormInputTermHandle,
  FormInputText,
  FormInputZip, FormLoader,
  FormWrapper,
  ValidationModal,
} from '../../../molecules';
import { Help } from '../../../molecules/QuickStart';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { fetchBudget } from '../../../../api';
import { TBudgetResponse } from '../../../../types/api';
import {
  tipAlimony,
  tipBudgetAuto_Ins,
  tipBudgetCable,
  tipBudgetCar_Pay,
  tipBudgetC_Cards,
  tipBudgetC_Phone,
  tipBudgetHealth_Ins,
  tipBudgetSelfEmployed,
  tipChild,
  tipColl_debt,
  tipCondo,
  tipCust_name,
  tipC_care,
  tipC_support,
  tipEmail,
  tipFiling_status,
  tipFood,
  tipInput_ltv,
  tipInput_ma,
  tipInput_util,
  tipInt_rate,
  tipOtherd,
  tipOtheramt,
  tipPets,
  tipProp_t,
  tipQ_income,
  tipRetire,
  tipRob_doType,
  tipSp,
  tipSq_foot,
  tipTfree_income,
  tipVa_fee_ex,
  tipVa_first,
  tipZip,
  UiSettingsProviderContext,
  PoweredBy,
} from '../../../atoms';
import { ChristeeConfigCtx } from '../../../context';
import { ButtonSendPdf } from '../../../molecules/ButtonSendPdf';
import FormInputFirstVaLoan from '../../../molecules/FormInputFirstVaLoan';
import FormMessageVaLoan from '../../../molecules/FormMessageVaLoan';
import { BudgetReport } from '../../Reports';
import ButtonDownloadPdf from "../../../molecules/ButtonDownloadPdf";
import { useRecommendations } from '../../../../hooks/useRecommendations';
import { Recommended } from '../../Recommendations';
import HomeMainImage from "../../../../images/main.png"

const Budget = () => {
  const defaultValues = {
    account_type: '',
    age: '',
    alimony: '',
    auto_ins: '',
    c_cards: '',
    c_care: '',
    c_phone: '',
    c_support: '',
    cable: '',
    car_pay: '',
    child: '',
    coll_debt: '',
    condo: '',
    cust_email: '',
    cust_name: '',
    cust_phone: '',
    filing_status: 's',
    health_ins: '',
    input_dol_cred: '',
    input_food: '',
    input_ltv: '',
    input_ma: '',
    input_rpt_age: '',
    input_util: '',
    input_uw_fee: '',
    inputcred: '',
    int_rate2: '',
    int_rate: '',
    lend_cred: '',
    lim_piti: '',
    m_debts: '',
    org_fee: '',
    other_amt: '',
    otherd: '',
    pets: '',
    prop_app: '',
    prop_t: '',
    q_income: '',
    report_years: '',
    retire: '',
    s_cred: '',
    self_emp: 'n',
    sp: '',
    sq_foot: '',
    t_cash: '',
    tfree_income: '',
  };
  const {
    control,
    handleSubmit,
    watch,
    reset,
    getValues,
    trigger,
    setValue,
    formState: { errors, isValid, touchedFields, submitCount },
  } = useForm<any>({ mode: 'onTouched', reValidateMode: 'onBlur', defaultValues });
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { currentSettings } = useContext(UiSettingsProviderContext);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  };

  useEffect(() => {
    console.log('useEffect->scrollTop');
    scrollTop();
  }, []);

  const [dats, setDats] = useState<TBudgetResponse>();
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState<string | null>('cash');

  const ctx = useContext(ChristeeConfigCtx);

  const stateAreaRef = useRef<FormInputStateAreaDropdownsHandle>(null);
  const loanTypeRef = useRef<FormInputLoanTypeSingleHandle>(null);
  const termRef = useRef<FormInputTermHandle>(null);
  const { recommendations, requestRecommendations } = useRecommendations();

  const onSubmit = async (data: any, loan: any) => {
    await trigger();

    scrollTop();
    setLoading(true);
    await new Promise((r) => setTimeout(r, 800));

    console.log('Form Data: ', data);
    console.log('Form Data: loan ', loan);
    console.log('watch', watch);
    console.log('errors', errors);

    const doTypeArr = data.doType && data.doType.length > 0 ? data.doType.map((m: any) => m.value) : [];
    const filteredData = {
      ...data,
      state: data.state[0].code,
      county_select: data.county_select[0].code,
      doType: doTypeArr,
      // term: Array.isArray(data.term) ? data.term[0].value : TERM_OPTIONS.find((f) => f.text === data.term)?.value,
      term: Array.isArray(data.term) ? data.term[0].value : data.term ? data.term : '30',
    };

    try {
      let captchaToken;
      if (executeRecaptcha) {
        captchaToken = await executeRecaptcha();
      }
      const modulePromise = fetchBudget(
        {
          ...filteredData,
          company_name: currentSettings.companyId,
          logged_in_email: currentSettings.email,
          logged_in_name: currentSettings.name,
          logged_in_phone: currentSettings.phone,
          account_type: currentSettings.accountType,
        },
        ctx?.stage,
        ctx?.key,
        captchaToken
      );

      const recommendationPromise = requestRecommendations({
        zipCode: data.zip,
        state: data.state?.[0].text
      });

      const [res] = await Promise.all([modulePromise, recommendationPromise]);

      if (res.meta?.displayMessage) toast.info(res.meta.displayMessage);

      console.log('onSubmit->res', res);
      setDats(res);
      setLoaded(true);
      setLoading(false);
      setKey('results');
      console.log('dats after setDats', dats);
      scrollTop();
    } catch (error) {
      setLoading(false);
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error('Something went wrong!');
      }
    }
  };

  const clearForm = (data: any) => {
    console.log('clearForm->data', data);
    console.log('clearForm->isValid', isValid);
    console.log('clearForm->errors', errors);
    console.log('clearForm->touchedFields', touchedFields);
    console.log('clearForm->submitCount', submitCount);
    console.log('clearForm->getValues->state', getValues('state'));

    stateAreaRef.current?.clear();
    loanTypeRef.current?.clear();
    termRef.current?.clear();

    reset(defaultValues);
    setDats({} as TBudgetResponse);
    setLoaded(false);
    setLoading(false);
    setKey('results');
    scrollTop();
  };

  const watchDoType = watch('doType');

  return (
    <FormWrapper>
      <div className="buyers-choice">
        <div className="container-fluid">
          <div className="row g-1">
            <div className="col-lg-3">
              <h3 className="h2">Monthly Budget</h3>
              <form className="christy" autoComplete="off">
                <fieldset>
                  <legend className="sr-only">Required Fields:</legend>
                  <FormInputStateAreaDropdowns setValue={setValue} stateWatch={watch('state')} areaWatch={watch('county_select')} control={control} errors={errors} ref={stateAreaRef} />
                  <FormInputZip label="Zip Code" tip={tipZip} error={errors.zip} name="zip" control={control} required={true} />
                  <FormInputDollar label="Sales Price" error={errors.sp} name="sp" control={control} required={true} tip={tipSp} />
                  <FormInputLoanTypeSingle watch={watchDoType} ref={loanTypeRef} tip={tipRob_doType} required={true} control={control} errors={errors} />
                  {watchDoType && watchDoType?.findIndex((type: any) => type.value === 'VA') !== -1 && <FormMessageVaLoan />}
                  <FormInputPercent2 label="Interest Rate" tip={tipInt_rate} error={errors.int_rate} name="int_rate" required={true} control={control} />
                  <FormInputTerm watch={watch('term')} ref={termRef} control={control} errors={errors} required={true} />
                  <FormInputDollar label="Annual Gross Income" tip={tipQ_income} error={errors.q_income} name="q_income" control={control} required={true} />
                  <FormInputRadioButtons
                    label="Self Employed"
                    name="self_emp"
                    tip={tipBudgetSelfEmployed}
                    defaultValue="n"
                    required={true}
                    ids={['self_emp_yes', 'self_emp_no']}
                    control={control}
                    errors={errors}
                  />
                  <FormInputRadioButtons
                    label="Married"
                    name="filing_status"
                    tip={tipFiling_status}
                    defaultValue="s"
                    required={true}
                    ids={['married', 'single']}
                    values={['m', 's']}
                    control={control}
                    errors={errors}
                  />
                  <FormInputCreditScore error={errors.inputcred} control={control} required={true} />
                  <FormInputNumber label="Number of Children" tip={tipChild} error={errors.child} name="child" required={true} min={0} max={24} control={control} />
                  <FormInputDollar label="Monthly Health Insurance" tip={tipBudgetHealth_Ins} error={errors.health_ins} name="health_ins" required={true} control={control} />
                  <FormInputDollar label="Monthly Auto Insurance" tip={tipBudgetAuto_Ins} error={errors.auto_ins} name="auto_ins" required={true} control={control} />
                  <FormInputDollar label="Monthly Credit Cards" tip={tipBudgetC_Cards} error={errors.c_cards} name="c_cards" control={control} required={true} />
                  <FormInputDollar label="Monthly Car Payment(s)" tip={tipBudgetCar_Pay} error={errors.car_pay} name="car_pay" control={control} required={true} />
                  <FormInputDollar
                    label="Monthly Utilities"
                    tip={tipInput_util}
                    error={errors.input_util}
                    name="input_util"
                    validate={{ inputUtilOrSqFoot: (v: any) => (v ? true : !!getValues('sq_foot')) }}
                    control={control}
                  />
                  <FormInputDollar label="Cell Phone" tip={tipBudgetC_Phone} error={errors.c_phone} name="c_phone" control={control} required={true} />
                  <FormInputDollar label="Monthly Cable Bill" tip={tipBudgetCable} error={errors.cable} name="cable" control={control} required={true} />
                  <FormInputDollar label="Monthly Food Budget" tip={tipFood} error={errors.input_food} name="input_food" control={control} />
                  <FormInputDollar label="Other Amount" tip={tipOtheramt} error={errors.other_amt} name="other_amt" control={control} />
                  <FormInputText label="Other Description" tip={tipOtherd} error={errors.otherd} name="otherd" control={control} />
                </fieldset>
                <Accordion id="optional-inputs">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header data-cy="btn-optional">Optional Inputs</Accordion.Header>
                    <Accordion.Body>
                      <fieldset>
                        <FormInputDollar label="Annual Tax Free Income" tip={tipTfree_income} error={errors.tfree_income} name="tfree_income" control={control} />
                        <FormInputDollar label="Monthly 401k or Pension" tip={tipRetire} error={errors.retire} name="retire" control={control} />
                        <FormInputDollar label="Monthly Child Care" tip={tipC_care} error={errors.c_care} name="c_care" control={control} />
                        <FormInputDollar label="Monthly Child Support" tip={tipC_support} error={errors.c_support} name="c_support" control={control} />
                        <FormInputDollar label="Monthly Alimony Obligation" tip={tipAlimony} error={errors.alimony} name="alimony" control={control} />
                        <FormInputDollar label="Monthly School Tuition" tip={tipColl_debt} error={errors.coll_debt} name="coll_debt" control={control} />
                        <FormInputDollar label="Monthly Pet Expense" tip={tipPets} error={errors.pets} name="pets" control={control} />
                        <FormInputNumber label="Square Feet of Home" tip={tipSq_foot} error={errors.sq_foot} name="sq_foot" control={control} />
                        <FormInputDollar label="Annual Property Taxes" tip={tipProp_t} error={errors.prop_t} name="prop_t" control={control} />
                        <FormInputDollar label="Limit Loan Amt" tip={tipInput_ma} error={errors.input_ma} name="input_ma" control={control} />
                        <FormInputPercent2 label="Down Payment" tip={tipInput_ltv} error={errors.input_ltv} name="input_ltv" control={control} />
                        <FormInputDollar label="Condo Fee" tip={tipCondo} error={errors.condo} name="condo" control={control} />
                        <FormInputFirstVaExempt loanTypes={watch('doType')} setValue={setValue} tip={tipVa_fee_ex} control={control} errors={errors} />
                        <FormInputFirstVaLoan loanTypes={watchDoType} setValue={setValue} tip={tipVa_first} control={control} errors={errors} />
                        <FormInputText label="Name" tip={tipCust_name} error={errors.cust_name} name="cust_name" control={control} />
                        <FormInputText label="Phone" tip={tipCust_name} error={errors.cust_phone} name="cust_phone" control={control} />
                        <FormInputEmail accountType={currentSettings.accountType} tip={tipEmail} error={errors.cust_email} control={control} />
                      </fieldset>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

                <p className="mt-3">
                  <ValidationModal label="Submit" errors={errors} onClick={handleSubmit(onSubmit)} />
                  <button type="reset" className="btn btn-outline-secondary me-2" onClick={clearForm}>
                    Clear
                  </button>
                </p>
              </form>
            </div>

            <div className="col-lg-9 position-relative ps-4">
              {!loading && recommendations && <Recommended recommendations={recommendations} />}
              <div className="form-results">
                <div className="w-100 d-flex align-items-center justify-content-end">
                  <Help sFile={hBUDGET} />
                </div>
                {loading ? (
                  <FormLoader />
                ) : loaded && dats ? (
                  <>
                    <Tabs id="controlled-tab" activeKey={key as string} onSelect={(k) => setKey(k)} className="mb-3">
                      <Tab className="pt-3" eventKey="results" title="Results">
                        <BudgetReport {...dats!} />
                      </Tab>
                    </Tabs>
                    <div className="d-flex mt-3">
                      <ButtonDownloadPdf data={dats} fileName="budget" />
                      {dats?.meta.email && dats?.meta.email !== 'nil' && <ButtonSendPdf dats={dats} />}
                    </div>
                  </>
                ) : (
                  <>
                    <h4 id="house-title" className="h4">
                      You're seconds away from unlocking your Budget data.
                    </h4>
                    <img id="house-image" src={HomeMainImage} alt="home" />
                  </>
                )}
              </div>
              {/* only guests can see this */}

              <div className="w-100 mt-3">
                <PoweredBy />
              </div>
            </div>
          </div>
        </div>
      </div>
    </FormWrapper>
  );
};

export default Budget;

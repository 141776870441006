import React from 'react';
import { TInvestmentCalc } from '../../../../types/api';
import { ReportSectionElement, ReportSectionItem, ReportContainer, ReportHeader, ReportSection, ReportSectionHeader, ReportSectionItemMess, ReportSectionMessElement } from '../../../molecules';

interface Props {
  calc: TInvestmentCalc;
}

const Investment: React.FC<Props> = ({ calc }) => {
  return (
    <ReportContainer>
      <ReportHeader>{calc.brow_head}</ReportHeader>
      <ReportHeader>{calc.brow_head2}</ReportHeader>
      <ReportSection>
        <ReportSectionItem>
          <ReportSectionHeader>{calc.header1}</ReportSectionHeader>
          <ReportSectionElement title="Purchase Price" value={calc.disp_sp} />
          <ReportSectionElement title="Loan Amount" value={calc.disp_ma} />
          <ReportSectionElement title="Down payment" value={calc.disp_d_payment} />
          <ReportSectionElement title="Total Closing Cost" value={calc.disp_totalcc} />
          <ReportSectionElement title="Closing Cost Credit" value={calc.dol_cred.toString()} />
          <ReportSectionElement title="Cash Investment" value={calc.disp_rcash} />
        </ReportSectionItem>
        <ReportSectionItem>
          <ReportSectionHeader>{calc.header2}</ReportSectionHeader>
          <ReportSectionElement title="Loan Amount" value={calc.disp_ma} />
          <ReportSectionElement title="Principal &amp; Interest" value={calc.disp_pi} />
          <ReportSectionElement title="Mortgage Insurance" value={calc.temp_mi.toString()} />
          <ReportSectionElement title="Property Taxes" value={calc.temp_pt} />
          <ReportSectionElement title="Property Insurances" value={calc.temp_ins} />
          <ReportSectionElement title="Total Monthly Payment" value={calc.disp_piti} />
        </ReportSectionItem>
        <ReportSectionItem>
          <ReportSectionHeader>{calc.header3}</ReportSectionHeader>
          <ReportSectionElement title="Annual Rent Increase" value={calc.rent_increase.toString()} />
          <ReportSectionElement title="Average Annual Rent" value={calc.disp_avg_rent} />
          <ReportSectionElement title="Annual Vacancy" value={calc.disp_vacancy} />
          <ReportSectionElement title="Management Fee" value={calc.disp_mgt_fee} />
          <ReportSectionElement title="Maintenance Cost" value={calc.disp_main_amt} />
          <ReportSectionElement title="Net Operating Income" value={calc.disp_noi} />
          <ReportSectionElement title="Debt Service" value={calc.disp_debt_ser} />
          <ReportSectionElement title={calc.disp_annual_cash_flow} value={calc.disp_cash_flow} />
        </ReportSectionItem>
        <ReportSectionItem>
          <ReportSectionHeader>{calc.header4}</ReportSectionHeader>
          <ReportSectionElement title="Property Value" value={calc.disp_h_value} />
          <ReportSectionElement title="Acquitision Cost" value={calc.disp_acq_cost} />
          <ReportSectionElement title="Gross Profits" value={calc.disp_gross_profit} />
          <ReportSectionElement title="Mortgage Balance" value={calc.disp_ma_bal} />
          <ReportSectionElement title="Future Equity" value={calc.disp_net_equity} />
          <ReportSectionElement title="Capital Gain" value={calc.disp_capital_gain} />
          <ReportSectionElement title="Cash Proceeds" value={calc.disp_net_cash} />
          <ReportSectionElement title="Cash Invested" value={calc.disp_rcash} />
          <ReportSectionElement title={calc.cash_$} value={calc.disp_cash_imp} />
        </ReportSectionItem>
        <ReportSectionItem>
          <ReportSectionHeader>{calc.header7}</ReportSectionHeader>
          <ReportSectionElement title="Return on Investment" value={calc.roi_return.toString()} />
          <ReportSectionElement title="Cap Rate" value={calc.capit_rate} />
          <ReportSectionElement title="Debt Service" value={calc.dscr} />
          <ReportSectionElement title="Return on Cash" value={calc.cash_return} />
          <ReportSectionElement title="Gross Rent Multiplier" value={calc.grm_factor.toString()} />
          <ReportSectionElement title="Return of Capital Years" value={calc.return_invest.toString()} />
        </ReportSectionItem>
        <ReportSectionItemMess>
          <ReportSectionHeader disableBottomLine>{calc.loan_mess}</ReportSectionHeader>
          <ReportSectionMessElement>{calc.m_note}</ReportSectionMessElement>
          <ReportSectionMessElement>{calc.m_note1}</ReportSectionMessElement>
          <ReportSectionMessElement>{calc.m_note2}</ReportSectionMessElement>
          <ReportSectionMessElement>{calc.m_note3}</ReportSectionMessElement>
          <ReportSectionMessElement>{calc.m_note4}</ReportSectionMessElement>
          <ReportSectionMessElement>{calc.m_note5}</ReportSectionMessElement>
          <ReportSectionMessElement>{calc.m_note6}</ReportSectionMessElement>
        </ReportSectionItemMess>
      </ReportSection>
    </ReportContainer>
  );
};

export default Investment;

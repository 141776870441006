import React from 'react';

import { ReportSectionElement, ReportSectionHeader, ReportSectionItem } from '../../../molecules';

import { BuyerQualChoiceReport } from './index';

interface Props extends BuyerQualChoiceReport {
  calcType: 'aCalc' | 'bCalc';
}

export const PotentialTaxConsiderations = ({ dats, loanType, calcType }: Props) => {
  return (
    <ReportSectionItem>
      <ReportSectionHeader>Potential Tax Considerations</ReportSectionHeader>
      <ReportSectionElement title="Estimated Tax Deduction" value={dats[loanType][calcType]?.disp_tot_duct} />
    </ReportSectionItem>
  );
};

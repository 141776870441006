import React from "react";

import "./styles.css";

type Props = {
  title: string,
  value?: string
}

export const ReportSectionElement = ({ title, value }: Props) => (
  <div className="report-values-section__element report-values-section-element">
    <div className="report-values-section-element__title">{title}</div>
    <div className="report-values-section-element__title">{value}</div>
  </div>
)

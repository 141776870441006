import React, { useState } from 'react';

import { BaseCard } from '../../molecules';

import { ExpandableHeader } from './ExpandableHeader';
import { RecommendationSection } from './RecommendationSection';

import './styles.css';

import { Recommendations } from '../../../types/recommended';

const POPOVER_TEXT = {
  agents: 'Contact professional agent who will assist you in buying properties.',
  officers: 'Contact professional loan officer who will assist you with the process of obtaining mortgage loans.'
}

export const Recommended = ({ recommendations }: { recommendations: Recommendations }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { agents, officers } = recommendations;

  const toggleRecommendation = () => {
    setIsOpen((isOpen) => !isOpen);
  }

  return (
    <div className='recommendations'>
      <BaseCard className='recommendations__card'>
        <ExpandableHeader isOpen={isOpen} toggle={toggleRecommendation} />
        <div className={`recommendations__card-content-wrapper ${isOpen ? 'recommendations__card-content-wrapper--open' : ''}`}>
          <div className="recommendations__card-content">
            {!!agents?.length && <RecommendationSection
              linkUrl='/agents'
              title="Agents"
              items={agents}
              isShortSize={!!officers?.length && officers?.length > 3}
              showVerticalLine={!!officers?.length}
              popoverContent={POPOVER_TEXT.agents}
            />}
            {!!officers?.length && <RecommendationSection
              linkUrl='/officers'
              items={officers}
              title="Loan officers"
              popoverContent={POPOVER_TEXT.officers}
            />}
            {!officers?.length && !agents?.length
              && <h4 className="recommendations__card-not-found">
                Nothing found!
            </h4>}
          </div>
        </div>
      </BaseCard>
    </div>
  );
};

/*
 * Copyright 2020-2021 AskChristee, LLC. All rights reserved.
 */

import React from 'react';

import {THecmResponseObject} from "../../../../types/api";
import {
  ReportHeader,
  ReportNotesSection,
  ReportSection
} from "../../../molecules";

import {Notes} from "./Notes";
import {ReportValues} from "./ReportValues";

export type HecmReport = {
  dats: THecmResponseObject,
}

const Hecm = ({ dats }: HecmReport) => {
  return (
    <div>
      <ReportHeader>{dats.aCalc?.brow_head}</ReportHeader>
      <ReportHeader>{dats.aCalc?.brow_head2}</ReportHeader>

      <ReportSection>
        <ReportValues calc={dats.aCalc} />
        {dats.bCalc && <ReportValues calc={dats.bCalc} />}
      </ReportSection>

      <ReportNotesSection>
        <Notes calc={dats.aCalc} />
      </ReportNotesSection>
    </div>
  );
};

export default Hecm;

import React from 'react';

import {ReportSectionElement, ReportSectionHeader, ReportSectionItem} from "../../../molecules";

import {TBuyOrRentResponseObjectV2} from "../../../../types/api";


export const PurchaseDetails = ({ aCalc }: TBuyOrRentResponseObjectV2) => {
  return (
    <ReportSectionItem>
      <ReportSectionHeader>Purchase Details</ReportSectionHeader>
      <ReportSectionElement title="Purchase Price" value={aCalc.disp_sp} />
      <ReportSectionElement title="Mortgage Amount" value={aCalc.disp_ma} />
      <ReportSectionElement title="Total Mortgage Amt" value={aCalc.disp_total_mtge} />
      <ReportSectionElement title="Down Payment" value={aCalc.disp_d_payment} />
      <ReportSectionElement title="Closing Cost" value={aCalc.disp_totalcc} />
      <ReportSectionElement title="Closing Cost Credit" value={aCalc.disp_dol_cred} />
      <ReportSectionElement title="Required Cash" value={aCalc.disp_r_cash} />
    </ReportSectionItem>
  );
};

import React, { ReactNode } from "react";

import { Card } from "react-bootstrap";

import "./styles.css";

const BaseCard = ({
  className,
  children
}: {
  className?: string;
  children: ReactNode;
}) => {
  return (
    <Card className={`ui-custom-card ${className}`}>
      <Card.Body className="ui-custom-card__body">
        {children}
      </Card.Body>
    </Card>
  );
};

export default BaseCard;

/*
 * Copyright 2020-2021 AskChristee, LLC. All rights reserved.
 */

import React from 'react';

import {TVacationResultsV2} from "../../../../types/api";
import {
  ReportHeader,
  ReportNotesSection,
  ReportResults,
  ReportSection
} from "../../../molecules";

import {LoanMess} from "./LoanMess";
import {LoanDetails} from "./LoanDetails";
import {CashRequirements} from "./CashRequirements";
import {ProjectedPaymentDetails} from "./ProjectedPaymentDetails";
import {PotentialTaxConsiderations} from "./PotentialTaxConsiderations";

export type VacationReport = {
  dats: TVacationResultsV2,
  calcType: "aCalc1" | "aCalc2" | "aCalc3" | "aCalc4"
}

const Vacation = ({ dats, calcType }: VacationReport) => {

  return (
    <div>
      <ReportHeader>{dats[calcType]?.brow_head}</ReportHeader>
      <ReportHeader>{dats[calcType]?.brow_head_2}</ReportHeader>

      <ReportSection>
        <LoanDetails dats={dats} calcType={calcType} />
        <CashRequirements dats={dats} calcType={calcType} />
        <ProjectedPaymentDetails dats={dats} calcType={calcType} />
        <PotentialTaxConsiderations dats={dats} calcType={calcType} />
      </ReportSection>

      <ReportResults>{dats[calcType]?.results$}</ReportResults>

      <ReportNotesSection>
        <LoanMess dats={dats} calcType={calcType} />
      </ReportNotesSection>
    </div>
  );
};

export default Vacation;

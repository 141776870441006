import React, { useEffect, useState } from 'react';

import MinimumCashSwitch from '../MinimumCashSwitch';

type Props = {
  regularReport: React.ReactNode,
  cashReport: React.ReactNode,
  isBCalcAvailable: boolean
}

const ComparisonLoansReport = ({ regularReport, cashReport, isBCalcAvailable }: Props) => {
  const [isACalc, setIsACalc] = useState(true);

  const switchCalcType = () => {
    setIsACalc(prevState => !prevState);
  }

  useEffect(() => {
    setIsACalc(!isBCalcAvailable);
  }, [isBCalcAvailable]);

  return (
    <>
      {isBCalcAvailable && <div className="w-100 d-flex align-items-center justify-content-end">
        <MinimumCashSwitch onChange={switchCalcType} checked={isACalc} />
      </div>}

      {isACalc ? regularReport : cashReport}
    </>
  )
}

export default ComparisonLoansReport;

import React from 'react';

import { ReportSectionElement, ReportSectionHeader, ReportSectionItem } from '../../../molecules';

import { TBoRACalcV2 } from '../../../../types/api';

type Props = {
  aCalc: TBoRACalcV2;
};

export const Cash = ({ aCalc }: Props) => {
  return (
    <ReportSectionItem>
      <ReportSectionHeader>{aCalc.cash$}</ReportSectionHeader>
      <ReportSectionElement title="Required Cash Buying" value={aCalc.disp_r_cash} />
      <ReportSectionElement title="Cash Growth Buying" value={aCalc.disp_req_cash_grow} />
      <ReportSectionElement title="Cash Growth Renting" value={aCalc.disp_inv_cash} />
      <ReportSectionElement title={aCalc.gain$} value={aCalc.disp_net_gain} />
    </ReportSectionItem>
  );
};

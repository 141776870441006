/*
 * Copyright 2020-2021 AskChristee, LLC. All rights reserved.
 */

import React, { useContext, useEffect, useRef, useState } from 'react';
import { Accordion, Tab, Tabs } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import {hCLOSINGCOST} from '../../../../api';
import {
  tipClose_date,
  tipCust_addr,
  tipCust_name,
  tipEmail,
  tipFiling_status,
  tipFtbuyer,
  tipInput_dol_cred,
  tipInput_ins,
  tipInput_ltv,
  tipInput_ma,
  tipInput_uw_fee,
  tipLend_cred,
  tipOrg_fee,
  tipProp_t,
  tipSp,
  tipS_cred,
  tipVa_fee_ex,
  tipVa_first,
  tipZip,
  UiSettingsProviderContext, PoweredBy,
} from '../../../atoms';
import { ChristeeConfigCtx } from '../../../context';
import {
  FormInputChangeRate,
  FormInputCreditScore,
  FormInputDatePicker2,
  FormInputDollar,
  FormInputEmail,
  FormInputLoanTypeSingle,
  FormInputLoanTypeSingleHandle,
  FormInputPercent2,
  FormInputRadioButtons,
  FormInputStateAreaDropdowns,
  FormInputStateAreaDropdownsHandle,
  FormInputTerm,
  FormInputTermHandle,
  FormInputText,
  FormInputZip, FormLoader, FormWrapper,
  ValidationModal,
} from '../../../molecules';
import { Help } from '../../../molecules/QuickStart';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { toast } from 'react-toastify';
import { fetchClosingCost } from '../../../../api';
import { TClosingCostResponse } from '../../../../types/api';
import { ButtonSendPdf } from '../../../molecules/ButtonSendPdf';
import FormInputFirstVaLoan from '../../../molecules/FormInputFirstVaLoan';
import FormMessageVaLoan from '../../../molecules/FormMessageVaLoan';
import { ClosingCostReport } from '../../Reports';
import FormInputFirstVaExempt from "../../../molecules/FormInputFirstVaExempt";
import ButtonDownloadPdf from "../../../molecules/ButtonDownloadPdf";
import { useRecommendations } from '../../../../hooks/useRecommendations';
import { Recommended } from '../../Recommendations';
import HomeMainImage from "../../../../images/main.png"

const ClosingCost = () => {
  const defaultValues = {
    account_type: '',
    buyer_ma: '',
    close_date: '',
    comm: '',
    condo: '',
    cust_addr: '',
    cust_email: '',
    cust_name: '',
    cust_phone: '',
    ex_mtge: '',
    input_dol_cred: '',
    input_ma: '',
    input_uw_fee: '',
    inputcred: '',
    ma_bal: '',
    main_amt: '',
    org_fee: '',
    prop_app: '',
    prop_mgt: '',
    prop_t: '',
    rent_income: '',
    rent_increase: '',
    report_years: '',
    s_cred: '',
    sales_cost: '',
    sp: '',
    stateSelect: '',
    vac_allow: '',
  };
  const {
    control,
    handleSubmit,
    watch,
    reset,
    getValues,
    trigger,
    setValue,
    formState: { errors, isValid, touchedFields, submitCount },
  } = useForm<any>({ mode: 'onTouched', reValidateMode: 'onBlur', defaultValues });
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { currentSettings } = useContext(UiSettingsProviderContext);
  const { recommendations, requestRecommendations } = useRecommendations();

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  };

  useEffect(() => {
    console.log('useEffect->scrollTop');
    scrollTop();
  }, []);

  const [dats, setDats] = useState<TClosingCostResponse>();
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState<string | null>('cash');

  const ctx = useContext(ChristeeConfigCtx);

  const stateAreaRef = useRef<FormInputStateAreaDropdownsHandle>(null);
  const loanTypeRef = useRef<FormInputLoanTypeSingleHandle>(null);
  const termRef = useRef<FormInputTermHandle>(null);

  const onSubmit = async (data: any) => {
    await trigger();

    scrollTop();
    setLoading(true);
    await new Promise((r) => setTimeout(r, 800));

    console.log('data', data);
    console.log('watch', watch);
    console.log('errors', errors);

    const doTypeArr = data.doType && data.doType.length > 0 ? data.doType.map((m: any) => m.value) : [];
    const filteredData = {
      ...data,
      state: data.state[0].code,
      county_select: data.county_select[0].code,
      doType: doTypeArr,
      term: Array.isArray(data.term) ? data.term[0].value : data.term ? data.term : '30',
    };

    try {
      let captchaToken;
      if (executeRecaptcha) {
        captchaToken = await executeRecaptcha();
      }
      const modulePromise = fetchClosingCost(
        {
          ...filteredData,
          company_name: currentSettings.companyId,
          logged_in_email: currentSettings.email,
          logged_in_name: currentSettings.name,
          logged_in_phone: currentSettings.phone,
          account_type: currentSettings.accountType,
        },
        ctx?.stage,
        ctx?.key,
        captchaToken
      );

      const recommendationPromise = requestRecommendations({
        zipCode: data.zip,
        state: data.state?.[0].text
      });

      const [res] = await Promise.all([modulePromise, recommendationPromise]);

      if (res.meta?.displayMessage) toast.info(res.meta.displayMessage);

      console.log('onSubmit->res', res);
      setDats(res);
      setLoaded(true);
      setLoading(false);
      setKey('cash');
      console.log('dats after setDats', dats);
      scrollTop();
    } catch (error) {
      setLoading(false);
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error('Something went wrong!');
      }
    }
  };

  const clearForm = (data: any) => {
    console.log('clearForm->data', data);
    console.log('clearForm->isValid', isValid);
    console.log('clearForm->errors', errors);
    console.log('clearForm->touchedFields', touchedFields);
    console.log('clearForm->submitCount', submitCount);
    console.log('clearForm->getValues->state', getValues('state'));

    stateAreaRef.current?.clear();
    loanTypeRef.current?.clear();
    termRef.current?.clear();

    reset(defaultValues);
    setDats({} as TClosingCostResponse);
    setLoaded(false);
    setLoading(false);
    setKey('cash');
    scrollTop();
  };

  const watchDoType = watch('doType');

  const show1stTimeBuyer = !!(
    watch('state')?.find((type: { code: string; text: string }) => type.code === 'md') || watchDoType?.find((type: { value: string; text: string }) => type.value === 'CONV')
  );

  return (
    <FormWrapper>
      <div className="buyers-choice">
        <div className="container-fluid">
          <div className="row g-1">
            <div className="col-lg-3">
              <h3 className="h2">Closing Cost</h3>
              <form className="christy" autoComplete="off">
                <fieldset>
                  <legend className="sr-only">Required Fields:</legend>
                  <FormInputStateAreaDropdowns setValue={setValue} stateWatch={watch('state')} areaWatch={watch('county_select')} control={control} errors={errors} ref={stateAreaRef} />
                  <FormInputZip label="Zip Code" tip={tipZip} error={errors.zip} name="zip" control={control} required={true} />
                  <FormInputChangeRate watchCalcForm={watch()} resetCalcForm={reset} />
                  <FormInputDollar label="Sales Price" error={errors.sp} name="sp" tip={tipSp} control={control} required={true} />
                  <FormInputLoanTypeSingle watch={watchDoType} ref={loanTypeRef} control={control} errors={errors} required={true} none={true} />
                  {watchDoType && watchDoType?.findIndex((type: any) => type.value === 'VA') !== -1 && <FormMessageVaLoan />}
                  <FormInputTerm watch={watch('term')} ref={termRef} control={control} errors={errors} required={true} />
                  <FormInputCreditScore error={errors.inputcred} control={control} required={true} />
                  {show1stTimeBuyer && (
                    <FormInputRadioButtons
                      label="1st Time Buyer"
                      name="ftbuyer"
                      tip={tipFtbuyer}
                      defaultValue="n"
                      required={true}
                      ids={['first_time_yes', 'first_time_no']}
                      control={control}
                      errors={errors}
                    />
                  )}
                  <FormInputRadioButtons
                    label="Married"
                    name="filing_status"
                    tip={tipFiling_status}
                    defaultValue="s"
                    required={true}
                    ids={['married', 'single']}
                    values={['m', 's']}
                    control={control}
                    errors={errors}
                  />
                </fieldset>
                <Accordion id="optional-inputs">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header data-cy="btn-optional">Optional Inputs</Accordion.Header>
                    <Accordion.Body>
                      <fieldset>
                        <FormInputDollar label="Annual Property Taxes" tip={tipProp_t} error={errors.prop_t} name="prop_t" required={false} control={control} />
                        <FormInputDollar label="Annual Property Insurance" tip={tipInput_ins} error={errors.input_ins} name="input_ins" control={control} />
                        <FormInputDatePicker2 label="Close Date" name="close_date" tip={tipClose_date} error={errors.close_date} control={control} />
                        <FormInputPercent2 label="Seller Credit" tip={tipS_cred} error={errors.s_cred} name="s_cred" control={control} required={false} />
                        <FormInputDollar label="Seller Credit" tip={tipInput_dol_cred} error={errors.input_dol_cred} name="input_dol_cred" control={control} />
                        <FormInputPercent2 label="Lender Credit" tip={tipLend_cred} error={errors.lend_cred} name="lend_cred" control={control} />
                        <FormInputDollar label="Lender Fees" tip={tipInput_uw_fee} error={errors.input_uw_fee} name="input_uw_fee" control={control} />
                        <FormInputPercent2 label="Loan Origination" tip={tipOrg_fee} error={errors.org_fee} name="org_fee" control={control} max={2} />
                        <FormInputPercent2 label="Down Payment" tip={tipInput_ltv} error={errors.input_ltv} name="input_ltv" control={control} />
                        <FormInputDollar label="Limit Loan Amt" tip={tipInput_ma} error={errors.input_ma} name="input_ma" control={control} />
                        <FormInputFirstVaExempt loanTypes={watch('doType')} setValue={setValue} tip={tipVa_fee_ex} control={control} errors={errors} />
                        <FormInputFirstVaLoan loanTypes={watch('doType')} setValue={setValue} tip={tipVa_first} control={control} errors={errors} />
                        <FormInputText label="Name" tip={tipCust_name} error={errors.cust_name} name="cust_name" control={control} />
                        <FormInputText label="Address" tip={tipCust_addr} error={errors.cust_addr} name="cust_addr" control={control} />
                        <FormInputText label="Phone" tip={tipCust_name} error={errors.cust_phone} name="cust_phone" control={control} />
                        <FormInputEmail accountType={currentSettings.accountType} tip={tipEmail} error={errors.cust_email} control={control} />
                      </fieldset>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <p className="mt-3">
                  <ValidationModal label="Submit" errors={errors} onClick={handleSubmit(onSubmit)} />
                  <button type="reset" className="btn btn-outline-secondary me-2" onClick={clearForm}>
                    Clear
                  </button>
                </p>
              </form>
            </div>
            {/* begin results */}
            <div className="col-lg-9 position-relative ps-4">
              {!loading && recommendations && <Recommended recommendations={recommendations} />}

              <div className="form-results">
                <div className="w-100 d-flex align-items-center justify-content-end">
                  <Help sFile={hCLOSINGCOST} />
                </div>
                {loading ? (
                  <FormLoader />
                ) : loaded && dats ? (
                  <>
                    <Tabs id="controlled-tab" activeKey={key as string} onSelect={(k) => setKey(k)} className="mb-3">
                      <Tab className="pt-3" eventKey="cash" title="Results">
                        <ClosingCostReport {...dats!} />
                      </Tab>
                    </Tabs>
                    <div className="d-flex mt-3">
                      <ButtonDownloadPdf data={dats} fileName="closing-cost" />
                      {dats?.meta.email && dats?.meta.email !== 'nil' && <ButtonSendPdf dats={dats} />}
                    </div>
                  </>
                ) : (
                  <>
                    <h4 id="house-title" className="h4">
                      You’re seconds away from unlocking your Closing Cost data.
                    </h4>
                    <img id="house-image" src={HomeMainImage} alt="home" />
                  </>
                )}
              </div>
              {/* only guests can see this */}

              <div className="w-100 mt-3">
                <PoweredBy />
              </div>
            </div>
          </div>
        </div>
      </div>
    </FormWrapper>
  );
};

export default ClosingCost;

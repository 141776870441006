/*
 * Copyright 2020-2021 AskChristee, LLC. All rights reserved.
 */

import React from 'react';

import { TBuyerQualChoiceResponseObjectV2 } from '../../../../types/api';
import { BrowMess, DetailCashNaked, DetailText, DetailTextCenter, HtmlDetailTextTwo, TdDownloadPdf } from '../../../atoms';

interface Props {
  conventional: TBuyerQualChoiceResponseObjectV2;
  fha: TBuyerQualChoiceResponseObjectV2;
  va: TBuyerQualChoiceResponseObjectV2;
  usda: TBuyerQualChoiceResponseObjectV2;
}

const BuyerQualCashQuick = ({ conventional, fha, va, usda }: Props) => {
  if (conventional.bCalc) {
    var aText = conventional.bCalc.brow_head;
  } else {
    var aText = '';
  }
  if (fha.bCalc) {
    var bText = fha.bCalc.brow_head;
  } else {
    var bText = '';
  }
  if (va.bCalc) {
    var cText = va.bCalc.brow_head;
  } else {
    var cText = '';
  }
  if (usda.bCalc) {
    var dText = usda.bCalc.brow_head;
  } else {
    var dText = '';
  }
  return (
    <table className="table">
      <tbody>
        <tr>
          <td colSpan={5}>
            <BrowMess a={aText} b={bText} c={cText} d={dText} />
          </td>
        </tr>
        <tr>
          <DetailText v="Loan Type" />
          {conventional.bCalc && <DetailTextCenter v={conventional.bCalc.report_type.toString()} />}
          {fha.bCalc && <DetailTextCenter v={fha.bCalc.report_type.toString()} />}
          {va.bCalc && <DetailTextCenter v={va.bCalc.report_type.toString()} />}
          {usda.bCalc && <DetailTextCenter v={usda.bCalc.report_type.toString()} />}
        </tr>
        <tr>
          <DetailText v="Alerts" />
          {conventional.bCalc && <DetailTextCenter v={conventional.bCalc.fumess.toString()} />}
          {fha.bCalc && <DetailTextCenter v={fha.bCalc.fumess.toString()} />}
          {va.bCalc && <DetailTextCenter v={va.bCalc.fumess.toString()} />}
          {usda.bCalc && <DetailTextCenter v={usda.bCalc.fumess.toString()} />}
        </tr>
        <tr>
          <DetailText v="Target Payment" />
          {conventional.bCalc && <DetailCashNaked v={conventional.bCalc.allow_piti.toString()} />}
          {fha.bCalc && <DetailCashNaked v={fha.bCalc.allow_piti.toString()} />}
          {va.bCalc && <DetailCashNaked v={va.bCalc.allow_piti.toString()} />}
          {usda.bCalc && <DetailCashNaked v={usda.bCalc.allow_piti.toString()} />}
        </tr>
        <tr className="active-row">
          <DetailText v="Projected Purchase Price" />
          {conventional.bCalc && <DetailCashNaked v={conventional.bCalc.sp.toString()} />}
          {fha.bCalc && <DetailCashNaked v={fha.bCalc.sp.toString()} />}
          {va.bCalc && <DetailCashNaked v={va.bCalc.sp.toString()} />}
          {usda.bCalc && <DetailCashNaked v={usda.bCalc.sp.toString()} />}
        </tr>
        <tr className="active-row">
          <DetailText v="Base Loan" />
          {conventional.bCalc && <HtmlDetailTextTwo a={conventional.bCalc.ma.toString()} b={conventional.bCalc.ltv.toString()} />}
          {fha.bCalc && <HtmlDetailTextTwo a={fha.bCalc.ma.toString()} b={fha.bCalc.ltv.toString()} />}
          {va.bCalc && <HtmlDetailTextTwo a={va.bCalc.ma.toString()} b={va.bCalc.ltv.toString()} />}
          {usda.bCalc && <HtmlDetailTextTwo a={usda.bCalc.ma.toString()} b={usda.bCalc.ltv.toString()} />}
        </tr>
        <tr>
          <DetailText v="Total Loan" />
          {conventional.bCalc && <DetailCashNaked v={conventional.bCalc.total_mtge.toString()} />}
          {fha.bCalc && <DetailCashNaked v={fha.bCalc.total_mtge.toString()} />}
          {va.bCalc && <DetailCashNaked v={va.bCalc.total_mtge.toString()} />}
          {usda.bCalc && <DetailCashNaked v={usda.bCalc.total_mtge.toString()} />}
        </tr>
        <tr>
          <DetailText v="Down payment" />
          {conventional.bCalc && <HtmlDetailTextTwo a={conventional.bCalc.d_payment.toString()} b={conventional.bCalc.pdf_dp.toString()} />}
          {fha.bCalc && <HtmlDetailTextTwo a={fha.bCalc.d_payment.toString()} b={fha.bCalc.pdf_dp.toString()} />}
          {va.bCalc && <HtmlDetailTextTwo a={va.bCalc.d_payment.toString()} b={va.bCalc.pdf_dp.toString()} />}
          {usda.bCalc && <HtmlDetailTextTwo a={usda.bCalc.d_payment.toString()} b={usda.bCalc.pdf_dp.toString()} />}
        </tr>
        <tr>
          <DetailText v="Closing Cost" />
          {conventional.bCalc && <DetailCashNaked v={conventional.bCalc.totalcc.toString()} />}
          {fha.bCalc && <DetailCashNaked v={fha.bCalc.totalcc.toString()} />}
          {va.bCalc && <DetailCashNaked v={va.bCalc.totalcc.toString()} />}
          {usda.bCalc && <DetailCashNaked v={usda.bCalc.totalcc.toString()} />}
        </tr>
        <tr>
          <DetailText v="Seller Credit" />
          {conventional.bCalc && <HtmlDetailTextTwo a={conventional.bCalc.dol_cred.toString()} b={conventional.bCalc.temp_cred.toString()} />}
          {fha.bCalc && <HtmlDetailTextTwo a={fha.bCalc.dol_cred.toString()} b={fha.bCalc.temp_cred.toString()} />}
          {va.bCalc && <HtmlDetailTextTwo a={va.bCalc.dol_cred.toString()} b={va.bCalc.temp_cred.toString()} />}
          {usda.bCalc && <HtmlDetailTextTwo a={usda.bCalc.dol_cred.toString()} b={usda.bCalc.temp_cred.toString()} />}
        </tr>
        <tr>
          <DetailText v="Lender Credit" />
          {conventional.bCalc && <HtmlDetailTextTwo a={conventional.bCalc.dol_lend.toString()} b={conventional.bCalc.temp_lend.toString()} />}
          {fha.bCalc && <HtmlDetailTextTwo a={fha.bCalc.dol_lend.toString()} b={fha.bCalc.temp_lend.toString()} />}
          {va.bCalc && <HtmlDetailTextTwo a={va.bCalc.dol_lend.toString()} b={va.bCalc.temp_lend.toString()} />}
          {usda.bCalc && <HtmlDetailTextTwo a={usda.bCalc.dol_lend.toString()} b={usda.bCalc.temp_lend.toString()} />}
        </tr>
        <tr className="active-row">
          <DetailText v="Required Cash" />
          {conventional.bCalc && <DetailCashNaked v={conventional.bCalc.r_cash.toString()} />}
          {fha.bCalc && <DetailCashNaked v={fha.bCalc.r_cash.toString()} />}
          {va.bCalc && <DetailCashNaked v={va.bCalc.r_cash.toString()} />}
          {usda.bCalc && <DetailCashNaked v={usda.bCalc.r_cash.toString()} />}
        </tr>
        <tr>
          <DetailText v="Cash available" />
          {conventional.bCalc && <DetailCashNaked v={conventional.bCalc.t_cash.toString()} />}
          {fha.bCalc && <DetailCashNaked v={fha.bCalc.t_cash.toString()} />}
          {va.bCalc && <DetailCashNaked v={va.bCalc.t_cash.toString()} />}
          {usda.bCalc && <DetailCashNaked v={usda.bCalc.t_cash.toString()} />}
        </tr>
        <tr>
          <DetailText v="Addition Cash Req." />
          {conventional.bCalc && <DetailCashNaked v={conventional.bCalc.ex_cash.toString()} />}
          {fha.bCalc && <DetailCashNaked v={fha.bCalc.ex_cash.toString()} />}
          {va.bCalc && <DetailCashNaked v={va.bCalc.ex_cash.toString()} />}
          {usda.bCalc && <DetailCashNaked v={usda.bCalc.ex_cash.toString()} />}
        </tr>
        <tr>
          <DetailText v="Interest Rate" />
          {conventional.bCalc && <DetailTextCenter v={conventional.bCalc.rate_display.toString()} />}
          {fha.bCalc && <DetailTextCenter v={fha.bCalc.rate_display.toString()} />}
          {va.bCalc && <DetailTextCenter v={va.bCalc.rate_display.toString()} />}
          {usda.bCalc && <DetailTextCenter v={usda.bCalc.rate_display.toString()} />}
        </tr>
        <tr className="active-row">
          <DetailText v="Total Monthly Payment" />
          {conventional.bCalc && <DetailCashNaked v={conventional.bCalc.t_piti.toString()} />}
          {fha.bCalc && <DetailCashNaked v={fha.bCalc.t_piti.toString()} />}
          {va.bCalc && <DetailCashNaked v={va.bCalc.t_piti.toString()} />}
          {usda.bCalc && <DetailCashNaked v={usda.bCalc.t_piti.toString()} />}
        </tr>
        <tr>
          <DetailText v="Principal &amp; Interest" />
          {conventional.bCalc && <HtmlDetailTextTwo a={conventional.bCalc.temp_pi.toString()} b={conventional.bCalc.res_pi.toString()} />}
          {fha.bCalc && <HtmlDetailTextTwo a={fha.bCalc.temp_pi.toString()} b={fha.bCalc.res_pi.toString()} />}
          {va.bCalc && <HtmlDetailTextTwo a={va.bCalc.temp_pi.toString()} b={va.bCalc.res_pi.toString()} />}
          {usda.bCalc && <HtmlDetailTextTwo a={usda.bCalc.temp_pi.toString()} b={usda.bCalc.res_pi.toString()} />}
        </tr>
        <tr>
          <DetailText v="Monthly Mortgage Ins" />
          {conventional.bCalc && <HtmlDetailTextTwo a={conventional.bCalc.temp_mi.toString()} b={conventional.bCalc.res_mi.toString()} />}
          {fha.bCalc && <HtmlDetailTextTwo a={fha.bCalc.temp_mi.toString()} b={fha.bCalc.res_mi.toString()} />}
          {va.bCalc && <HtmlDetailTextTwo a={va.bCalc.temp_mi.toString()} b={va.bCalc.res_mi.toString()} />}
          {usda.bCalc && <HtmlDetailTextTwo a={usda.bCalc.temp_mi.toString()} b={usda.bCalc.res_mi.toString()} />}
        </tr>
        <tr>
          <DetailText v="Monthly Prop. Taxes" />
          {conventional.bCalc && <HtmlDetailTextTwo a={conventional.bCalc.temp_pt.toString()} b={conventional.bCalc.res_pt.toString()} />}
          {fha.bCalc && <HtmlDetailTextTwo a={fha.bCalc.temp_pt.toString()} b={fha.bCalc.res_pt.toString()} />}
          {va.bCalc && <HtmlDetailTextTwo a={va.bCalc.temp_pt.toString()} b={va.bCalc.res_pt.toString()} />}
          {usda.bCalc && <HtmlDetailTextTwo a={usda.bCalc.temp_pt.toString()} b={usda.bCalc.res_pt.toString()} />}
        </tr>
        <tr>
          <DetailText v="Monthly Property Ins." />
          {conventional.bCalc && <HtmlDetailTextTwo a={conventional.bCalc.temp_ins.toString()} b={conventional.bCalc.res_ins.toString()} />}
          {fha.bCalc && <HtmlDetailTextTwo a={fha.bCalc.temp_ins.toString()} b={fha.bCalc.res_ins.toString()} />}
          {va.bCalc && <HtmlDetailTextTwo a={va.bCalc.temp_ins.toString()} b={va.bCalc.res_ins.toString()} />}
          {usda.bCalc && <HtmlDetailTextTwo a={usda.bCalc.temp_ins.toString()} b={usda.bCalc.res_ins.toString()} />}
        </tr>
        <tr>
          <DetailText v="Monthly Condo/HOA" />
          {conventional.bCalc && <HtmlDetailTextTwo a={conventional.bCalc.condo.toString()} b={conventional.bCalc.res_condo.toString()} />}
          {fha.bCalc && <HtmlDetailTextTwo a={fha.bCalc.condo.toString()} b={fha.bCalc.res_condo.toString()} />}
          {va.bCalc && <HtmlDetailTextTwo a={va.bCalc.condo.toString()} b={va.bCalc.res_condo.toString()} />}
          {usda.bCalc && <HtmlDetailTextTwo a={usda.bCalc.condo.toString()} b={usda.bCalc.res_condo.toString()} />}
        </tr>
        <tr>
          <DetailText v="Estimated Tax Deduction" />
          {conventional.bCalc && <DetailCashNaked v={conventional.bCalc.tot_duct.toString()} />}
          {fha.bCalc && <DetailCashNaked v={fha.bCalc.tot_duct.toString()} />}
          {va.bCalc && <DetailCashNaked v={va.bCalc.tot_duct.toString()} />}
          {usda.bCalc && <DetailCashNaked v={usda.bCalc.tot_duct.toString()} />}
        </tr>
        <tr>
          <TdDownloadPdf />
        </tr>
      </tbody>
    </table>
  );
};

export default BuyerQualCashQuick;

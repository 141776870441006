/*
 * Copyright 2020-2021 AskChristee, LLC. All rights reserved.
 */

import React, {forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState} from 'react';
import { FloatingLabel } from 'react-bootstrap';
import {Areas, States, TState, UiSettingsProviderContext} from '../../atoms';
import { Controller } from 'react-hook-form';
import { InputProps, Typeahead } from 'react-bootstrap-typeahead';

type FormInputStateAreaDropdownsProps = {
  control: any,
  errors: any,
  stateWatch: any,
  areaWatch: any,
  setValue: any,
}

export type FormInputStateAreaDropdownsHandle = {
  clear: () => void;
};

interface MyInputProps extends InputProps {
  "data-cy"? : string;
}

const FormInputStateAreaDropdowns = forwardRef<FormInputStateAreaDropdownsHandle, FormInputStateAreaDropdownsProps>((props, ref ) => {
  const { currentSettings } = useContext(UiSettingsProviderContext);

  const [stateList, setStateList] = useState<TState[]>([]);
  const [selectOptions, setSelectOptions] = useState<any>([]);

  const typeaheadRef = useRef<any>();
  const areaTypeaheadRef = useRef<any>();

  const options = {
    required: true
  }

  useImperativeHandle(ref, () => ({
    clear() {
      typeaheadRef?.current?.clear();
      areaTypeaheadRef?.current?.clear();
      props.setValue("county_select", []);
    }
  }));

  const toggleStateTypeahead = (data: any) => {
    console.log('toggleStateTypeahead->data', data);
    typeaheadRef?.current?.clear();
    areaTypeaheadRef?.current?.clear();
    typeaheadRef?.current?.toggleMenu();
    props.setValue("county_select", []);
  };

  const toggleAreaTypeahead = (data: any) => {
    console.log('toggleAreaTypeahead->data', data);
    areaTypeaheadRef?.current?.clear();
    areaTypeaheadRef?.current?.toggleMenu();
    props.setValue("county_select", []);
  };

  const handleStateChange = (val: any) => {
    const newSelectOptions = Areas(val[0]?.code);

    if (newSelectOptions.length === 1) {
      props.setValue("county_select", newSelectOptions);
    }
    setSelectOptions(newSelectOptions);
  }

  useEffect(() => {
    if (currentSettings.availableStates?.length) {
      const newStateList = currentSettings.availableStates.reduce((accumulator, availableState) => {
        const newState = States.find(state => state.code === availableState);
        if (newState) accumulator.push(newState);
        return accumulator
      }, [] as TState[]);
      setStateList(newStateList);
    } else {
      setStateList(States);
    }
  }, [currentSettings.availableStates])

  const isSingleArea: boolean = selectOptions.length === 1;

  return (
    <>
      <div className="state-input">
        <FloatingLabel
          controlId="floatingSelect"
          label={props.errors.state ? 'State is required' : props.stateWatch && props.stateWatch.length > 0 ? 'State' : 'Select a State'}
          className={props.errors.state ? 'alert-expand' : ''}
        >
          <Controller
            name="state"
            rules={options}
            control={props.control}
            render={({ field }) => (
              <Typeahead
                onBlur={field.onBlur}
                onChange={(val) => {
                  handleStateChange(val);
                  field.onChange(val);
                }}
                id="state"
                ref={typeaheadRef}
                labelKey="text"
                inputProps={{
                  'data-cy': 'select-state',
                  autoComplete: 'ignore-me',
                  shouldSelectHint: (shouldSelect, e) => {
                    return e.key === 'Tab' || e.key === 'Enter' || shouldSelect;
                  },
                } as MyInputProps }
                options={stateList}
              >
                <img className="select-down" alt="select-down" onClick={toggleStateTypeahead} />
              </Typeahead>
            )}
          />
        </FloatingLabel>
      </div>

      {props.stateWatch && selectOptions.length > 0 && (
        <div className="area-input">
          <FloatingLabel
            controlId="floatingSelect"
            label={
              props.errors.county_select ? 'Area is required' : props.areaWatch && props.areaWatch.length > 0 ? 'Area' : 'Select an Area'
            }
            className={props.errors.county_select ? 'alert-expand' : ''}
          >
            <Controller
              name="county_select"
              rules={{ required: true }}
              control={props.control}
              render={({ field }) => (
                <Typeahead
                  {...field}
                  id="county_select"
                  ref={areaTypeaheadRef}
                  // @ts-ignore
                  labelKey="text"
                  inputProps={{
                    'data-cy': 'select-area',
                    autoComplete: 'ignore-me',
                    shouldSelectHint: (shouldSelect, e) => {
                      return e.key === 'Tab' || e.key === 'Enter' || shouldSelect;
                    },
                  } as MyInputProps}
                  options={selectOptions}
                  disabled={isSingleArea}
                  selected={field.value || []}
                >
                  {!isSingleArea && <img className="select-down" alt="select-down" onClick={toggleAreaTypeahead} />}
                </Typeahead>
              )}
            />
          </FloatingLabel>
        </div>
      )}
    </>
  )
});

export default FormInputStateAreaDropdowns;

import React from "react";

import "./styles.css";

type Props = {
  children: React.ReactNode
}

export const ReportNotesSection = ({ children }: Props) => (
  <div className="report__notes-section report-notes-section">
    {children}
  </div>
)

import React from 'react';

import { ReportSectionElement, ReportSectionHeader, ReportSectionItem } from '../../../molecules';

import { TBoRACalcV2 } from '../../../../types/api';

type Props = {
  aCalc: TBoRACalcV2;
};

export const TaxConsiderations = ({ aCalc }: Props) => {
  return (
    <ReportSectionItem>
      <ReportSectionHeader>Tax Considerations</ReportSectionHeader>
      <ReportSectionElement title="Average Annual Deduction" value={aCalc.disp_avg_duct} />
      <ReportSectionElement title="Monthly Mortgage Payment " value={aCalc.disp_t_piti} />
      <ReportSectionElement title="Average Monthly Tax Savings" value={aCalc.disp_mon_taxs} />
      <ReportSectionElement title="Net Mortgage Payment" value={aCalc.disp_net_mopiti} />
      <ReportSectionElement title={aCalc.net_pay$} value={aCalc.disp_net_pay} />
    </ReportSectionItem>
  );
};

import React from "react";

import {ReportSectionHeader, ReportSectionItem, ReportSectionElement} from "../../../molecules";

import {TVacationResultsV2} from "../../../../types/api";

type Props = {
  dats: TVacationResultsV2,
  calcType: "aCalc1" | "aCalc2" | "aCalc3" | "aCalc4"
}

export const ProjectedPaymentDetails = ({ dats, calcType }: Props) => {
  return (
    <ReportSectionItem>
      <ReportSectionHeader>Projected Payment Details</ReportSectionHeader>
      <ReportSectionElement title="Principal & Interest" value={dats[calcType]?.disp_pi} />
      <ReportSectionElement title="Mortgage Insurance" value={dats[calcType]?.disp_mi} />
      <ReportSectionElement title="Property Taxes" value={dats[calcType]?.disp_pt} />
      <ReportSectionElement title="Property Insurance" value={dats[calcType]?.disp_ins} />
      <ReportSectionElement title="Condo Fee" value={dats[calcType]?.disp_condo} />
      <ReportSectionElement title="Total Payment" value={dats[calcType]?.disp_t_piti} />
    </ReportSectionItem>
  );
}

/*
 * Copyright 2020-2021 AskChristee, LLC. All rights reserved.
 */

import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Controller } from 'react-hook-form';
import { FloatingLabel, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { tipTerm } from '../../atoms';
import { InputProps, Typeahead } from 'react-bootstrap-typeahead';

type FormInputTermProps = {
  errors: any;
  control: any;
  required?: boolean;
  tip?: string;
  watch: any;
  label?: string;
};

export type FormInputTermHandle = {
  clear: () => void;
};

type TTerm = {
  value: string;
  text: string;
};

export const TERM_OPTIONS = [
  { value: '10', text: '10 Years' },
  { value: '15', text: '15 Years' },
  { value: '20', text: '20 Years' },
  { value: '25', text: '25 Years' },
  { value: '30', text: '30 Years' },
];

interface MyInputProps extends InputProps {
  'data-cy'?: string;
  'autocomplete'?: string
}

const FormInputTerm = forwardRef<FormInputTermHandle, FormInputTermProps>((props, ref) => {
  const [selected, setSelected] = useState<TTerm[]>([TERM_OPTIONS[4]]);

  const name = 'term';
  const label = props.label || 'Loan Term';
  const tip = props.tip || tipTerm;
  const options = {
    required: false,
  };

  let termTypeaheadRef: Typeahead<TTerm> | null;

  useImperativeHandle(ref, () => ({
    clear() {
      setSelected([TERM_OPTIONS[4]]);
    },
  }));

  const toggleTermTypeahead = () => {
    termTypeaheadRef?.toggleMenu();
  };

  const filterBy = () => {
    return true;
  };

  const onSelection = (selection: any, onChange: any) => {
    setSelected(selection);
    onChange(selection);
  };

  return (
    <div className="term-input">
      <FloatingLabel
        controlId="floatingSelect"
        label={
          props.errors[name] ? label + (props.required ? ' Required' : ' Incorrect') : label
        }
        className={props.errors[name] ? 'alert-expand' : ''}
      >
        <Controller
          name={name}
          rules={options}
          control={props.control}
          render={({ field }) => (
            <Typeahead
              filterBy={filterBy}
              {...field}
              id="term"
              className="input-term"
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              inputProps={{
                'data-cy': 'term',
                'autocomplete': 'term'
              } as MyInputProps}
              ref={(typeahead) => (termTypeaheadRef = typeahead)}
              labelKey="text"
              selected={selected}
              onChange={(selection) => onSelection(selection, field.onChange)}
              options={TERM_OPTIONS}
              defaultSelected={[TERM_OPTIONS[5]]}
            >
              <img className="select-down term-down" alt="select-down" onClick={toggleTermTypeahead} />
            </Typeahead>
          )}
        />
        <OverlayTrigger placement="right" trigger="click" rootClose overlay={<Tooltip>{tip}</Tooltip>}>
          <a className={props.errors[name] ? 'tooltiplabel alert-expand' : 'tooltippanel'}>
            <FontAwesomeIcon icon={faInfoCircle} />
          </a>
        </OverlayTrigger>
      </FloatingLabel>
    </div>
  );
});

export default FormInputTerm;
